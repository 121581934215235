import * as actionTypes from './actionTypes';

//  GET
export const getHeaderInfoStartedAction = () => ({
    type: actionTypes.GET_HEADER_INFO_STARTED,
});

export const getHeaderInfoSuccessAction = (data) => ({
    type: actionTypes.GET_HEADER_INFO_SUCCESS,
    payload: data,
});

export const getHeaderInfoFailAction = () => ({
    type: actionTypes.GET_HEADER_INFO_FAIL,
});
