export const GET_MEALS = 'GET_MEALS';
export const GET_MEALS_SUCCESS = 'GET_MEALS_SUCCESS';
export const GET_MEALS_FAIL = 'GET_MEALS_FAIL';
export const RETRIEVE_MEAL = 'RETRIEVE_MEAL';
export const RETRIEVE_MEAL_SUCCESS = 'RETRIEVE_MEAL_SUCCESS';
export const RETRIEVE_MEAL_FAIL = 'RETRIEVE_MEAL_FAIL';
export const UPDATE_MEAL = 'UPDATE_MEAL';
export const UPDATE_MEAL_SUCCESS = 'UPDATE_MEAL_SUCCESS';
export const UPDATE_MEAL_FAIL = 'UPDATE_MEAL_FAIL';
export const CREATE_MEAL = 'CREATE_MEAL';
export const CREATE_MEAL_SUCCESS = 'CREATE_MEAL_SUCCESS';
export const CREATE_MEAL_FAIL = 'CREATE_MEAL_FAIL';

// GET ALL MEALS
export const GET_ALL_MEALS = 'GET_ALL_MEALS';
export const GET_ALL_MEALS_SUCCESS = 'GET_ALL_MEALS_SUCCESS';
export const GET_ALL_MEALS_FAIL = 'GET_ALL_MEALS_FAIL';

export const GET_ALL_HOMEFOODS = 'GET_ALL_HOMEFOODS';
export const GET_ALL_HOMEFOODS_SUCCESS = 'GET_ALL_HOMEFOODS_SUCCESS';
export const GET_ALL_HOMEFOODS_FAIL = 'GET_ALL_HOMEFOODS_FAIL';

export const GET_ALL_MEALS_FOR_SEARCH = 'GET_ALL_MEALS_FOR_SEARCH';
export const GET_ALL_MEALS_FOR_SEARCH_SUCCESS = 'GET_ALL_MEALS_FOR_SEARCH_SUCCESS';
export const GET_ALL_MEALS_FOR_SEARCH_FAIL = 'GET_ALL_MEALS_FOR_SEARCH_FAIL';
