import styled, { createGlobalStyle } from 'styled-components';
import { between } from 'polished';
import { Col, Row, Menu as DefaultMenu, Drawer, Input } from 'antd';
import Layout from '../index';
import HamburgerSrc from '../../../../assets/shared/layout/hamburger-menu.svg';

const { Header } = Layout;

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const Logo = styled.img`
    cursor: pointer;
    user-select: none;
    height: auto;
    max-height: 54px;
    width: 100%;
    /* @media only screen and (max-width: 836px) {
        display: none;
    } */
    @media only screen and (max-width: 768px) {
        display: block;
    }
`;

export const LogoIcon = styled(Logo)`
    display: none;
    max-width: 56px;
    max-height: 44px;
    /* @media only screen and (max-width: 836px) {
        display: block;
    } */
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

export default styled(Header)`
    z-index: 1000;
    overflow: hidden;
    height: ${({ theme }) => theme.layoutStyles.headerHeight};
    background: ${({ theme }) => theme.layoutStyles.headerColor};
    padding: ${({ theme }) => theme.layoutStyles.headerPaddingVertical} ${between('0px', '120px')};
    position: fixed;
    width: 100%;
    -webkit-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    -moz-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        border: none !important;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border: none !important;
    }

    .ant-menu-horizontal {
        border: none;
    }

    .dispose {
        display: none;
    }

    .guest-icon-styles {
        :hover {
            cursor: pointer;
        }
    }

    .ant-menu {
        display: flex;
        align-items: middle !important;
        justify-content: flex-end !important;
    }
`;

export const ChefComponentStyles = styled(Row)`
    @media only screen and (max-width: 992px) {
        display: none;
    }
`;

export const UserProfileComponent = styled.div`
    background: ${({ theme }) => theme.primaryColor};
    min-width: 150px;
    border-radius: 50px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 3px;

    :hover {
        cursor: pointer;
    }

    .text-padding {
        padding-left: 12px;
        line-height: 12px;
        max-width: 100px;
    }
`;

export const LocalizationAndProfileGroup = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const GlobalStyleForTooltip = createGlobalStyle`
  body {
    .ant-tooltip-inner {
      background-color: transparent;
      padding:0px;
      margin-top:-5px;
      border-radius:15px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      
    }
    .ant-tooltip-arrow{
        display:none
    }
  }
`;

export const LocalizationIconWrapper = styled(Col)`
    height: 33px;
    width: 33px;
    display: flex;
    align-items: middle;
    justify-content: center;
    :hover {
        cursor: pointer;
    }
`;

export const GuestProfileIconWrapper = styled.div`
    display: flex;
    align-items: middle;
    justify-content: center;

    .swal-button {
        padding: 7px 19px;
        border-radius: 2px;
        background-color: red !important;
        font-size: 36px !important;
        border: 1px solid #3e549a;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
    }

    .swal-button--confirm {
        background-color: red;
    }
    @media only screen and (max-width: 992px) {
        display: none;
    }
`;

export const HamburgerMenu = styled.img.attrs(() => ({
    src: HamburgerSrc,
    alt: 'Hamburger Menu',
}))`
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: none;
    @media only screen and (max-width: 992px) {
        display: block;
    }
`;

export const Menu = styled(DefaultMenu)`
    .ant-menu-item {
        font-size: 13px;
    }
    @media only screen and (max-width: 992px) {
        display: none !important;
    }
`;

export const SearchIcon = styled.img`
    cursor: pointer;
`;

export const DrawerMenu = styled(Drawer)``;

export const SearchInput = styled(Input)`
    /* .ant-input {
        width: 120px;
    } */
    // margin-right: 20px;
    .ant-input-suffix {
        border-radius: 30px;
        background-color: ${({ theme }) => theme.secondaryColor};
    }
    // .ant-input-borderless:focus {
    //     width: 250px;
    // }
`;
