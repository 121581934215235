import * as actionTypes from './actionTypes';

const initialState = {
    orders: [],
    loading: false,
    cancelReasons: [],
    totalPage: 0,
    basket: [],
};

const failReducer = (state) => ({
    ...state,
    loading: false,
});
const startedReducer = (state) => ({
    ...state,
    loading: true,
});

const getOrderSuccessReducer = (state, action) => ({
    ...state,
    orders: action.payload.results,
    totalPage: action.payload.count,
    loading: false,
});
const getCancelReasonsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    cancelReasons: action.payload.results,
});
const cancelOrderSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const confirmOrderSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const deleteOrderSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const deleteBasketSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const orderReadySuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const createOrderSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ORDER_STARTED:
            return startedReducer(state);
        case actionTypes.GET_ORDER_SUCCESS:
            return getOrderSuccessReducer(state, action);
        case actionTypes.GET_ORDER_FAIL:
            return failReducer(state);
        case actionTypes.GET_CANCEL_REASONS_STARTED:
            return startedReducer(state);
        case actionTypes.GET_CANCEL_REASONS_SUCCESS:
            return getCancelReasonsSuccessReducer(state, action);
        case actionTypes.GET_CANCEL_REASONS_FAIL:
            return failReducer(state);
        case actionTypes.CANCEL_ORDER_STARTED:
            return startedReducer(state);
        case actionTypes.CANCEL_ORDER_SUCCESS:
            return cancelOrderSuccessReducer(state);
        case actionTypes.CANCEL_ORDER_FAIL:
            return failReducer(state);
        case actionTypes.CONFIRM_ORDER_STARTED:
            return startedReducer(state);
        case actionTypes.CONFIRM_ORDER_SUCCESS:
            return confirmOrderSuccessReducer(state);
        case actionTypes.CONFIRM_ORDER_FAIL:
            return failReducer(state);
        case actionTypes.DELETE_ORDER_STARTED:
            return startedReducer(state);
        case actionTypes.DELETE_ORDER_SUCCESS:
            return deleteOrderSuccessReducer(state);
        case actionTypes.DELETE_ORDER_FAIL:
            return failReducer(state);
        case actionTypes.DELETE_BASKET_STARTED:
            return startedReducer(state);
        case actionTypes.DELETE_BASKET_SUCCESS:
            return deleteBasketSuccessReducer(state);
        case actionTypes.DELETE_BASKET_FAIL:
            return failReducer(state);
        case actionTypes.CREATE_ORDER_STARTED:
            return startedReducer(state);
        case actionTypes.CREATE_ORDER_SUCCESS:
            return createOrderSuccessReducer(state);
        case actionTypes.CREATE_ORDER_FAIL:
            return failReducer(state);
        case actionTypes.ORDER_READY_STARTED:
            return startedReducer(state);
        case actionTypes.ORDER_READY_SUCCESS:
            return orderReadySuccessReducer(state);
        case actionTypes.ORDER_READY_FAIL:
            return failReducer(state);
        default:
            return state;
    }
};

export default reducer;
