/* eslint-disable camelcase */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { CONFIGURATION } from '../Api/request';
import { notificationReceived } from '../Redux/notification/actions';

function waitForSocketConnection(socket, callback) {
    setTimeout(function () {
        if (socket.readyState === 1) {
            // console.log('Connection is made');
            if (callback != null) {
                callback();
            }
        } else {
            waitForSocketConnection(socket, callback);
        }
    }, 5); // wait 5 milisecond for the connection...
}

class WebSocketService {
    static instance = null;

    callbacks = {};

    static getInstance() {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    constructor() {
        this.socketRef = null;
    }

    connect(dispatch, newNotificationMessage) {
        const path = CONFIGURATION.WS_URL;

        this.socketRef = new WebSocket(path);
        this.socketRef.onopen = () => {
            waitForSocketConnection(this.socketRef, () => {
                this.socketRef.send(
                    JSON.stringify({
                        type: 'subscribe',
                        token: window.localStorage.getItem('token'),
                        content: 'notification',
                    })
                );
            });
        };
        this.socketRef.onmessage = (e) => {
            const data = JSON.parse(e.data);

            if (data?.type === 'notification') {
                dispatch(notificationReceived(data));
                notification?.info({
                    message: newNotificationMessage,
                    style: {
                        marginBottom: '10px',
                        height: '50px',
                        padding: '10px',
                    },
                });
                // dispatch(notificationReceived(newNotificationMessage));
            }
            // else if (data.type === 'order') {
            //     notification.info({
            //         message: newOrderMessage,
            //         style: {
            //             marginBottom: '0px',
            //             height: '60px',
            //         },
            //     });
            // }
            this.socketNewMessage(e.data);
            // console.log(e, 'websocket listening');
        };

        this.socketRef.onerror = () => {
            // console.log(e.message);
        };
        this.socketRef.onclose = () => {
            // console.log("WebSocket closed let's reopen");
            this.connect();
        };
    }

    socketNewMessage(data) {
        const parsedData = JSON.parse(data);
        const { command } = parsedData;
        if (Object.keys(this.callbacks).length === 0) {
            return;
        }
        if (command === 'messages') {
            this.callbacks[command](parsedData.messages);
        }
        if (command === 'new_message') {
            this.callbacks[command](parsedData.message);
        }
    }

    initChatUser(username) {
        this.sendMessage({ command: 'init_chat', username });
    }

    fetchMessages(username) {
        this.sendMessage({ command: 'fetch_messages', username });
    }

    newChatMessage(message) {
        this.sendMessage({ command: 'new_message', from: message.from, text: message.text });
    }

    addCallbacks(messagesCallback, newMessageCallback) {
        this.callbacks.messages = messagesCallback;
        this.callbacks.new_message = newMessageCallback;
    }

    sendMessage(data) {
        try {
            this.socketRef.send(JSON.stringify({ ...data }));
        } catch (err) {
            // console.log(err.message);
        }
    }

    state() {
        return this.socketRef.readyState;
    }
}

const WebSocketInstance = WebSocketService.getInstance();

export const WebSocketComponent = () => {
    const { t } = useTranslation('shared');
    const [webSocketStarted, setWebSocketStarted] = useState(false);
    const dispatch = useDispatch();

    if (!webSocketStarted) {
        setWebSocketStarted(true);
        WebSocketInstance.connect(dispatch, t('general.messages.newNotification'), t('general.messages.newOrder'));
    }
    return null;
};

export default WebSocketInstance;
