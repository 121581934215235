/* eslint-disable sonarjs/no-duplicate-string */
export default {
    general: {
        order: 'Sipariş',
        moreDetail: 'Daha Fazla',
        active: 'Aktif',
        chefTitle: 'Lorem ipsum dolor sit amet (TR)',
        chefDescription:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.(TR)',
    },
    setting: {
        title: 'Kullanıcı Ayarları',
        personalInformation: 'Kişisel Bilgiler',
        paymentMethods: 'Ödeme Yöntemleri',
        notificationSettings: 'Bildirim Ayarları',
        securityPassword: 'Güvenlik/Şifre',
        generalPreferences: 'Genel Tercihler',
        payoutMethods: 'Kazançlarım',
        adresses: 'Adreslerim',

        payment: {
            info: "Bir ödeme yöntemi ayarlayın Yemegim.co'da satın alma yapmak için ödeme yönteminizi kullanın",
            cardName: 'Kart Adı',
            cardNo: 'Card Numarası',
            cardholderName: 'Kartın Üstündeki Ad',
            expirationDate: 'Son kullanma Tarihi',
            paymentCheck: 'Ücretleri ve masrafları ve çevrimiçi deneyimleri onaylıyorum.',
            whatsCVC2: 'CVC2 nedir?',
            month: 'Ay',
            year: 'Yıl',
            messages: {
                cardName: 'Lütfen kart adını giriniz!',
                cardNo: 'Lütfen card numarasını giriniz!',
                cardholderName: 'Lütfen kart üzerindeki adı giriniz!',
                month: 'Lütfen Ay giriniz!',
                year: 'Lütfen yıl giriniz!',
            },
            addCard: 'Kart Ekle',
        },
        payout: {
            info: 'Yemegim.co üzerindeki bekleyen kazançlarınızı ve bekleyen ödemelerinizi görebilirsiniz.',
            paymentCheck: 'Ücretleri ve masrafları ve çevrimiçi deneyimleri onaylıyorum.',
            shippingOption: 'Teslim Seçeneği',
            recipientName: 'Alıcı Adı Soyadı/Unvanı',
            cardholderName: 'Kartın Üstündeki Ad',
            messages: {
                shippingOption: 'Lütfen teslim seçeneğini seçiniz!',
                iban: 'Lütfen IBAN seçiniz!',
            },
            completedPayments: 'Ödemeler İçin Tıklayın',
            upcomingPayments: 'Yaklaşan Ödemeler',
        },
        notification: {
            messages: 'Mesajlar',
            messagesInfo: 'Siparişlerinizin takibi dahil olmak üzere yorumlarınız ve siparişleriniz hakkında mesajlar alın.',
            email: 'E-posta',
            textMessages: 'Metin mesajları',
            browserNotifications: 'Tarayıcı bildirimleri',
            browserNotificationsInfo: 'Tarayıcı dışında masaüstü ve mobil web üzerinden bildirimler alın',
            reminders: 'Hatırlatmalar',
            remindersInfo:
                'Çevrimiçi Deneyim etkinlikleriyle ilgili sipariş hatırlatıcıları, inceleme istekleri, fiyatlandırma bildirimleri ve diğer hatırlatıcıları alın.',
            choosePhone: 'Telefon seç',
            textMessagesTop:
                'SMS kutusunu işaretleyerek, aşağıdaki adrese otomatik olarak gönderilecek promosyon mesajlarını almayı kabul etmiş olursunuz',
            textMessagesBottom:
                "Daha fazla bilgi için, INFO ile 123456'ya kısa mesajla gönderin. Mobil bildirimleri iptal etmek için İPTAL yazarak 123456'ya yanıt verin. Mesaj ve veri ücretleri geçerli olabilir.",
        },
        generalPreference: {
            preferredLanguage: 'Tercihedilen Dil',
            language: 'Dil',
            turkishLira: 'Türk Lirası',
            preferredCurrency: 'Tercihedilen Para Birimi',
            timeZone: 'Saat Dilimi',
            turkish: 'Türkçe',
            messages: {
                preferredLanguage: 'Lütfen tercih ettiğiniz dili seçiniz!',
                preferredCurrency: 'Lütfen tercih ettiğiniz para birimini seçiniz!',
                timeZone: 'Lütfen saat dilimini seçiniz!',
            },
            generalChoices: 'Genel Tercihleriniz',
            generalChoicesInfo:
                'Para biriminizi değiştirdiğinizde Fiyatların görüntülenme şekli de değişir. Ödemelerinizi, ödemelerinizi alma şekliniz ve ödeme tercihleriniz bölümünden değiştirebilirsiniz.',
        },
    },
    SignupPage: {
        emailCard: {
            countryRegion: 'Ülke/ Bölge',
            errorMessage: 'Bir şeyler yanlış gitti',
            sign: 'Kaydol',
            login: 'Giriş yap',
            moreOption: 'Daha fazla seçenek',
            haveAccount: 'Hesabınız var mı?',
            forgotPassword: 'Şifremi unuttum',
            firstNameMessage: 'Lütfen adınızı giriniz',
            firstNamePlaceHolder: 'Ad',
            lastNameMessage: 'Lütfen soyadınızı giriniz',
            lastNamePlaceHolder: 'Soyad',
            countryCodeMessage: 'Lütfen ülke kodunuzu girin',
            phoneNumberMessage: 'Lütfen telefon numaranızı girin',
            phoneNumberPlaceHolder: 'Telefon numarası (5XXXXXXXXX)',
            emailMessage: 'Lütfen e-mail adresinizi giriniz',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Şifrenizin en az 8 karakter ve en az 1 büyük harf içermesi gerekir',
            passwordPlaceHolder: 'Şifre',
            passwordAgainMessage: 'Lütfen şifreyi tekrar giriniz',
            passwordAgainPlaceHolder: 'Şifre tekrar',
            emptyFieldMessage: 'Bu alanı doldurun',
            passwordError: 'Şifreler uyuşmuyor',
        },
        otpInputCard: {
            sign: 'Kaydol',
            login: 'Giriş yap',
            continue: 'Devam et',
            registrationCode: 'Lütfen kayıt kodunu giriniz.',
            digitMessage: '***** ile biten telefon numaranıza SMS gönderildi',
            digitCodeMessage: 'Lütfen 6 haneli kodu giriniz.',
            haveAccount: 'Hesabınız var mı?',
            register: 'Hesabınız yok mu ? Kayıt olmak için ',
        },
        signupCard: {
            sign: 'Kaydol',
            text1: 'Şef olmak için',
            login: 'Giriş Yap',
            message: 'Bu e-postaya sahip bir hesap var. Lütfen şifrenizle giriş yapın.',
            text: 'Bildirimleri açmak ister misiniz? Yeni lezzetli ve çevrimiçi deneyimler gibi önemli mesajları kaçırmayın.',
            haveAccount: 'Hesabınız var mı?',
            chefContract: 'Şef Sözleşmesi',
        },
    },
    LoginPage: {
        loginCard: {
            login: 'Giriş Yap',
            message: 'Bu e-postaya sahip bir hesap var. Lütfen şifrenizle giriş yapın',
            create: 'Bir tane yarat.',
            emailMessage: 'Lütfen e-mail adresinizi giriniz',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Şifrenizin en az 8 karakter ve en az 1 büyük harf içermesi gerekir',
            passwordPlaceHolder: 'Şifre',
            wrongPassword: 'Hatalı şifre veya e-mail!',
            countryRegion: 'Ülke / Bölge',
            countryCodeMessage: 'Lütfen ülke kodunuzu giriniz',
            phoneNumberMessage: 'Lütfen telefon numaranızı giriniz',
            phoneNumberPlaceHolder: 'Telefon numarası (5XXXXXXXXX)',
            notificationText: 'Bildirimleri açmak ister misiniz? Yeni lezzetli ve çevrimiçi deneyimler gibi önemli mesajları kaçırmayın.',
            continue: 'Devam et',
            textOne: 'Lorem Ipsum is simply dummy text of the printing and typesetting ',
            textTwo: 'Lorem Ipsum is simply dummy text of the printing and',
            register: 'Hesabınız yok mu ? ',
            forgotPassword: 'Şifreni mi unuttun ?',
        },
        provider: {
            email: 'E-mail ile devam et',
            apple: 'Apple ile devam et',
            google: 'Google ile devam et',
            sms: 'Sms ile devam et',
            facebook: 'Facebook ile devam et',
        },
    },
    NotificationPage: {
        notification: 'Bildirimler',
        noDataText: 'Şu anda herhangi bir bildirim yok.',
        newNotif: 'Yeni',
        areYouSure: 'Bildirimi Silmek İstediğinize Emin Misiniz ?',
        approve: 'Onayla',
        cancel: 'Vazgeç',
    },
    ProfilePage: {
        welcome: 'Hoş Geldiniz',
        join: '2021 yılında katıldı',
        evaluation: 'Değerlendirmelerim',
        commentMessage: 'Herhangi bir değerlendirmeniz yok.',
    },
    MyMenuPage: {
        welcome: 'Hoş Geldiniz',
        welcomeText: 'Bunlardan hangisi sizin yemeğinize en çok benziyor? Kazanmaya başlamak için menünüzü bitirin.',
        createMyMenu: 'Menümü Oluştur',
        categories: 'Yemek Kategorileri',
        portion: 'Porsiyon',
        price: 'Fiyat',
        time: 'Süre (Dk)',
        description: 'Yemek Açıklaması',
        name: 'Yemek İsmi',
        ingredients: 'Yemekte Kullanılan Ürünler',
        country: 'Ülke',
        region: 'Bölge',
        labels: 'Yemek Etiketleri',
        successMessage: 'Yemek onaya gönderildi.',
        errorMessage: 'Onaya gönderilirken bir hata oluştu!',
        minimumPrice: 'Min. tutar ',
        validTime: '',
        noTags: 'Lütfen en az bir yemek etiketi seçiniz!',
        noCategoryError: 'Lütfen bir kategori seçiniz!',
        noIngredientError: 'Lütfen en az bir ürün seçiniz!',
        noImageError: 'Lütfen en az bir resim yükleyin!',
        requiredError: 'Bu alan zorunludur!',
        ingredientExists: 'Aynı ürünü birden fazla ekleyemezsiniz!',
        unit: 'Birim',
        imgRecommend: 'Yemegim.co, daha iyi bir görünüm elde edebilmeniz için yatay fotoğraf yüklemenizi önerir',
        categoryInfo: 'Yemeğinizi en iyi tanımlayan kategoriyi seçiniz.',
        portionInfo: 'Yemeğinizin porsiyon bilgisini giriniz.',
        priceInfo: 'Yemeğinizin fiyat bilgisini giriniz.',
        timeInfo: 'Yemeğinizin hazır olacağı ortalama süreyi giriniz.',
        mealDescriptionInfo: 'Müşterileriniz yemeğiniz hakkında bilmesi gerekleri açıklayınız.',
        mealNameInfo: 'Yemeğinizin ismini giriniz.',
        ingredientsNameInfo: 'Yemeğinizin malzemelerini giriniz.',
        countryInfo: 'Mutfağınızın bulunduğu ülkeyi giriniz.',
        regionInfo: 'Mutfağınızın bulunduğu bölgeyi giriniz.',
        foodLabelInfo: 'Yemeğinizi tanımlayan etiketleri seçiniz.',
        foodPortionQuantityError: '',
        foodPortionQuantityErrorForKg: 'Min. 3',
    },
    FavouritesPage: {
        name: 'Favoriler',
        emptyMessage: 'Favori yemeğin yok.',
    },
    PaymentsPage: {
        name: 'Ödemeler',
        completed: 'Tamamlanan Ödemeler',
        upcoming: 'Yaklaşan Ödemeler',
        CompletedPayments: {
            method: 'Ödeme Şekli:',
            paymentMessage: 'Lütfen ödeme şeklini seçiniz!',
            paymentPlaceHolder: 'Bütün ödeme yöntemleri',
            range: 'Tarih Aralığı:',
            rangeMessage: 'Lütfen tarih aralığını girin!',
            paid: 'Ödeme:',
            download: 'CSV İndir',
            year: 'yıl',
            month: 'ay',
        },
        UpcomingPayments: {
            method: 'Ödeme Şekli:',
            paymentMessage: 'Lütfen ödeme şeklini seçiniz!',
            paymentPlaceHolder: 'Bütün ödeme yöntemleri',
            range: 'Tarih Aralığı:',
            rangeMessage: 'Lütfen tarih aralığını girin!',
            paid: 'Ödeme:',
            download: 'Ödeme Al',
            year: 'yıl',
            month: 'ay',
            warningMessage: 'Şu anda seçili tarihler, kayıtlar ve ödeme yöntemi için herhangi bir ödeme almadınız.',
        },
        MealTable: {
            id: 'Yemek ID:',
            meal: 'Yemek:',
            date: 'Tarih:',
            currency: 'Para Birimi:',
            price: 'Yemek Ücreti:',
            fee: 'Yemegim.co Ücreti:',
            out: 'Ödendi:',
            payment: 'Ödeme İste',
        },
    },
    SchedulePage: {
        welcome: 'Hoşgeldiniz',
        description: 'Haftalık olarak ajandanızı doldurun ve kazanmaya başlayın.',
        create: 'Programımı Oluştur',
        Calendar: {
            CalendarHeader: {
                week: 'Hafta',
                weekly: 'Haftalık',
                monthly: 'Aylık',
                info: 'Şefler 09:00 - 18:00 saatleri arasında çalışabilir',
            },
            MonthlyCalendar: {
                Sider: {
                    order: 'Siparişiniz bulunmamaktadır.',
                },
                order: 'Sipariş',
                start: 'Başlangıç',
                end: 'Bitiş',
            },
            WeeklyCalendar: {
                Footer: {
                    send: 'Onaya Gönder',
                    wait: 'Onay Bekleniyor',
                    edit: 'Düzenle',
                    message: 'Onay için gönderildi.',
                },
                work: 'Çalışma Saatleri',
                status: 'Sipariş Alma Durumu',
            },
        },
    },
    OverviewPage: {
        updateSuccess: 'Yemek Başarıyla Güncellendi, onaylandıktan sonra değişiklikleri görebileceksiniz.',
        MyMenuPage: {
            menu: 'Menüm',
        },
        MyMenuSettingsPage: {
            welcome: 'Hoş Geldiniz',
            create: 'Menümü Oluştur',
            text: 'Satış yapmak için menünüzü tanımlayın. Menünüzü tamamlamak için yemek kategorisini seçmeyi unutmayın.',
            CreateMealSection: {
                category: 'Yemek Kategorileri:',
                name: 'Yemek Adı:',
                nameMessage: 'Lütfen yemek adınızı giriniz!',
                namePlaceHolder: 'Yemek Adı',
                country: 'Ülke:',
                countryMessage: 'Lütfen ülkenizi giriniz!',
                region: 'Bölge:',
                regionMessage: 'Lütfen bölgenizi giriniz!',
                foodLabel: 'Yemek Etiketleri:',
                portion: 'Porsiyon',
                price: 'Fiyat (TL)',
                time: 'Zaman',
                minimumPrice: 'Min. Tutar',
                priceTl: 'fiyat (TL)',
                timeMins: 'Zaman (Dk)',
                validTime: 'Geçerli zaman girin!',
                description: 'Ürün Açıklaması:',
                descriptionMessage: 'Lütfen son açıklamanızı girin!',
                inputPlaceHolder:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation consequat. ',
                ingredient: 'Malzemeler:',
                ingredientPlaceHolder: 'Malzeme Ekle',
                add: 'Ekle',
                edit: 'Düzenle',
                sendApprove: 'Onaya Gönder',
                wait: 'Onay Bekleniyor',
                ingredientErrorMessage: 'Aynı ürünü birden fazla ekleyemezsiniz!',
                ingredientEmptyMessage: 'Malzeme adı giriniz',
                approveSuccess: 'Ürün onaya gönderildi',
                approveError: 'Ürün onaya gönderilemedi',
            },
            FoodEditSection: {
                Modal: {
                    successMessage: 'Başarılı',
                    errorMessage: 'Hata',
                    recommended: 'Etiket Önerin:',
                    recommended2: 'Etiket Önerin',

                    send: 'Gönder',
                    foodLabel: 'Lütfen bir gıda etiketi seçin',
                    add: 'Ekle',
                    cancel: 'İptal',
                },
            },
        },
        noMenuWarning: "Yemegim.co'ya hoş geldin! Menünü oluşturmak için ",
        noMenuWarningLink: 'kullanıcı ayarlarından ',
        noMenuWarning2: 'bilgilerini tamamlamalısın. ',
        createMenuWarning: 'Hesabın oluşturuldu. Şimdi ',
        menuHref: 'menünü ',
        afterHref: 'oluşturup satış yapmaya başlayabilirsin.',
    },
    OrderPage: {
        moreDetail: 'Daha Fazla',
        pending: 'Onay Bekleyenler',
        today: 'Aktif',
        history: 'Geçmiş',
        allType: 'Hepsi',
        delivired: 'Teslim Edildi',
        preparing: 'Hazırlanıyor',
        cancelled: 'Sipariş İptal',
        cancelledByVidieats: 'Yemegim.co İptal',
        ready: 'Sipariş Hazır',
        onDelivery: 'Sipariş Yolda',
        date: 'Tarih',
        time: 'Zaman',
        mealName: 'Yemek Adı',
        mealPrice: 'Yemek Fiyatı',
        total: 'Toplam',
        earning: 'Kazanç',
        note: 'Not',
        orderApproved: 'Sipariş Alındı',
        rejectOrder: 'Sipariş İptal Et',
        acceptOrder: 'Siparişi Onayla',
        title: 'Siparişlerim',
        future: 'İleri Tarihli',
        myOrder: 'Verilen Sipariş',
        receiveOrder: 'Gelen Sipariş',
        orderReady: 'Sipariş Hazır',
        waitForCourrier: 'Kurye Bekleniyor',
        deliveryCode: 'Teslimat Kodu:',
        deliveryCodeDesc: 'Kurye siparişi üstüne aldığında belirlenir.',
        callACourier: 'Kurye Çağır',
        sentMngMessage: 'Siparişinizi en yakın MNG kargo subeşine teslimat kodu ile teslim edebilirsiniz',
        orderDeleteModal: {
            modalHeader: 'Emin misiniz?',
            modalText: 'Bu eylem geri döndürülemez!',
        },
        orderDetailModal: {
            sent: 'Siparişi Veren',
            chef: 'Şef',
            orderNo: 'Sipariş No',
            sendingDate: 'Gönderim Tarihi',
            sendingTime: 'Gönderim Zamanı',
            paymentMethod: 'Ödeme Yöntemi',
            address: 'Adres :',
            comment: 'Yorum Yapın',
        },
        orderRejectModal: {
            sent: 'Gönder',
            info: 'Lütfen kişinin siparişini iptal etmek için aşağıdaki nedenlerden birini seçin.',
            modalTitle: 'Siparişi İptal Et',
            modalHeader: 'Emin misiniz?',
            modalText: 'Bu eylem geri döndürülemez!',
            cancel: 'Vazgeç',
            approve: 'Onayla',
        },
        callCourrier: 'Kurye Çağır',
        vehicleType: 'Siparişin hazır mı? Lütfen paketinin büyüklüğüne göre araç tipi seç. Kabul ettikten sonra kuryeye bildirim gönderilecektir.',
    },
    storePage: {
        bigTitle: 'Mağaza',
        welcomeText: 'Yemegim.co Mağazaya hoş geldiniz.',
        description: 'Birbirinden lezzetli siparişlerinizi hazırlarken tüm ihtiyacınız olanlar Yemegim.co mağazada.',
    },
    storeItemCard: {
        name: 'Şef Şapka',
        description: ' Lorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod….',
        price: '40',
        current: 'TL',
        quantity: 'Adet',
        addToBasket: 'Sepete Ekle',
    },
};
