/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable sonarjs/no-identical-functions */
import * as actionTypes from './actionTypes';

const initialState = {
    settings: null,
    loading: false,
    sendingRequest: false,
    pendingUpdatedSettings: false,
    addresses: [],
    currentAddress: [],
    myPreferences: [],
};

// GET
const getSettingsStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const getSettingsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getSettingsSuccessReducer = (state, action) => ({
    ...state,
    settings: action.payload,
    loading: false,
});

// CHECK
const checkSettingsStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const checkSettingsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const checkSettingsSuccessReducer = (state, action) => ({
    ...state,
    pendingUpdatedSettings: action.payload,
    loading: false,
});

const sendSettingsStartedReducer = (state) => ({
    ...state,
    sendingRequest: true,
});

const sendSettingsSuccessReducer = (state) => ({
    ...state,
    sendingRequest: false,
});

const sendSettingsFailReducer = (state) => ({
    ...state,
    sendingRequest: false,
});

// GET ADDRESSES
const getAddressesStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const getAddressesFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getAddressesSuccessReducer = (state, action) => ({
    ...state,
    addresses: action.payload,
    loading: false,
});

// CREATE ADDRESSES
const createAddressStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const createAddressFailReducer = (state) => ({
    ...state,
    loading: false,
});

const createAddressSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const selectedAddressStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const selectedAddressFailReducer = (state) => ({
    ...state,
    loading: false,
});

const selectedAddressSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

// GET SELECTED ADDRESSES
const getSelectedAddressStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const getSelectedAddressFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getSelectedddressSuccessReducer = (state, action) => ({
    ...state,
    currentAddress: action.payload,
    loading: false,
});

// GET MY PREFERENCES
const getMyPreferencesStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const getMyPreferencesSuccessReducer = (state, action) => ({
    ...state,
    myPreferences: action.payload,
    loading: false,
});

const getMyPreferencesFailReducer = (state) => ({
    ...state,
    loading: false,
});

// UPDATE MY PREFERENCES
const updateMyPreferencesStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const updateMyPreferencesSuccessReducer = (state, action) => ({
    ...state,
    myPreferences: { ...state.myPreferences, ...action.payload },
    loading: false,
});

const updateMyPreferencesFailReducer = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMER_SETTINGS_STARTED:
            return getSettingsStartedReducer(state);
        case actionTypes.GET_CUSTOMER_SETTINGS_SUCCESS:
            return getSettingsSuccessReducer(state, action);
        case actionTypes.GET_CUSTOMER_SETTINGS_FAIL:
            return getSettingsFailReducer(state);

        case actionTypes.GET_CHEF_SETTINGS_STARTED:
            return getSettingsStartedReducer(state);
        case actionTypes.GET_CHEF_SETTINGS_SUCCESS:
            return getSettingsSuccessReducer(state, action);
        case actionTypes.GET_CHEF_SETTINGS_FAIL:
            return getSettingsFailReducer(state);

        case actionTypes.UPDATE_CUSTOMER_SETTINGS_STARTED:
            return sendSettingsStartedReducer(state);
        case actionTypes.UPDATE_CUSTOMER_SETTINGS_SUCCESS:
            return sendSettingsSuccessReducer(state);
        case actionTypes.UPDATE_CUSTOMER_SETTINGS_FAIL:
            return sendSettingsFailReducer(state);

        case actionTypes.UPDATE_CHEF_SETTINGS_STARTED:
            return sendSettingsStartedReducer(state);
        case actionTypes.UPDATE_CHEF_SETTINGS_SUCCESS:
            return sendSettingsSuccessReducer(state);
        case actionTypes.UPDATE_CHEF_SETTINGS_FAIL:
            return sendSettingsFailReducer(state);

        case actionTypes.CHECK_CHEF_SETTINGS_STARTED:
            return checkSettingsStartedReducer(state);
        case actionTypes.CHECK_CHEF_SETTINGS_SUCCESS:
            return checkSettingsSuccessReducer(state, action);
        case actionTypes.CHECK_CHEF_SETTINGS_FAIL:
            return checkSettingsFailReducer(state);

        case actionTypes.GET_ADDRESSES_STARTED:
            return getAddressesStartedReducer(state);
        case actionTypes.GET_ADDRESSES_SUCCESS:
            return getAddressesSuccessReducer(state, action);
        case actionTypes.GET_ADDRESSES_FAIL:
            return getAddressesFailReducer(state);

        case actionTypes.CREATE_ADDRESS_STARTED:
            return createAddressStartedReducer(state);
        case actionTypes.CREATE_ADDRESS_SUCCESS:
            return createAddressSuccessReducer(state);
        case actionTypes.CREATE_ADDRESS_FAIL:
            return createAddressFailReducer(state);

        case actionTypes.SELECTED_ADDRESS_STARTED:
            return selectedAddressStartedReducer(state);
        case actionTypes.SELECTED_ADDRESS_SUCCESS:
            return selectedAddressSuccessReducer(state);
        case actionTypes.SELECTED_ADDRESS_FAIL:
            return selectedAddressFailReducer(state);

        case actionTypes.GET_SELECTED_ADDRESS_STARTED:
            return getSelectedAddressStartedReducer(state);
        case actionTypes.GET_SELECTED_ADDRESS_SUCCESS:
            return getSelectedddressSuccessReducer(state, action);
        case actionTypes.GET_SELECTED_ADDRESS_FAIL:
            return getSelectedAddressFailReducer(state);

        case actionTypes.GET_PREFERENCES_STARTED:
            return getMyPreferencesStartedReducer(state);
        case actionTypes.GET_PREFERENCES_SUCCESS:
            return getMyPreferencesSuccessReducer(state, action);
        case actionTypes.GET_PREFERENCES_FAIL:
            return getMyPreferencesFailReducer(state);

        case actionTypes.UPDATE_PREFERENCES_STARTED:
            return updateMyPreferencesStartedReducer(state);
        case actionTypes.UPDATE_PREFERENCES_SUCCESS:
            return updateMyPreferencesSuccessReducer(state, action);
        case actionTypes.UPDATE_PREFERENCES_FAIL:
            return updateMyPreferencesFailReducer(state);

        default:
            return state;
    }
};

export default reducer;
