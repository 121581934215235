import * as actionTypes from './actionTypes';

const initialState = {
    schedule: null,
    loading: true,
    chefSchedule: null,
};

// GET
// const getScheduleStartedReducer = (state) => ({
//     ...state,
//     loading: true,
// });

const getScheduleFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getScheduleSuccessReducer = (state, action) => ({
    ...state,
    schedule: action.payload.results,
    loading: false,
});

const getChefScheduleSuccess = (state, action) => ({
    ...state,
    loading: false,
    chefSchedule: action.payload.results,
});

// UPDATE;

// const updateScheduleStartedReducer = (state) => ({
//     ...state,
//     loading: true,
// });

// const updateScheduleFailReducer = (state) => ({
//     ...state,
//     loading: false,
// });

// const updateScheduleSuccessReducer = (state, action) => ({
//     ...state,
//     loading: false,
// });

// // CREATE

// const createScheduleStartedReducer = (state) => ({
//     ...state,
//     loading: true,
// });

// const createScheduleFailReducer = (state) => ({
//     ...state,
//     loading: false,
// });

// const createScheduleSuccessReducer = (state, action) => ({
//     ...state,
//     loading: false,
// });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SCHEDULE_SUCCESS:
            return getScheduleSuccessReducer(state, action);
        case actionTypes.GET_SCHEDULE_FAIL:
            return getScheduleFailReducer(state);
        case actionTypes.GET_CHEF_SCHEDULE_WITH_ID_SUCCESS:
            return getChefScheduleSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
