/* eslint-disable sonarjs/no-identical-functions */
import * as actionTypes from './actionTypes';

const initialState = {
    user: null,
    loading: false,
};

const loginStartReducer = (state) => ({
    ...state,
    loading: true,
});

const loginFailReducer = (state) => ({
    ...state,
    loading: false,
});

const loginSuccessReducer = (state, action) => ({
    ...state,
    user: action.payload.user,
    loading: false,
});

const authLogOutReducer = (state) => ({
    ...state,
    user: null,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return loginStartReducer(state);
        case actionTypes.LOGIN_FAILURE:
            return loginFailReducer(state);
        case actionTypes.LOGIN_SUCCESS:
            return loginSuccessReducer(state, action);
        case actionTypes.LOGOUT:
            return authLogOutReducer(state);

        default:
            return state;
    }
};

export default reducer;
