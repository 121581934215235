import * as actions from './actions';

export const getFooterCategories = () => async (dispatch, getState, service) => {
    try {
        dispatch(actions.getFooterCategoriesStartedAction());
        const result = await service({
            method: 'GET',
            path: 'webpage/footerCategory/',
        });
        dispatch(actions.getFooterCategoriesSuccessAction(result));
    } catch (error) {
        dispatch(actions.getFooterCategoriesFailAction());
    }
};

export const getSocialMedias = () => async (dispatch, getState, service) => {
    try {
        dispatch(actions.getSocialMediasStartedAction());
        const result = await service({
            method: 'GET',
            path: 'webpage/socialMedia/',
        });
        dispatch(actions.getSocialMediasSuccessAction(result));
    } catch (error) {
        dispatch(actions.getSocialMediasFailAction());
    }
};
