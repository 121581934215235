/* eslint-disable sonarjs/no-identical-functions */
import * as actionTypes from './actionTypes';

const initialState = {
    cardsInfo: [],
    loading: true,
    sendingRequest: false,
    upcomingPayments: [],
    completedPayments: [],
};

// GET UPCOMING
const getUpcomingPaymentsStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const getUpcomingPaymentsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getUpcomingPaymentsSuccessReducer = (state, action) => ({
    ...state,
    upcomingPayments: action.payload,
    loading: false,
});

// GET COMPLETED
const getCompletedPaymentsStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const getCompletedPaymentsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getCompletedPaymentsSuccessReducer = (state, action) => ({
    ...state,
    completedPayments: action.payload,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SAVED_CARDS_STARTED:
            return { ...state, loading: true };
        case actionTypes.GET_SAVED_CARDS_SUCCESS:
            return { ...state, cardsInfo: action.payload.customerCards, loading: false };
        case actionTypes.GET_SAVED_CARDS_FAIL:
            return { ...state, loading: false };
        case actionTypes.DELETE_SAVED_CARD_STARTED:
            return { ...state, sendingRequest: true };
        case actionTypes.DELETE_SAVED_CARD_SUCCESS:
            return { ...state, sendingRequest: false };
        case actionTypes.DELETE_SAVED_CARD_FAIL:
            return { ...state, sendingRequest: false };
        // UPCOMINGPAYMENTS
        case actionTypes.GET_UPCOMING_PAYMENTS_STARTED:
            return getUpcomingPaymentsStartedReducer(state);
        case actionTypes.GET_UPCOMING_PAYMENTS_SUCCESS:
            return getUpcomingPaymentsSuccessReducer(state, action);
        case actionTypes.GET_UPCOMING_PAYMENTS_FAIL:
            return getUpcomingPaymentsFailReducer(state);
        // COMPLETEDPAYMENTS
        case actionTypes.GET_COMPLETED_PAYMENTS_STARTED:
            return getCompletedPaymentsStartedReducer(state);
        case actionTypes.GET_COMPLETED_PAYMENTS_SUCCESS:
            return getCompletedPaymentsSuccessReducer(state, action);
        case actionTypes.GET_COMPLETED_PAYMENTS_FAIL:
            return getCompletedPaymentsFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
