/* eslint-disable sonarjs/no-duplicate-string */
import swal from 'sweetalert';
import * as actionTypes from './actionTypes';
// CUSTOMER

export const getCustomerSettingsStartedAction = () => ({
    type: actionTypes.GET_CUSTOMER_SETTINGS_STARTED,
});

export const getCustomerSettingsSuccessAction = (data) => ({
    type: actionTypes.GET_CUSTOMER_SETTINGS_SUCCESS,
    payload: data,
});

export const getCustomerSettingsFailAction = () => ({
    type: actionTypes.GET_CUSTOMER_SETTINGS_FAIL,
});

export const updateCustomerSettingsStartedAction = () => ({
    type: actionTypes.UPDATE_CUSTOMER_SETTINGS_STARTED,
});

export const updateCustomerSettingsSuccessAction = () => ({
    type: actionTypes.UPDATE_CUSTOMER_SETTINGS_SUCCESS,
});

export const updateCustomerSettingsFailAction = () => ({
    type: actionTypes.UPDATE_CUSTOMER_SETTINGS_FAIL,
});

export const changeCustomerPasswordStartedAction = () => ({
    type: actionTypes.CHANGE_CUSTOMER_PASSWORD_STARTED,
});

export const changeCustomerPasswordSuccessAction = () => ({
    type: actionTypes.CHANGE_CUSTOMER_PASSWORD_SUCCESS,
});

export const changeCustomerPasswordFailAction = (error) => {
    swal({ text: error.detail, icon: 'error' });

    return {
        type: actionTypes.CHANGE_CUSTOMER_PASSWORD_FAIL,
    };
};

// CHEF
export const getChefSettingsStartedAction = () => ({
    type: actionTypes.GET_CHEF_SETTINGS_STARTED,
});

export const getChefSettingsSuccessAction = (data) => ({
    type: actionTypes.GET_CHEF_SETTINGS_SUCCESS,
    payload: data,
});

export const getChefSettingsFailAction = () => ({
    type: actionTypes.GET_CHEF_SETTINGS_FAIL,
});

export const updateChefSettingsStartedAction = () => ({
    type: actionTypes.UPDATE_CHEF_SETTINGS_STARTED,
});

export const updateChefSettingsSuccessAction = () => ({
    type: actionTypes.UPDATE_CHEF_SETTINGS_SUCCESS,
});

export const updateChefSettingsFailAction = () => ({
    type: actionTypes.UPDATE_CHEF_SETTINGS_FAIL,
});

export const changeChefPasswordStartedAction = () => ({
    type: actionTypes.CHANGE_CHEF_PASSWORD_STARTED,
});

export const changeChefPasswordSuccessAction = () => ({
    type: actionTypes.CHANGE_CHEF_PASSWORD_SUCCESS,
});

export const changeChefPasswordFailAction = () => {
    swal({ text: 'Bir hata oluştu !', icon: 'error' });

    return {
        type: actionTypes.CHANGE_CHEF_PASSWORD_FAIL,
    };
};

export const checkChefSettingsStartedAction = () => ({
    type: actionTypes.CHECK_CHEF_SETTINGS_STARTED,
});

export const checkChefSettingsSuccessAction = (data) => ({
    type: actionTypes.CHECK_CHEF_SETTINGS_SUCCESS,
    payload: data,
});

export const checkChefSettingsFailAction = () => ({
    type: actionTypes.CHECK_CHEF_SETTINGS_FAIL,
});

// FORGOT PASS FOR CHEF
export const chefForgotPasswordStartedAction = () => ({
    type: actionTypes.CHEF_FORGOT_PASSWORD_STARTED,
});

export const chefForgotPasswordSuccessAction = () => ({
    type: actionTypes.CHEF_FORGOT_PASSWORD_SUCCESS,
});

export const chefForgotPasswordFailAction = () => {
    swal({ text: 'Bir hata oluştu !', icon: 'error' });

    return {
        type: actionTypes.CHEF_FORGOT_PASSWORD_FAIL,
    };
};

export const customerForgotPasswordStartedAction = () => ({
    type: actionTypes.CUSTOMER_FORGOT_PASSWORD_STARTED,
});

export const customerForgotPasswordSuccessAction = () => ({
    type: actionTypes.CUSTOMER_FORGOT_PASSWORD_SUCCESS,
});

export const customerForgotPasswordFailAction = () => {
    swal({ text: 'Bir hata oluştu !', icon: 'error' });

    return {
        type: actionTypes.CUSTOMER_FORGOT_PASSWORD_FAIL,
    };
};

// GET ADDRESSES
export const getAddressesStartedAction = () => ({
    type: actionTypes.GET_ADDRESSES_STARTED,
});

export const getAddressesSuccessAction = (data) => ({
    type: actionTypes.GET_ADDRESSES_SUCCESS,
    payload: data,
});

export const getAddressesFailAction = () => ({
    type: actionTypes.GET_ADDRESSES_FAIL,
});

// CREATE ADDRESS
export const createAddressStartedAction = () => ({
    type: actionTypes.CREATE_ADDRESS_STARTED,
});

export const createAddressSuccessAction = (data) => ({
    type: actionTypes.CREATE_ADDRESS_SUCCESS,
    payload: data,
});

export const createAddressFailAction = () => ({
    type: actionTypes.CREATE_ADDRESS_FAIL,
});

// DELETE ADDRESS
export const deleteAddressStarted = () => ({
    type: actionTypes.DELETE_ADDRESS_STARTED,
});

export const deleteAddressSuccess = (order) => ({
    type: actionTypes.DELETE_ADDRESS_SUCCESS,
    payload: order,
});

export const deleteAddressFail = () => ({
    type: actionTypes.DELETE_ADDRESS_FAIL,
});

// EDIT ADDRESS
export const editAddressStartedAction = () => ({
    type: actionTypes.EDIT_ADDRESS_STARTED,
});

export const editAddressSuccessAction = () => ({
    type: actionTypes.EDIT_ADDRESS_SUCCESS,
});

export const editAddressFailAction = () => {
    swal({ text: 'Bir hata oluştu !', icon: 'error' });

    return {
        type: actionTypes.EDIT_ADDRESS_FAIL,
    };
};

// SELECTED ADDRESS
export const selectedAddressStartedAction = () => ({
    type: actionTypes.SELECTED_ADDRESS_STARTED,
});

export const selectedAddressSuccessAction = (data) => ({
    type: actionTypes.SELECTED_ADDRESS_SUCCESS,
    payload: data,
});

export const selectedAddressFailAction = () => ({
    type: actionTypes.SELECTED_ADDRESS_FAIL,
});

// GET SELECTED ADDRESSES
export const getSelectedAddressStartedAction = () => ({
    type: actionTypes.GET_SELECTED_ADDRESS_STARTED,
});

export const getSelectedAddressSuccessAction = (data) => ({
    type: actionTypes.GET_SELECTED_ADDRESS_SUCCESS,
    payload: data,
});

export const getSelectedAddressFailAction = () => ({
    type: actionTypes.GET_SELECTED_ADDRESS_FAIL,
});

// GET PREFERENCES
export const getPreferencesStartedAction = () => ({
    type: actionTypes.GET_PREFERENCES_STARTED,
});

export const getPreferencesSuccessAction = (data) => ({
    type: actionTypes.GET_PREFERENCES_SUCCESS,
    payload: data,
});

export const getPreferencesFailAction = () => ({
    type: actionTypes.GET_PREFERENCES_FAIL,
});

// UPDATE PREFERENCES
export const updatePreferencesStartedAction = () => ({
    type: actionTypes.UPDATE_PREFERENCES_STARTED,
});

export const updatePreferencesSuccessAction = (data) => ({
    type: actionTypes.UPDATE_PREFERENCES_SUCCESS,
    payload: data,
});

export const updatePreferencesFailAction = () => ({
    type: actionTypes.UPDATE_PREFERENCES_FAIL,
});

// DELETE USER
export const deleteUserStarted = () => ({
    type: actionTypes.DELETE_USER_STARTED,
});

export const deleteUserSuccess = (order) => ({
    type: actionTypes.DELETE_USER_SUCCESS,
    payload: order,
});

export const deleteUserFail = () => ({
    type: actionTypes.DELETE_USER_FAIL,
});

// NEW (27.12)
export const chefForgotPasswordNewStartedAction = () => ({
    type: actionTypes.CHEF_FORGOT_PASSWORD_NEW_STARTED,
});
export const chefForgotPasswordNewSuccessAction = () => ({
    type: actionTypes.CHEF_FORGOT_PASSWORD_NEW_SUCCESS,
});

export const chefForgotPasswordNewFailAction = () => ({
    type: actionTypes.CHEF_FORGOT_PASSWORD_NEW_FAIL,
});

export const customerForgotPasswordNewStartedAction = () => ({
    type: actionTypes.CUSTOMER_FORGOT_PASSWORD_NEW_STARTED,
});

export const customerForgotPasswordNewSuccessAction = () => ({
    type: actionTypes.CUSTOMER_FORGOT_PASSWORD_NEW_SUCCESS,
});

export const customerForgotPasswordNewFailAction = () => ({
    type: actionTypes.CUSTOMER_FORGOT_PASSWORD_NEW_FAIL,
});
