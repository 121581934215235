import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { ButtonTheme } from './constants';
import { theme } from '../../themes/mainTheme';

const { primaryColor, secondaryColor, tertiaryColor, quaternaryColor, borderColor, lightColor } = theme;
const { hoverTertiary, hoverBordered, defaultRadius, defaultShadow } = ButtonTheme;

// TYPES: primary - secondary - tertiary - deafult - link

const PrimaryButtonStyles = css`
    background-color: ${primaryColor} !important;
    border-color: ${primaryColor} !important;
`;

const SecondaryButtonStyles = css`
    background-color: ${secondaryColor} !important;
    color: #ffffff;
    border-color: ${secondaryColor} !important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${hoverTertiary} !important;
    }
`;
const SecondaryButtonReverseStyles = css`
    background-color: #ffffff !important;
    color: ${secondaryColor};
    border-color: ${secondaryColor} !important;
`;

const SecondaryOutlinedButtonStyles = css`
    background-color: #fff;
    border-color: ${secondaryColor};
    color: ${secondaryColor};

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${secondaryColor};
        border-color: ${secondaryColor};
        color: #fff;
    }
`;

const TertiaryButtonStyles = css`
    background-color: ${tertiaryColor} !important;
    color: #424242;
    border-color: ${quaternaryColor}!important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${hoverBordered};
    }
`;

const TertiaryOutlinedButtonStyles = css`
    background-color: #fff;
    border-color: ${tertiaryColor};
    color: ${tertiaryColor};

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${tertiaryColor};
        border-color: ${tertiaryColor};
        color: #fff;
    }
`;

const BorderedButtonStyles = css`
    background-color: transparent;
    border-color: ${primaryColor};
    border: 2px solid;
    border-radius: 25px;
    color: #39996d;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${hoverBordered};
        transition: 0.2s;
        color: #ffffff;
    }
`;

const LinkButtonStyles = css`
    background-color: transparent;
    color: ${primaryColor};
    border: none;
    &.ant-btn {
        padding: 0px;
    }
    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: transparent;
        color: #39996d;
        border: none;
    }
`;

const QuaternaryButtonStyles = css`
    background-color: ${quaternaryColor} !important;
    color: ${defaultRadius};
    border-color: ${quaternaryColor} !important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${hoverTertiary} !important;
        border-color: ${hoverBordered} !important;
    }
`;

const LightButtonStyles = css`
    background-color: transparent;
    color: ${primaryColor};
    border: none;
    box-shadow: none;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${primaryColor};
        color: ${lightColor};
    }
`;

const TransparentButtonStyles = css`
    background-color: transparent;
    box-shadow: none;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: transparent;
        color: ${primaryColor};
        border-color: ${primaryColor};
    }
`;

export default styled(Button)`
    &&& {
        height: ${({ type }) => type !== 'link' && 40}px;
        ${({ shape }) => !shape && `border-radius: ${defaultRadius}`};
        border-radius: ${defaultRadius};
        border-color: ${borderColor};
        box-shadow: ${({ type }) => type !== 'link' && defaultShadow};
        ${({ type }) => {
            switch (type) {
                case 'primary':
                    return PrimaryButtonStyles;
                case 'secondary':
                    return SecondaryButtonStyles;
                case 'secondary-outlined':
                    return SecondaryOutlinedButtonStyles;
                case 'secondaryReverse':
                    return SecondaryButtonReverseStyles;
                case 'tertiary':
                    return TertiaryButtonStyles;
                case 'tertiary-outlined':
                    return TertiaryOutlinedButtonStyles;
                case 'quaternary':
                    return QuaternaryButtonStyles;
                case 'border':
                    return BorderedButtonStyles;
                case 'link':
                    return LinkButtonStyles;
                case 'light':
                    return LightButtonStyles;
                case 'transparent':
                    return TransparentButtonStyles;
                default:
                    break;
            }
        }}
    }
`;
