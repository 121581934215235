import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import authStoreReducer from './auth/reducer';
import scheduleStoreReducer from './schedule/reducer';
import mealReducer from './common/meals/reducer';
import chefsMealsReducer from './Chefs/meals/reducer';
import ordersStoreReducer from './orders/reducer';
import orderStoreReducer from './order/reducer';
import basketStoreReducer from './basket/reducer';
import favMealsReducer from './favmeals/reducer';
import commentStoreReducer from './comment/reducer';
import contractsStoreReducer from './contracts/reducer';
import settingsReducer from './settings/reducer';
import userInfoReducer from './Admin/UserInfo/reducer';
import xmlHttpRequestService from '../Api/request';
import menuSettingsReducer from './Chefs/menuSettings/reducer';
import searchbarMealsReducer from './searchbar/reducer';
import landingReducer from './landing/reducer';
import currencyReducer from './currency/reducer';
import notificationReducer from './notification/reducer';
import layoutReducer from './layout/header/reducer';
import bannerReducer from './layout/banner/reducer';
import footerReducer from './layout/footer/reducer';
import paymentReducer from './payment/reducer';
import cardsReducer from './payment/CreditCards/reducer';
import verificationReducer from './auth/verification/reducer';
import payoutReducer from './payment/Payout/reducer';
import mailReducer from './payment/Mail/reducer';
import storeReducer from './VidiStore/reducer';
import userReducer from './user/reducer';

const reducers = combineReducers({
    authStore: authStoreReducer,
    scheduleStore: scheduleStoreReducer,
    mealStore: mealReducer,
    chefsMealsStore: chefsMealsReducer,
    ordersStore: ordersStoreReducer,
    orderStore: orderStoreReducer,
    basketStore: basketStoreReducer,
    favMealsStore: favMealsReducer,
    commentStore: commentStoreReducer,
    contractsStore: contractsStoreReducer,
    settingsStore: settingsReducer,
    userInfoStore: userInfoReducer,
    menuSettingsStore: menuSettingsReducer,
    searchbarMealsStore: searchbarMealsReducer,
    landingStore: landingReducer,
    currencyStore: currencyReducer,
    notificationStore: notificationReducer,
    layoutStore: layoutReducer,
    bannerStore: bannerReducer,
    footerStore: footerReducer,
    paymentStore: paymentReducer,
    cardsStore: cardsReducer,
    verificationStore: verificationReducer,
    payoutStore: payoutReducer,
    mailStore: mailReducer,
    vidieatsStore: storeReducer,
    userStore: userReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk.withExtraArgument(xmlHttpRequestService))));

export default store;
