import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    orders: null,
    basketOrders: null,
    count: 0,
};

const getOrdersStartReducer = (state) => ({
    ...state,
    loading: true,
});

const getOrdersSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    orders: action.payload.results,
    count: action.payload.count,
});

const getOrdersFailReducer = (state) => ({
    ...state,
    loading: false,
});

const createOrdersReducer = (state) => ({
    ...state,
    loading: true,
});

const createOrdersSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const createOrdersFailReducer = (state) => ({
    ...state,
    loading: false,
});

const createBasketOrdersReducer = (state) => ({
    ...state,
    loading: true,
});

const createBasketOrdersSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const createBasketOrdersFailReducer = (state) => ({
    ...state,
    loading: false,
});

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ORDERS_START:
            return getOrdersStartReducer(state);
        case actionTypes.GET_ORDERS_SUCCESS:
            return getOrdersSuccessReducer(state, action);
        case actionTypes.GET_ORDERS_FAIL:
            return getOrdersFailReducer(state);

        case actionTypes.CREATE_ORDERS:
            return createOrdersReducer(state);
        case actionTypes.CREATE_ORDERS_SUCCESS:
            return createOrdersSuccessReducer(state);
        case actionTypes.CREATE_ORDERS_FAIL:
            return createOrdersFailReducer(state);

        case actionTypes.CREATE_BASKET_ORDERS:
            return createBasketOrdersReducer(state);
        case actionTypes.CREATE_BASKET_ORDERS_SUCCESS:
            return createBasketOrdersSuccessReducer(state);
        case actionTypes.CREATE_BASKET_ORDERS_FAIL:
            return createBasketOrdersFailReducer(state);

        default:
            return state;
    }
};

export default reducer;
