/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';
import { Text } from '../../../Typography/styles';
import { NavigationMenuStyles, MenuButton } from './styles';
import { logout } from '../../../../../services/Redux/auth/actions';

const NavigationMenu = ({ type }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('shared');
    const headerNavigation = t('layout.header.headerPopup', { returnObjects: true });
    const hamburgerNavigationForChefs = t('layout.header.headerHamburgerForChefs', { returnObjects: true });
    const hamburgerNavigationForGuests = t('layout.header.headerHamburgerForGuests', { returnObjects: true });
    const headerHamburgerForCustomers = t('layout.header.headerHamburgerForCustomers', { returnObjects: true });
    const { lg } = useResponsive();

    const { notificationsCount } = useSelector((state) => state.notificationStore);

    const chefPopoverRoutes = [
        {
            id: 1,
            title: headerNavigation.overview,
            path: '/chefs/overview',
            className: 'popoveroverview',
        },
        {
            id: 2,
            title: `${headerNavigation.notification} (${notificationsCount})`,
            path: '/chefs/notification',
        },
        {
            id: 3,
            title: headerNavigation.order,
            path: '/chefs/orders',
        },
        {
            id: 4,
            title: headerNavigation.favourite,
            path: '/chefs/favourites',
        },
        {
            id: 5,
            title: headerNavigation.myBasket,
            path: '/basket',
        },
        {
            id: 6,
            title: headerNavigation.setting,
            path: '/chefs/settings',
            className: 'popoversettings',
        },
        {
            id: 7,
            title: headerNavigation.helpCenter,
            path: '/help-center',
        },
        {
            id: 8,
            title: headerNavigation.logout,
            path: '/',
            onClick: logout,
        },
    ];

    const chefHamburgerRoutes = [
        {
            id: 1,
            title: hamburgerNavigationForChefs.overview,
            path: '/chefs/overview',
            className: 'popoveroverview',
        },
        {
            id: 2,
            title: `${headerNavigation.notification} (${notificationsCount})`,
            path: '/chefs/notification',
        },
        {
            id: 3,
            title: hamburgerNavigationForChefs.order,
            path: '/chefs/orders',
        },
        {
            id: 4,
            title: hamburgerNavigationForChefs.favourite,
            path: '/chefs/favourites',
        },
        {
            id: 5,
            title: hamburgerNavigationForChefs.myBasket,
            path: '/basket',
        },
        {
            id: 6,
            title: hamburgerNavigationForChefs.setting,
            path: '/chefs/settings',
            className: 'popoversettings',
        },
        {
            id: 7,
            title: hamburgerNavigationForChefs.helpCenter,
            path: '/help-center',
        },
        {
            id: 7,
            title: hamburgerNavigationForChefs.becomeACourrier,
            path: '/become-a-courier',
        },
        {
            id: 7,
            title: hamburgerNavigationForChefs.vidiStore,
            path: '/store',
        },
        {
            id: 8,
            title: hamburgerNavigationForChefs.homeFood,
            path: '/homefood',
        },
        {
            id: 9,
            title: hamburgerNavigationForChefs.logout,
            path: '/',
            onClick: logout,
        },
    ];

    const costomerPopOverRoutes = [
        {
            id: 1,
            title: headerHamburgerForCustomers.myProfile,
            path: '/customer/profile',
        },
        {
            id: 2,
            title: `${headerNavigation.notification} (${notificationsCount})`,
            path: '/customer/notifications',
        },
        {
            id: 3,
            title: headerHamburgerForCustomers.order,
            path: '/customer/orders',
        },
        {
            id: 4,
            title: headerHamburgerForCustomers.favourite,
            path: '/customer/favourites',
        },
        {
            id: 5,
            title: headerHamburgerForCustomers.myBasket,
            path: '/basket',
        },
        {
            id: 6,
            title: headerHamburgerForCustomers.setting,
            path: '/customer/settings',
        },
        {
            id: 7,
            title: headerHamburgerForCustomers.helpCenter,
            path: '/help-center',
        },
        {
            id: 8,
            title: headerHamburgerForCustomers.logout,
            path: '/',
            onClick: logout,
        },
    ];

    const costomerHamburgerRoutes = [
        {
            id: 1,
            title: headerHamburgerForCustomers.myProfile,
            path: '/customer/profile',
        },
        {
            id: 2,
            title: `${headerNavigation.notification} (${notificationsCount})`,
            path: '/customer/notifications',
        },
        {
            id: 3,
            title: headerHamburgerForCustomers.order,
            path: '/customer/orders',
        },
        {
            id: 4,
            title: headerHamburgerForCustomers.favourite,
            path: '/customer/favourites',
        },
        {
            id: 5,
            title: headerHamburgerForCustomers.myBasket,
            path: '/basket',
        },
        {
            id: 6,
            title: headerHamburgerForCustomers.setting,
            path: '/customer/settings',
        },
        {
            id: 7,
            title: headerHamburgerForCustomers.helpCenter,
            path: '/help-center',
        },
        {
            id: 8,
            title: headerHamburgerForCustomers.about,
            path: '/about',
        },
        {
            id: 9,
            title: headerHamburgerForCustomers.becomeAchef2,
            path: '/become-a-chef',
        },
        {
            id: 10,
            title: headerHamburgerForCustomers.becomeACourrier,
            path: '/become-a-courier',
        },
        {
            id: 11,
            title: headerHamburgerForCustomers.logout,
            path: '/',
            onClick: logout,
        },
    ];

    const guestPopoverRoutes = [
        {
            id: 8,
            title: hamburgerNavigationForGuests.register,
            path: '/signup',
        },
        {
            id: 9,
            title: hamburgerNavigationForGuests.login,
            path: '/login',
        },
        {
            id: 10,
            title: hamburgerNavigationForGuests.becomeAchef,
            path: '/chef-signup',
        },
        {
            id: 11,
            title: hamburgerNavigationForGuests.helpCenter,
            path: '/help-center',
        },
    ];

    const guestHamburgerRoutes = [
        {
            id: 8,
            title: hamburgerNavigationForGuests.register,
            path: '/signup',
        },
        {
            id: 9,
            title: hamburgerNavigationForGuests.login,
            path: '/login',
        },
        {
            id: 10,
            title: hamburgerNavigationForGuests.becomeAchef,
            path: '/chef-signup',
        },
        {
            id: 11,
            title: hamburgerNavigationForGuests.helpCenter,
            path: '/help-center',
        },
        {
            id: 9,
            title: hamburgerNavigationForGuests.about,
            path: '/about',
        },
        {
            id: 10,
            title: hamburgerNavigationForGuests.becomeAchef2,
            path: '/become-a-chef',
        },
        {
            id: 11,
            title: hamburgerNavigationForGuests.becomeACourrier,
            path: '/become-a-courier',
        },
    ];

    const history = useHistory();

    switch (type) {
        case 'customer':
            return (
                <>
                    {lg ? (
                        <NavigationMenuStyles span={24}>
                            {costomerHamburgerRoutes.map((x) => (
                                <MenuButton
                                    key={x.id}
                                    className={
                                        (x.title === 'Settings' && 'menu-settings-title-border') ||
                                        (x.title === 'Ayarlar' && 'menu-settings-title-border')
                                    }
                                    type="link"
                                    onClick={() => {
                                        if (x.onClick) {
                                            dispatch(x.onClick());
                                        }
                                        history.push(x.path);
                                    }}>
                                    <Text disableSelect>{x.title}</Text>
                                </MenuButton>
                            ))}
                        </NavigationMenuStyles>
                    ) : (
                        <NavigationMenuStyles span={24}>
                            {costomerPopOverRoutes.map((x) => (
                                <MenuButton
                                    key={x.id}
                                    className={
                                        (x.title === 'Settings' && 'menu-settings-title-border') ||
                                        (x.title === 'Ayarlar' && 'menu-settings-title-border')
                                    }
                                    type="link"
                                    onClick={() => {
                                        if (x.onClick) {
                                            dispatch(x.onClick());
                                        }
                                        history.push(x.path);
                                    }}>
                                    <Text disableSelect>{x.title}</Text>
                                </MenuButton>
                            ))}
                        </NavigationMenuStyles>
                    )}
                </>
            );

        case 'chefs':
            return (
                <>
                    {lg ? (
                        <NavigationMenuStyles span={24}>
                            {chefHamburgerRoutes.map((i) => (
                                <MenuButton
                                    key={i.id}
                                    className={
                                        i.title === 'Settings' || i.title === 'Ayarlar' ? `menu-settings-title-border ${i.className}` : i.className
                                    }
                                    type="link"
                                    onClick={() => {
                                        if (i.onClick) {
                                            dispatch(i.onClick());
                                        }
                                        history.push(i.path);
                                    }}>
                                    <Text disableSelect>{i.title}</Text>
                                </MenuButton>
                            ))}
                        </NavigationMenuStyles>
                    ) : (
                        <NavigationMenuStyles span={24}>
                            {chefPopoverRoutes.map((i) => (
                                <MenuButton
                                    key={i.id}
                                    className={
                                        i.title === 'Settings' || i.title === 'Ayarlar' ? `menu-settings-title-border ${i.className}` : i.className
                                    }
                                    type="link"
                                    onClick={() => {
                                        if (i.onClick) {
                                            dispatch(i.onClick());
                                        }
                                        history.push(i.path);
                                    }}>
                                    <Text disableSelect>{i.title}</Text>
                                </MenuButton>
                            ))}
                        </NavigationMenuStyles>
                    )}
                </>
            );
        default:
            return (
                <>
                    {lg ? (
                        <NavigationMenuStyles span={24}>
                            {guestHamburgerRoutes.map((j) => (
                                <MenuButton
                                    key={j.id}
                                    className={
                                        (j.title === 'For Chefs' && 'menu-settings-title-border') ||
                                        (j.title === 'Şefler/Üreticiler İçin' && 'menu-settings-title-border')
                                    }
                                    type="link"
                                    onClick={() => {
                                        history.push(j.path);
                                    }}>
                                    <Text disableSelect>{j.title}</Text>
                                </MenuButton>
                            ))}
                        </NavigationMenuStyles>
                    ) : (
                        <NavigationMenuStyles span={24}>
                            {guestPopoverRoutes.map((j) => (
                                <>
                                    <MenuButton
                                        key={j.id}
                                        className={
                                            (j.title === 'For Chefs' && 'menu-settings-title-border') ||
                                            (j.title === 'Şefler/Üreticiler İçin' && 'menu-settings-title-border')
                                        }
                                        type="link"
                                        onClick={() => {
                                            history.push(j.path);
                                        }}>
                                        <Text disableSelect>{j.title}</Text>
                                    </MenuButton>
                                </>
                            ))}
                        </NavigationMenuStyles>
                    )}
                </>
            );
    }
};

export default NavigationMenu;
