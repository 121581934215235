import * as actionTypes from './actionTypes';

const initialState = {
    loading: true,
    chefInfo: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CHEF_WITH_ID_SUCCESS:
            return { ...state, loading: false, chefInfo: action.payload };
        case actionTypes.GET_CHEF_WITH_ID_FAIL:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default reducer;
