export const AUTO_LOGIN = 'AUTO_LOGIN';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAILURE = 'AUTO_LOGIN_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SEND_EMAIL_VERIFICATION_STARTED = 'SEND_EMAIL_VERIFICATION_STARTED';
export const SEND_EMAIL_VERIFICATION_SUCCESS = 'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAIL = 'SEND_EMAIL_VERIFICATION_FAIL';

export const SEND_PHONE_NUMBER_STARTED = 'SEND_PHONE_NUMBER_STARTED';
export const SEND_PHONE_NUMBER_SUCCESS = 'SEND_PHONE_NUMBER_SUCCESS';
export const SEND_PHONE_NUMBER_FAIL = 'SEND_PHONE_NUMBER_FAIL';

export const CHEF_SIGNUP_STARTED = 'CHEF_SIGNUP_STARTED';
export const CHEF_SIGNUP_SUCCESS = 'CHEF_SIGNUP_SUCCESS';
export const CHEF_SIGNUP_FAILURE = 'CHEF_SIGNUP_FAILURE';

export const CHEF_LOGIN_STARTED = 'CHEF_LOGIN_STARTED';
export const CHEF_LOGIN_SUCCESS = 'CHEF_LOGIN_SUCCESS';
export const CHEF_LOGIN_FAILURE = 'CHEF_LOGIN_FAILURE';

export const CHEF_LOGIN_FIRST_STARTED = 'CHEF_LOGIN_FIRST_STARTED';
export const CHEF_LOGIN_FIRST_SUCCESS = 'CHEF_LOGIN_FIRST_SUCCESS';
export const CHEF_LOGIN_FIRST_FAILURE = 'CHEF_LOGIN_FIRST_FAILURE';

export const CUSTOMER_SIGNUP_STARTED = 'CUSTOMER_SIGNUP_STARTED';
export const CUSTOMER_SIGNUP_SUCCESS = 'CUSTOMER_SIGNUP_SUCCESS';
export const CUSTOMER_SIGNUP_FAILURE = 'CUSTOMER_SIGNUP_FAILURE';

export const CUSTOMER_LOGIN_STARTED = 'CUSTOMER_LOGIN_STARTED';
export const CUSTOMER_LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS';
export const CUSTOMER_LOGIN_FAILURE = 'CUSTOMER_LOGIN_FAILURE';

export const CUSTOMER_LOGIN_FIRST_STARTED = 'CUSTOMER_LOGIN_FIRST_STARTED';
export const CUSTOMER_LOGIN_FIRST_SUCCESS = 'CUSTOMER_LOGIN_FIRST_SUCCESS';
export const CUSTOMER_LOGIN_FIRST_FAILURE = 'CUSTOMER_LOGIN_FIRST_FAILURE';

export const USER_LOGIN_CREATE_DEVİCE_STARTED = 'USER_LOGIN_CREATE_DEVİCE_STARTED';
export const USER_LOGIN_CREATE_DEVİCE_SUCCESS = 'USER_LOGIN_CREATE_DEVİCE_SUCCESS';
export const USER_LOGIN_CREATE_DEVİCE_FAILURE = 'USER_LOGIN_CREATE_DEVİCE_FAILURE';
