// import { notification } from 'antd';
import * as actionTypes from './actionTypes';

export const notificationReceived = () =>
    // const res = data.object.replaceAll('"', '');
    // console.log(data.object);

    // notification.info({
    //     message,
    //     style: {
    //         marginBottom: '0px',
    //         height: '50px',
    //     },
    //     // duration: 0,
    // });

    ({
        type: actionTypes.NOTIFICATION_RECEIVED,
    });

// GET

export const getNotificationsStarted = () => ({
    type: actionTypes.GET_NOTIFICATIONS_STARTED,
});

export const getNotificationsSuccess = (data) => ({
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: data,
});

export const getNotificationsFail = () => ({
    type: actionTypes.GET_NOTIFICATIONS_FAIL,
});

// DELETE

export const deleteNotificationStarted = () => ({
    type: actionTypes.DELETE_NOTIFICATION_STARTED,
});

export const deleteNotificationSuccess = () => ({
    type: actionTypes.DELETE_NOTIFICATION_SUCCESS,
});

export const deleteNotificationFail = () => ({
    type: actionTypes.DELETE_NOTIFICATION_FAIL,
});
