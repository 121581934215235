import * as actionTypes from './actionTypes';

const initialState = {
    loading: true,
    value: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EMAIL_VERIFICATION_STARTED:
            return { loading: true };
        case actionTypes.EMAIL_VERIFICATION_SUCCESS:
            return { loading: false, value: action };
        case actionTypes.EMAIL_VERIFICATION_FAIL:
            return { loading: false };
        default:
            return state;
    }
};

export default reducer;
