/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable camelcase */
/* eslint-disable sonarjs/no-duplicate-string */
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import React, { useEffect, useState } from 'react';
import { Col, Row, Avatar, Badge, Tooltip, Space, AutoComplete, Modal, Popover } from 'antd';
import { useHistory } from 'react-router';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Joyride from 'react-joyride';
import ReactPlayer from 'react-player';
import PLAYICON from '../../../../assets/PlayIcon.svg';
import yemegimCoLogo from '../../../../assets/yemegimCo_logo_.png';
import { VideoPlayIcon, VideoStarter } from '../../../pages/Customer/BecomeAChef/styles';
import StyledHeader, {
    ChefComponentStyles,
    UserProfileComponent,
    LocalizationAndProfileGroup,
    // LocalizationIconWrapper,
    GlobalStyleForTooltip,
    GuestProfileIconWrapper,
    HamburgerMenu,
    Logo,
    LogoIcon,
    Menu,
    SearchIcon,
    SearchInput,
} from './styles';
import vidieatsLogo from '../../../../assets/shared/layout/vidieats-logo.svg';
import vidieatsLogoIcon from '../../../../assets/shared/layout/logo-icon.svg';
import SearchIconSrc from '../../../../assets/shared/layout/search-icon.svg';
// import localizationIconEN from '../../../../assets/shared/common/localization/EN.svg';
// import localizationIconTR from '../../../../assets/shared/common/localization/TR.svg';
import profileLogo from '../../../../assets/chefs/profile.svg';
// import localizationIconFR from '../../../../assets/shared/common/localization/FR.svg';
import { Text } from '../../Typography/styles';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import NavigationMenu from './NavigationMenu';
import GuestIconGroup from '../../../../assets/shared/layout/guestIcon.svg';
// import LocalizationMenu from './LocalizationMenu';
import { getHeaderInfo } from '../../../../services/Redux/layout/header/api';
import { getCustomerSettings, getChefSettings } from '../../../../services/Redux/settings/api';
import googleplayIcon from '../../../../assets/shared/layout/googleplay.svg';
import appstoreIcon from '../../../../assets/shared/layout/appstore.svg';
import audioMp3 from '../../../../assets/notificationSound/notification.mp3';

const HeaderNavItems = (type, t) => {
    if (type === 'chef') {
        return t('layout.header.navItemChef', { returnObjects: true });
    }
    if (type === 'customer') {
        return t('layout.header.navItemCustomer', { returnObjects: true });
    }
    return t('layout.header.navItemGuest', { returnObjects: true });
};

const ChefComponent = ({ showBadge }) => {
    // const chef = JSON.parse(localStorage.getItem('user'));
    // const { first_name, last_name, chefPhoto } = chef;
    // const name = first_name.length < 7 ? `${first_name} ${last_name[0]}.` : `${first_name[0]}.${last_name[0]}.`;
    const dispatch = useDispatch();
    const { settings } = useSelector((state) => state.settingsStore);
    const { xs } = useResponsive();
    const { notificationReceived } = useSelector((state) => state.notificationStore);

    useEffect(() => {
        dispatch(getChefSettings());
    }, [dispatch]);

    const playSound = async (url) => {
        const audio = new Audio(url);
        await audio.play();
    };

    // useEffect(() => {
    //     try {
    //         playSound(audioMp3);
    //         console.log('hata degil');
    //     } catch (error) {
    //         console.log('hata');
    //     }
    // }, [notificationReceived]);

    useEffect(() => {
        setTimeout(() => {
            document?.getElementById('id')?.click();
        }, 1000);
    }, []);

    return (
        <Tooltip className="my-other-step" placement="bottomRight" title={<NavigationMenu type="chefs" />} trigger={xs ? 'click' : 'hover'}>
            <HamburgerMenu />
            <Popover className="dummySection" title="Title" trigger="click" />
            <ChefComponentStyles align="middle" justify="center">
                <UserProfileComponent className="profSection">
                    <Text ellipsis={{ tooltip: true }} className="text-padding" color="white" size="small">
                        {settings ? `${settings.first_name} ${settings.last_name[0]}.` : ''}
                    </Text>
                    {/* <button id="id" onClick={() => playSound(audioMp3)}>
                       Play TODO
                    </button> */}
                    {showBadge ? (
                        <Badge dot size="default">
                            <Avatar shape="circle" icon={<img className="img-fill" src={settings?.chefPhoto || profileLogo} alt="" srcSet="" />} />
                        </Badge>
                    ) : (
                        <Avatar shape="circle" icon={<img className="img-fill" src={settings?.chefPhoto || profileLogo} alt="" srcSet="" />} />
                    )}
                </UserProfileComponent>
            </ChefComponentStyles>
        </Tooltip>
    );
};
const CustomerComponent = ({ showBadge }) => {
    const dispatch = useDispatch();
    const { settings } = useSelector((state) => state.settingsStore);
    // const customer = JSON.parse(localStorage.getItem('user'));
    // const { first_name, last_name, customerPhoto } = customer;
    const { xs } = useResponsive();

    useEffect(() => {
        dispatch(getCustomerSettings());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <Tooltip placement="bottomRight" title={<NavigationMenu type="customer" />} trigger={xs ? 'click' : 'hover'}>
            <HamburgerMenu />
            <ChefComponentStyles align="middle" justify="center">
                <UserProfileComponent>
                    <Text ellipsis={{ tooltip: true }} className="text-padding" color="white" size="small">
                        {settings ? `${settings.first_name} ${settings.last_name[0]}.` : ''}
                    </Text>
                    {showBadge ? (
                        <Badge dot size="default">
                            <Avatar
                                shape="circle"
                                icon={<img className="img-fill" src={settings?.customerPhoto || profileLogo} alt="" srcSet="" />}
                            />
                        </Badge>
                    ) : (
                        <Avatar shape="circle" icon={<img className="img-fill" src={settings?.customerPhoto || profileLogo} alt="" srcSet="" />} />
                    )}
                </UserProfileComponent>
            </ChefComponentStyles>
        </Tooltip>
    );
};

const GuestComponent = () => {
    const { xs } = useResponsive();
    return (
        <Tooltip placement="bottomRight" title={<NavigationMenu />} trigger={xs ? 'click' : 'hover'}>
            <HamburgerMenu />
            <GuestProfileIconWrapper>
                <img className="guest-icon-styles" src={GuestIconGroup} alt="" srcSet="" />
            </GuestProfileIconWrapper>
        </Tooltip>
    );
};

// const LocalizationIcon = () => {
//     const lang = localStorage.getItem('i18nextLng');
//     const { xs } = useResponsive();
//     return (
//         <Tooltip placement="bottomRight" title={<LocalizationMenu />} trigger={xs ? 'click' : 'hover'}>
//             {(() => {
//                 if (lang === 'tr') {
//                     return <img src={localizationIconTR} alt="localization-tr" />;
//                 }
//                 // if (lang === 'fr') {
//                 //     return <img src={localizationIconFR} alt="localization-fr" />;
//                 // }
//                 return <img src={localizationIconEN} alt="localization-en" />;
//             })()}
//         </Tooltip>
//     );
// };

const Header = ({ userRole }) => {
    const dispatch = useDispatch();
    const [language, setLanguage] = useState();
    const [showBadge, setShowBadge] = useState();

    const [inputOpen, setInputOpen] = useState(false);

    const { notificationReceived, notificationsCount } = useSelector((state) => state.notificationStore);
    const { settings } = useSelector((state) => state.settingsStore);
    const { categories } = useSelector((state) => state.layoutStore);

    const { xs, lg } = useResponsive();
    const { i18n, t } = useTranslation('shared');
    const history = useHistory();
    const location = useLocation();
    const { Item } = Menu;
    const [options] = useState([]);
    const [foodNameSearchText, setFoodNameSearchText] = useState();

    useEffect(() => {
        localStorage.setItem('i18nextLng', i18n.language);
        setLanguage(i18n.language);

        if (!categories) {
            dispatch(getHeaderInfo());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        setShowBadge(notificationReceived);
    }, [notificationReceived]);

    const handleSearch = (inputText) => {
        setFoodNameSearchText(inputText);
    };

    const openSearchInput = () => {
        setInputOpen(!inputOpen);
    };

    const onSelect = async () => {
        if (!location.pathname.includes('near-places')) {
            const data = foodNameSearchText;
            setFoodNameSearchText('');
            await history.push(`near-places`, { searchFoodName: data });
        }
        setInputOpen();
    };

    useEffect(() => {
        if (foodNameSearchText) {
            setFoodNameSearchText('');
        }
    }, [location]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e.target.value);
            onSelect();
        }
    };

    const steps = [
        {
            target: '.my-first-step',
            content: `${settings?.first_name} ${settings?.last_name}${t('searchbar.step1Text')}`,
            placement: 'center',
            offset: 10,
            locale: {
                next: <span>{t('searchbar.nextButtonText')}</span>,
            },
        },
        {
            target: '.search-bar',
            placement: 'top',
            content: `${t('searchbar.step2Text')}`,
            locale: {
                next: <span>{t('searchbar.nextButtonText')}</span>,
                back: <span>{t('searchbar.backButtonText')}</span>,
            },
            options: {
                width: 900,
            },
        },
        {
            target: '.explore',
            placement: 'top',
            content: `${t('searchbar.step3Text')}`,
            locale: {
                next: <span>{t('searchbar.nextButtonText')}</span>,
                back: <span>{t('searchbar.backButtonText')}</span>,
            },
        },
        {
            target: '.bestseller',
            placement: 'auto',
            content: `${t('searchbar.step4Text')}`,
            locale: {
                next: <span>{t('searchbar.nextButtonText')}</span>,
                back: <span>{t('searchbar.backButtonText')}</span>,
            },
            offset: 32,
        },

        {
            target: '.profSection',
            placement: 'bottom',
            content: `${t('searchbar.step5Text')}`,
            locale: {
                last: <span>{t('searchbar.nextButtonText')}</span>,
                back: <span>{t('searchbar.backButtonText')}</span>,
            },
        },
        {
            target: '.dummySection',
            placement: 'center',
            content: (
                <ReactPlayer
                    width="100%"
                    height="400px"
                    controls
                    playing
                    playIcon={
                        <VideoStarter>
                            <VideoPlayIcon src={PLAYICON} alt="play" />
                        </VideoStarter>
                    }
                    url="https://vimeo.com/722884751"
                    // light=
                />
            ),
            locale: {
                last: <span>{t('searchbar.lastButtonText')}</span>,
                back: <span>{t('searchbar.backButtonText')}</span>,
            },
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(true);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <StyledHeader>
            {xs ? (
                <Modal
                    style={{ display: 'flex', alignItems: 'center', paddingTop: '50%', justifyContent: 'center' }}
                    footer={false}
                    visible={isModalVisible}
                    onCancel={handleCancel}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <p>{t('searchbar.mobileWarning')}</p>

                        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
                            <a target="_blank" href="https://apps.apple.com/us/app/yemegim-co/id6475003527" rel="noreferrer">
                                <img src={appstoreIcon} alt="appstore-icon" className="appstore-logo" />
                            </a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.yemegimco" rel="noreferrer">
                                <img src={googleplayIcon} alt="googleplay-icon" className="googleplay-logo" />
                            </a>
                        </div>
                    </div>
                </Modal>
            ) : null}

            {!localStorage.getItem('skipTut') && localStorage.getItem('role') === 'chef' && (
                <Joyride
                    disableCloseOnEsc
                    continuous
                    disableScrolling
                    callback={(data) => {
                        if (data?.index === steps.length || data?.status === 'finished') {
                            document.querySelector('.my-other-step')?.click();
                            localStorage.setItem('skipTut', true);
                        }
                    }}
                    showProgress
                    steps={steps}
                    styles={{
                        options: {
                            arrowColor: '#ffffffea',
                            backgroundColor: '#ffffffea',
                            width: '720px',
                            primaryColor: '#39996d',
                            textColor: '#000000b2',
                            borderColor: '#39996d',
                            zIndex: 1000,
                        },
                    }}
                />
            )}

            <Row align="middle" style={{ minHeight: '63px' }}>
                <Col xs={10} sm={10} md={4} lg={3} xl={5} xxl={6}>
                    <Row className="my-first-step" align="middle" justify="start">
                        <Link to="/">
                            <Logo src={yemegimCoLogo} alt="yemegimCo-logo" />
                            <LogoIcon src={vidieatsLogoIcon} alt="vidieats-logo" />
                        </Link>
                    </Row>
                </Col>
                <Col xs={14} sm={14} md={20} lg={21} xl={19} xxl={18}>
                    <Row align="middle" justify="end" gutter={8}>
                        <Menu className="selam" style={{ display: lg || inputOpen ? 'none' : '' }} theme="light" mode="horizontal">
                            {userRole
                                ? HeaderNavItems(userRole, t).map(({ id, title, path }) => (
                                      <Item onClick={() => history.push(path)} key={`menu-item-${id}`}>
                                          {title}
                                      </Item>
                                  ))
                                : categories?.map((menuItem) => (
                                      <Item className={menuItem.id} onClick={() => history.push(menuItem.link)} key={`menu-item-${menuItem.id}`}>
                                          {menuItem[`name_${language}`]}
                                      </Item>
                                  ))}
                        </Menu>
                        <LocalizationAndProfileGroup align="middle" justify="end" style={{ width: xs && '80%' }}>
                            <Space direction="horizontal" size="large">
                                <LocalizationAndProfileGroup
                                    align="middle"
                                    justify="end"
                                    style={{ display: location.pathname === '/near-places' ? 'none' : '', width: xs && '80%' }}>
                                    <AutoComplete
                                        options={options}
                                        value={foodNameSearchText}
                                        onKeyDown={handleKeyDown}
                                        onSelect={onSelect}
                                        onSearch={handleSearch}>
                                        <SearchInput
                                            style={{ width: inputOpen ? '100%' : '0%', marginRight: inputOpen ? '0%' : '-150px' }}
                                            bordered={false}
                                            value={foodNameSearchText}
                                            placeholder={t('searchbar.headerPlaceholder')}
                                            suffix={<SearchIcon src={SearchIconSrc} alt="search-icon" onClick={openSearchInput} />}
                                            enterButton
                                        />
                                    </AutoComplete>
                                </LocalizationAndProfileGroup>
                                {/* <LocalizationIconWrapper>
                                    <LocalizationIcon />
                                </LocalizationIconWrapper> */}
                                <GlobalStyleForTooltip />
                                {(() => {
                                    if (userRole === 'chef') {
                                        return <ChefComponent notificationsCount={notificationsCount} showBadge={showBadge} />;
                                    }
                                    if (userRole === 'customer') {
                                        return <CustomerComponent notificationsCount={notificationsCount} showBadge={showBadge} />;
                                    }
                                    return <GuestComponent />;
                                })()}
                            </Space>
                        </LocalizationAndProfileGroup>
                    </Row>
                </Col>
            </Row>
        </StyledHeader>
    );
};

export default Header;
