import * as actionTypes from './actionTypes';

const initialState = {
    footerCategories: null,
    socialMedias: null,
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_FOOTER_CATEGORIES_STARTED:
            return { ...state, loading: true };
        case actionTypes.GET_FOOTER_CATEGORIES_SUCCESS:
            return { ...state, footerCategories: action.payload.results, loading: !action.payload };
        case actionTypes.GET_FOOTER_CATEGORIES_FAIL:
            return { ...state, loading: false };
        case actionTypes.GET_SOCIAL_MEDIAS_SUCCESS:
            return { ...state, socialMedias: action.payload.results };
        default:
            return state;
    }
};

export default reducer;
