import * as actionTypes from './actionTypes';

const initialState = {
    loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_EMAIL_NOTIFICATION_SUCCESS:
            return { loading: false };
        case actionTypes.SEND_EMAIL_NOTIFICATION_FAIL:
            return { loading: false };
        default:
            return state;
    }
};

export default reducer;
