export const GET_CHEF_INFO_STARTED = 'GET_CHEF_INFO_STARTED';
export const GET_CHEF_INFO_SUCCESS = 'GET_CHEF_INFO_SUCCESS';
export const GET_CHEF_INFO_FAIL = 'GET_CHEF_INFO_FAIL';

export const GET_CUSTOMER_INFO_STARTED = 'GET_CUSTOMER_INFO_STARTED';
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS';
export const GET_CUSTOMER_INFO_FAIL = 'GET_CUSTOMER_INFO_FAIL';

export const SUSPEND_USER_STARTED = 'SUSPEND_USER_STARTED';
export const SUSPEND_USER_SUCCESS = 'SUSPEND_USER_SUCCESS';
export const SUSPEND_USER_FAIL = 'SUSPEND_USER_FAIL';

export const TERMINATE_USER_STARTED = 'TERMINATE_USER_STARTED';
export const TERMINATE_USER_SUCCESS = 'TERMINATE_USER_SUCCESS';
export const TERMINATE_USER_FAIL = 'TERMINATE_USER_FAIL';
