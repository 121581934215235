import * as actionTypes from './actionTypes';

//  GET CATEGORIES
export const getFooterCategoriesStartedAction = () => ({
    type: actionTypes.GET_FOOTER_CATEGORIES_STARTED,
});

export const getFooterCategoriesSuccessAction = (data) => ({
    type: actionTypes.GET_FOOTER_CATEGORIES_SUCCESS,
    payload: data,
});

export const getFooterCategoriesFailAction = () => ({
    type: actionTypes.GET_FOOTER_CATEGORIES_FAIL,
});

//  GET SOCIAL MEDIAS
export const getSocialMediasStartedAction = () => ({
    type: actionTypes.GET_SOCIAL_MEDIAS_STARTED,
});

export const getSocialMediasSuccessAction = (data) => ({
    type: actionTypes.GET_SOCIAL_MEDIAS_SUCCESS,
    payload: data,
});

export const getSocialMediasFailAction = () => ({
    type: actionTypes.GET_SOCIAL_MEDIAS_FAIL,
});
