/* eslint-disable sonarjs/no-duplicate-string */
export default {
    general: {
        moreDetail: 'More Detail',
        addtoBasket: 'Add to Basket',
        removeMessage: 'Selected meal successfully removed from your favourites',
        addedMessage: 'Selected meal successfully added to your favourites',
        notSale: 'not in sale',
        onSale: 'on sale',
        waiting: 'Waiting Confirmation',
        edit: 'Edit',
        min: 'mins',
        portion: 'porti..',
    },
    favourites: {
        title: 'Favourites',
        emptyFavourite: "You don't have any favourite meals.",
    },
    notification: {
        title: 'Notifications',
        emptyNotif: 'Şu anda bildirim bulunmamaktadır.',
    },
    order: {
        title: 'Orders',
        emptyNotif: 'Şu anda bildirim bulunmamaktadır.',
        noOrder: "You don't have any orders at this moment.",
        today: 'Today',
        past: 'Past',
        future: 'Future',
        preparing: 'Preparing Order',
        cancelled: 'Order Cancelled',
        completed: 'Order Completed',
        waiting: 'Waiting Confirmation',
        warningMessage: 'Are you sure? This is irreversible!',
    },
    evaluations: {
        welcome: 'Welcome',
        title: 'My Evaluations',
        emptyEvaluation: "You don't have any evaluations.",
    },
    setting: {
        title: 'Account Settings',
        personalInformation: 'Personal Information',
        paymentMethods: 'Payment Methods',
        notificationSettings: 'Notification Settings',
        securityPassword: 'Security/Password',
        generalPreferences: 'General Preferences',
        payment: {
            info: 'You can view the cards you use to pay here and delete any card you want.',
            cardName: 'Card Name',
            cardNo: 'Card No',
            cardholderName: 'Cardholder Name',
            expirationDate: 'Expiration Date',
            paymentCheck: 'I confirm fees and charges and online experiences',
            whatsCVC2: "What's CVC2?",
            month: 'Month',
            year: 'Year',
            areYouSure: 'Are you sure ?',
            cancel: 'Cancel',
            approve: 'Approve',
            messages: {
                cardName: 'Please input a card name!',
                cardNo: 'Please input the card numbers!',
                cardholderName: 'Please input the cardholder name!',
                month: 'Please select the expiration month!',
                year: 'Please select the expiration year!',
            },
            addCard: 'Add Card',
        },
        notification: {
            messages: 'Messages',
            messagesInfo: 'Receive messages about your comments and orders, including tracking your orders.',
            email: 'E-mail',
            textMessages: 'Text Messages',
            browserNotifications: 'Browser notifications',
            browserNotificationsInfo: 'Receive notifications outside the browser via desktop and mobile web',
            reminders: 'Reminders',
            remindersInfo: 'Receive order reminders, review requests, pricing notifications, and other reminders about Online Experience events.',
            choosePhone: 'Choose a Phone',
            textMessagesTop:
                'By checking the SMS box, you agree to receive promotional messages that will be automatically sent to the address below:',
            textMessagesBottom:
                'For more information, send it in a text message to 123456 with INFO. To cancel mobile notifications, reply to 123456 by typing CANCEL. Message and data charges may apply.',
        },
        generalPreference: {
            preferredLanguage: 'Preferred Language',
            language: 'Language',
            turkishLira: 'Turkish Lira',
            preferredCurrency: 'Preferred Currency',
            timeZone: 'Time Zone',
            turkish: 'Turkish',
            messages: {
                preferredLanguage: 'Please select a preferred language!',
                preferredCurrency: 'Please select a preferred currency!',
                timeZone: 'Please select a time zone!',
            },
            generalChoices: 'Your General Choices',
            generalChoicesInfo:
                'When you change your currency The way prices are displayed also changes. The way you receive your payments, payments and You can change it in your payment preferences section.',
        },
    },
    SignupPage: {
        emailCard: {
            countryRegion: 'Country / Region',
            errorMessage: 'Something went wrong',
            sign: 'Sign up',
            login: 'Log in',
            moreOption: 'More option',
            haveAccount: 'Do you have an account?',
            forgotPassword: 'Forgot my password',
            firstNameMessage: 'Please enter your first name',
            firstNamePlaceHolder: 'First name',
            lastNameMessage: 'Please enter your last name',
            lastNamePlaceHolder: 'Last name',
            countryCodeMessage: 'Please enter your country code',
            phoneNumberMessage: 'Please enter your phone number',
            phoneNumberPlaceHolder: 'Phone number (5XXXXXXXXX)',
            emailMessage: 'Please enter your e-mail',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Your password must contain at least 8 characters and at least 1 uppercase letter',
            passwordPlaceHolder: 'Password',
            passwordAgainMessage: 'Please enter your password again',
            passwordAgainPlaceHolder: 'Password repeat',
            passwordError: 'Passwords dont match',
        },
        otpInputCard: {
            sign: 'Sign up',
            login: 'Log in',
            continue: 'Continue',
            haveAccount: 'Already have an account?',
            dontHaveAccount: "Don't have an account?",
            registrationCode: 'Please enter the registration code.',
            digitMessage: 'SMS sent to your phone number ending with *****',
            digitCodeMessage: 'Please enter the 6 digit code.',
            resendCode: 'Resend Code',
        },
        signupCard: {
            sign: 'Sign up',
            login: 'Log in',
            message: 'There exists an account with this email. Please login with your password',
            text: "Turn on notifications? Don't miss important messages like new delicious and online experiences",
            haveAccount: 'Do you have an account?',
        },
    },
    LoginPage: {
        loginCard: {
            login: 'Log in',
            message: 'There exists an account with this email. Please login with your password',
            create: 'Create one.',
            emailMessage: 'Please enter your e-mail',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Please enter your password',
            passwordPlaceHolder: 'Password',
            wrongPassword: 'Wrong password or e-mail!',
            countryRegion: 'Country / Region',
            countryCodeMessage: 'Please enter your country code',
            phoneNumberMessage: 'Please enter your phone number',
            phoneNumberPlaceHolder: 'Phone number (5XXXXXXXXX)',
            notificationText: "Turn on notifications? Don't miss important messages like new delicious and online experiences",
            continue: 'Continue',
            textOne: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
            textTwo: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
            haveAccount: "Don't have an account?",
            forgotPassword: 'Forgot Password ?',
        },
        provider: {
            email: 'Continue with E-mail',
            apple: 'Continue with Apple',
            google: 'Continue with Google',
            sms: 'Continue with Sms',
            facebook: 'Continue with Facebook',
        },
    },
};
