import * as actionTypes from './actionTypes';

const initialState = {
    layoutInfo: null,
    categories: null,
    loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_HEADER_INFO_STARTED:
            return { ...state, loading: true };
        case actionTypes.GET_HEADER_INFO_SUCCESS:
            return { ...state, layoutInfo: action.payload, categories: action.payload.categories, loading: !action.payload };
        case actionTypes.GET_HEADER_INFO_FAIL:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default reducer;
