export const GET_LANDING_MEALS_START = 'GET_LANDING_MEALS_START';
export const GET_LANDING_MEALS_SUCCESS = 'GET_LANDING_MEALS_SUCCESS';
export const GET_LANDING_MEALS_FAIL = 'GET_LANDING_MEALS_FAIL';

export const GET_LANDING_VIDEO_START = 'GET_LANDING_VIDEO_START';
export const GET_LANDING_VIDEO_SUCCESS = 'GET_LANDING_VIDEO_SUCCESS';
export const GET_LANDING_VIDEO_FAIL = 'GET_LANDING_VIDEO_FAIL';

export const SET_LOCATION_STARTED = 'SET_LOCATION_STARTED';
export const SET_LOCATION_SUCCESS = 'SET_LOCATION_SUCCESS';
