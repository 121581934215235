import * as actionTypes from './actionTypes';

const initialState = {
    notifications: null,
    notificationReceived: false,
    notificationsCount: 0,
    allNotificationsCount: 0,
};
const notificationReceivedReducer = (state) => {
    const arr = { ...state };

    arr.notificationsCount += 1;
    arr.notificationReceived = true;

    return { ...state, notificationsCount: arr.notificationsCount, notificationReceived: true };
};

const getNotificationsSuccessReducer = (state, action) =>
    // if (state.notificationReceived) {
    //     return { notifications: [...state.notifications, ...action.payload], notificationReceived: false };
    // }
    ({
        notifications: action.payload.results,
        notificationReceived: false,
        notificationsCount: 0,
        allNotificationsCount: action.payload.count,
    });

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_RECEIVED:
            return notificationReceivedReducer(state);
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return getNotificationsSuccessReducer(state, action);

        default:
            return state;
    }
};

export default reducer;
