import * as actionTypes from './actionTypes';

const initialState = {
    basketLoading: true,
    basket: [],
    promotionCode: '',
};

// BASKET

const getBasketListStartReducer = (state) => ({
    ...state,
    basketLoading: true,
});

const getBasketListSuccessReducer = (state, action) => {
    const { basket } = action.payload;
    const sortedBasket = basket.sort((a, b) => a.id - b.id);

    return {
        ...state,
        basketLoading: false,
        basket: {
            ...action.payload,
            basket: sortedBasket,
        },
    };
};

const getBasketListFailReducer = (state) => ({
    ...state,
    basketLoading: false,
});

const deleteBasketListStartReducer = (state) => ({
    ...state,
    basketLoading: true,
});

const deleteBasketListSuccessReducer = (state) => ({
    ...state,
    basketLoading: false,
});

const deleteBasketListFailReducer = (state) => ({
    ...state,
    basketLoading: false,
});

const updateBasketListStartReducer = (state) => ({
    ...state,
    // basketLoading: true,
});

const updateBasketListSuccessReducer = (state) => ({
    ...state,
    // basketLoading: false,
    // basket: action.payload,
});

const updateBasketListFailReducer = (state) => ({
    ...state,
    // basketLoading: false,
});

const setGuestBasketReducer = (state, action) => {
    localStorage.setItem('basket', JSON.stringify(action.payload));
    return {
        ...state,
        basket: action.payload,
        basketLoading: false,
    };
};

const getMyPromotionStartedReducer = (state) => ({
    ...state,
});

const getMyPromotionSuccessReducer = (state, action) => ({
    ...state,
    promotionCode: action.payload,
});

const getMyPromotionFailReducer = (state) => ({
    ...state,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BASKET_LIST_STARTED:
            return getBasketListStartReducer(state);
        case actionTypes.GET_BASKET_LIST_SUCCESS:
            return getBasketListSuccessReducer(state, action);
        case actionTypes.GET_BASKET_LIST_FAIL:
            return getBasketListFailReducer(state);

        case actionTypes.DELETE_BASKET_LIST_STARTED:
            return deleteBasketListStartReducer(state);
        case actionTypes.DELETE_BASKET_LIST_SUCCESS:
            return deleteBasketListSuccessReducer(state);
        case actionTypes.DELETE_BASKET_LIST_FAIL:
            return deleteBasketListFailReducer(state);

        case actionTypes.UPDATE_BASKET_LIST_STARTED:
            return updateBasketListStartReducer(state);
        case actionTypes.UPDATE_BASKET_LIST_SUCCESS:
            return updateBasketListSuccessReducer(state);
        case actionTypes.UPDATE_BASKET_LIST_FAIL:
            return updateBasketListFailReducer(state);

        case actionTypes.GET_PROMOTION_LIST_STARTED:
            return getMyPromotionStartedReducer(state);
        case actionTypes.GET_PROMOTION_LIST_SUCCESS:
            return getMyPromotionSuccessReducer(state, action);
        case actionTypes.GET_PROMOTION_LIST_FAIL:
            return getMyPromotionFailReducer(state);

        case actionTypes.SET_GUEST_BASKET:
            return setGuestBasketReducer(state, action);

        default:
            return state;
    }
};

export default reducer;
