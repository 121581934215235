export const GET_SCHEDULE_STARTED = 'GET_SCHEDULE_STARTED';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAIL = 'GET_SCHEDULE_FAIL';

export const GET_CHEF_SCHEDULE_WITH_ID_STARTED = 'GET_CHEF_SCHEDULE_WITH_ID_STARTED';
export const GET_CHEF_SCHEDULE_WITH_ID_SUCCESS = 'GET_CHEF_SCHEDULE_WITH_ID_SUCCESS';
export const GET_CHEF_SCHEDULE_WITH_ID_FAIL = 'GET_CHEF_SCHEDULE_WITH_ID_FAIL';

export const UPDATE_SCHEDULE_STARTED = 'UPDATE_SCHEDULE_STARTED';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAIL = 'UPDATE_SCHEDULE_FAIL';

export const CREATE_SCHEDULE_STARTED = 'CREATE_SCHEDULE_STARTED';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAIL = 'CREATE_SCHEDULE_FAIL';
