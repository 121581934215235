import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Layout from '../presentation/components/Layout';
import LayoutHeader from '../presentation/components/Layout/Header';
import LayoutFooter from '../presentation/components/Layout/Footer';
import LayoutContent from '../presentation/components/Layout/Content';
import LoadingSpinner from '../presentation/components/LoadingSpinner';
import ScrollToTop from '../presentation/components/ScrollToTop';

// Shared
const SharedLandingPage = lazy(() => import('../presentation/pages/Shared/LandingPage'));
const SharedNearPlacesPage = lazy(() => import('../presentation/pages/Shared/NearPlacesPage'));
const SharedMealChoicePage = lazy(() => import('../presentation/pages/Shared/MealChoicePage'));
const SharedBasketPage = lazy(() => import('../presentation/pages/Shared/BasketPage'));
const SharedFoodDetailsPage = lazy(() => import('../presentation/pages/Shared/FoodDetailsPage'));
const SharedChefDetailsPage = lazy(() => import('../presentation/pages/Shared/PreviewUserProfilePage'));
// const AboutVidieatsPage = lazy(() => import('../presentation/pages/Shared/TextPages/AboutVidieatsPage'));
const UserAgreementPage = lazy(() => import('../presentation/pages/Shared/TextPages/ConditionsofUsePage'));
const PrivacyPage = lazy(() => import('../presentation/pages/Shared/TextPages/PrivacyPage'));
const ProtectionOfDataPage = lazy(() => import('../presentation/pages/Shared/TextPages/ProtectionOfDataPage'));
const EmailVerifyPage = lazy(() => import('../presentation/pages/Shared/EmailVerifyPage'));
const PaymentSuccessPage = lazy(() => import('../presentation/pages/Shared/PaymentPage/Success/PaymentSuccess'));
const PaymentFailPage = lazy(() => import('../presentation/pages/Shared/PaymentPage/Fail/PaymentFail'));
const SharedPaymentPage = lazy(() => import('../presentation/pages/Shared/PaymentPage'));
const HelpCenterPage = lazy(() => import('../presentation/pages/Shared/HelpCenterPage'));
const AboutPage = lazy(() => import('../presentation/pages/Shared/TextPages/AboutPage'));
const CourrierForm = lazy(() => import('../presentation/pages/Shared/TextPages/CourrierRegistrationPage'));
const ConditionsOfUse = lazy(() => import('../presentation/pages/Shared/TextPages/ConditionsofUsePage'));
const SalesContractPage = lazy(() => import('../presentation/pages/Shared/TextPages/SalesContractPage'));
const KVKKPage = lazy(() => import('../presentation/pages/Shared/TextPages/KVKKPage'));
const Cancellation = lazy(() => import('../presentation/pages/Shared/TextPages/CancelletionPage'));
const ChefContract = lazy(() => import('../presentation/pages/Shared/TextPages/ChefContractPage'));
const CouirerContract = lazy(() => import('../presentation/pages/Shared/TextPages/CourierContractPage'));
const SharedVidiFoodPage = lazy(() => import('../presentation/pages/Shared/VidiFoodPage'));

// Chef
const ChefOrderPage = lazy(() => import('../presentation/pages/Chefs/OrderPage'));
const ChefNotificationPage = lazy(() => import('../presentation/pages/Chefs/NotificationPage'));
const ChefSignupPage = lazy(() => import('../presentation/pages/Chefs/SignupPage'));
const ChefLoginPage = lazy(() => import('../presentation/pages/Chefs/LoginPage'));
const ForgotPasswordPage = lazy(() => import('../presentation/pages/Chefs/LoginPage/ForgotPasswordPage'));

const ChefCreateMySchedulePage = lazy(() => import('../presentation/pages/Chefs/CreateMySchedulePage'));
const ChefMyMenuPage = lazy(() => import('../presentation/pages/Chefs/OverviewPage/MyMenuPage'));
const ChefMenuSettingsPage = lazy(() => import('../presentation/pages/Chefs/OverviewPage/MyMenuSettingsPage'));
const ChefSettingsPage = lazy(() => import('../presentation/pages/Chefs/SettingsPage'));
const FoodDetailsPage = lazy(() => import('../presentation/pages/Shared/FoodDetailsPage'));
const ChefPaymentsPage = lazy(() => import('../presentation/pages/Chefs/PaymentsPage'));
const ChefFavouritesPage = lazy(() => import('../presentation/pages/Chefs/FavouritesPage'));
const ChefProfilePage = lazy(() => import('../presentation/pages/Chefs/ProfilePage'));
const StorePage = lazy(() => import('../presentation/pages/Chefs/StorePage'));

// Customer
const CustomerLoginPage = lazy(() => import('../presentation/pages/Customer/LoginPage'));
const CustomerSignupPage = lazy(() => import('../presentation/pages/Customer/SignupPage'));
const CustomerNotificationPage = lazy(() => import('../presentation/pages/Customer/NotificationPage'));
const CustomerSettingsPage = lazy(() => import('../presentation/pages/Customer/SettingsPage'));
const CustomerFavouritesPage = lazy(() => import('../presentation/pages/Customer/FavouritesPage'));
const CustomerProfilePage = lazy(() => import('../presentation/pages/Customer/ProfilePage'));
const CustomerOrdersPage = lazy(() => import('../presentation/pages/Customer/OrdersPage'));
const BecomeAChefPage = lazy(() => import('../presentation/pages/Customer/BecomeAChef'));
const BecomeACourrierPage = lazy(() => import('../presentation/pages/Customer/BecomeACourier'));
const CustomerForgotPasswordPage = lazy(() => import('../presentation/pages/Customer/LoginPage/ForgotPasswordPage'));

const Shared = ({ match }) => {
    const userRole = localStorage.getItem('role');
    // const [started, setStarted] = useState(false);
    // if (isLoggedIn && !started) {
    //     WebSocket.connect();
    // }

    return (
        <Layout>
            <ScrollToTop />
            <LayoutHeader userRole={userRole} />
            <Layout>
                <LayoutContent pagetype="shared">
                    <Suspense fallback={<LoadingSpinner fullHeight />}>
                        <Switch>
                            <Route path={`${match.url}login`} exact component={CustomerLoginPage} />
                            <Route path={`${match.url}signup`} exact component={CustomerSignupPage} />
                            <Route path={`${match.url}chef-signup`} exact component={ChefSignupPage} />
                            <Route path={`${match.url}chef-login`} exact component={ChefLoginPage} />
                            <Route path={`${match.url}forgot-password`} exact component={ForgotPasswordPage} />
                            <Route path={`${match.url}customer-forgot-password`} exact component={CustomerForgotPasswordPage} />
                            <Route path={`${match.url}near-places/chefs/:mealId`} exact component={SharedMealChoicePage} />
                            <Route path={`${match.url}basket`} exact component={SharedBasketPage} />
                            <Route path={`${match.url}near-places`} exact component={SharedNearPlacesPage} />
                            <Route path={`${match.url}notification`} component={CustomerNotificationPage} />
                            <Route path={`${match.url}about`} component={AboutPage} />
                            <Route path={`${match.url}user-agreement`} component={UserAgreementPage} />
                            <Route path={`${match.url}privacy`} component={PrivacyPage} />
                            <Route path={`${match.url}protection-of-data`} component={ProtectionOfDataPage} />
                            <Route path={`${match.url}mealdetail/:mealId`} exact component={FoodDetailsPage} />
                            <Route path={match.url} exact component={SharedLandingPage} />
                            <Route path={`${match.url}chef-details/:chefId`} component={SharedChefDetailsPage} exact />
                            <Route path={`${match.url}email-verify/:token`} component={EmailVerifyPage} />
                            <Route path={`${match.url}near-places/:foodName`} exact component={SharedNearPlacesPage} />
                            <Route path={`${match.url}near-places/:cityName`} exact component={SharedNearPlacesPage} />
                            <Route path={`${match.url}near-places/category/:categoryName`} exact component={SharedNearPlacesPage} />
                            <Route path={`${match.url}homefood`} exact component={SharedVidiFoodPage} />

                            <Route path="/payment/:paymentId/:paymentUrl" component={SharedPaymentPage} />

                            <Route path={`${match.url}payment-success`} exact component={PaymentSuccessPage} />
                            <Route path={`${match.url}payment-fail`} exact component={PaymentFailPage} />
                            <Route path={`${match.url}become-a-chef`} exact component={BecomeAChefPage} />
                            <Route path={`${match.url}become-a-courier`} exact component={BecomeACourrierPage} />
                            <Route path={`${match.url}help-center`} exact component={HelpCenterPage} />
                            <Route path={`${match.url}store`} component={StorePage} />
                            <Route path={`${match.url}courier-registration-form`} component={CourrierForm} />
                            <Route path={`${match.url}conditions-of-use`} component={ConditionsOfUse} />
                            <Route path={`${match.url}cancellation`} component={Cancellation} />
                            <Route path={`${match.url}chef-contract`} component={ChefContract} />
                            <Route path={`${match.url}courier-contract`} component={CouirerContract} />
                            <Route path={`${match.url}sales-contract`} component={SalesContractPage} />
                            <Route path={`${match.url}KVKK`} component={KVKKPage} />
                        </Switch>
                    </Suspense>
                </LayoutContent>
            </Layout>
            <LayoutFooter />
        </Layout>
    );
};

const Chefs = ({ match }) => {
    const userRole = localStorage.getItem('role');

    return userRole === 'chef' ? (
        <Layout>
            <ScrollToTop />
            <LayoutHeader userRole={userRole} />
            <Layout>
                <LayoutContent pagetype="chefs">
                    <Suspense fallback={<LoadingSpinner fullHeight />}>
                        <Switch>
                            <Route path={`${match.url}/orders`} component={ChefOrderPage} exact />
                            <Route path={`${match.url}/create-my-schedule`} component={ChefCreateMySchedulePage} exact />
                            <Route path={`${match.url}/overview`} component={ChefMyMenuPage} exact />
                            <Route path={`${match.url}/overview/settings`} component={ChefMenuSettingsPage} exact />
                            <Route path={`${match.url}/edit-meal/:mealId`} component={ChefMenuSettingsPage} exact />
                            <Route path={`${match.url}/settings`} component={ChefSettingsPage} exact />
                            <Route path={`${match.url}/notification`} component={ChefNotificationPage} exact />
                            <Route path={`${match.url}/favourites`} component={ChefFavouritesPage} exact />
                            <Route path={`${match.url}/chef-details/:chefId`} component={SharedChefDetailsPage} exact />
                            <Route path={`${match.url}/profile`} component={ChefProfilePage} exact />
                            <Route path={`${match.url}/settings/payments`} component={ChefPaymentsPage} exact />
                            <Route path={`${match.url}/mealdetail/:mealId`} component={FoodDetailsPage} exact />
                            <Route path="/payment/:paymentId/:paymentUrl" component={SharedPaymentPage} />
                            {/* <Route path="/payment/:paymentUrl" component={SharedPaymentPage} /> */}
                        </Switch>
                    </Suspense>
                </LayoutContent>
            </Layout>
            <LayoutFooter />
        </Layout>
    ) : (
        <Redirect to="/" />
    );
};

const Customer = ({ match }) => {
    const userRole = localStorage.getItem('role');
    return userRole === 'customer' ? (
        <Layout>
            <ScrollToTop />
            <LayoutHeader userRole={userRole} />
            <Layout>
                <LayoutContent pagetype="customer">
                    <Suspense fallback={<LoadingSpinner fullHeight />}>
                        <Switch>
                            <Route path={`${match.url}/profile`} component={CustomerProfilePage} exact />
                            <Route path={`${match.url}/notifications`} component={CustomerNotificationPage} exact />
                            <Route path={`${match.url}/orders`} component={CustomerOrdersPage} exact />
                            <Route path={`${match.url}/favourites`} component={CustomerFavouritesPage} exact />
                            <Route path={`${match.url}/about`} component={AboutPage} />
                            <Route path={`${match.url}/settings`} component={CustomerSettingsPage} exact />
                            <Route path={`${match.url}/help`} component={CustomerSettingsPage} exact />
                            <Route path={`${match.url}/mealdetail/:mealId`} component={SharedFoodDetailsPage} exact />
                            <Route path={`${match.url}/chef-details/:chefId`} component={SharedChefDetailsPage} exact />
                            <Route path="/payment/:paymentId/:paymentUrl" component={SharedPaymentPage} />
                            {/* <Route path="/payment/:paymentUrl" component={SharedPaymentPage} /> */}
                        </Switch>
                    </Suspense>
                </LayoutContent>
            </Layout>
            <LayoutFooter />
        </Layout>
    ) : (
        <Redirect to="/" />
    );
};

const Routes = () => (
    <Router>
        <Layout color="#F5F9FC">
            <Switch>
                <Route path="/chefs" component={Chefs} />
                <Route path="/customer" component={Customer} />
                <Route path="/" component={Shared} />
            </Switch>
        </Layout>
    </Router>
);

export default Routes;
