export const GET_ORDER_STARTED = 'GET_ORDER_STARTED';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const GET_CANCEL_REASONS_STARTED = 'GET_CANCEL_REASONS_STARTED';
export const GET_CANCEL_REASONS_SUCCESS = 'GET_CANCEL_REASONS_SUCCESS';
export const GET_CANCEL_REASONS_FAIL = 'GET_CANCEL_REASONS_FAIL';

export const CANCEL_ORDER_STARTED = 'CANCEL_ORDER_STARTED';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL';

export const CONFIRM_ORDER_STARTED = 'CONFIRM_ORDER_STARTED';
export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_FAIL = 'CONFIRM_ORDER_FAIL';

export const DELETE_ORDER_STARTED = 'DELETE_ORDER_STARTED';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

export const DELETE_BASKET_STARTED = 'DELETE_BASKET_STARTED';
export const DELETE_BASKET_SUCCESS = 'DELETE_BASKET_SUCCESS';
export const DELETE_BASKET_FAIL = 'DELETE_BASKET_FAIL';

export const CREATE_ORDER_STARTED = 'CREATE_ORDER_STARTED';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const ORDER_READY_STARTED = 'ORDER_READY_STARTED';
export const ORDER_READY_SUCCESS = 'ORDER_READY_SUCCESS';
export const ORDER_READY_FAIL = 'ORDER_READY_FAIL';
