/* eslint-disable sonarjs/no-identical-functions */
import swal from 'sweetalert';
import '../../../presentation/components/Swal/styles.css';
import * as actions from './actions';
import { logout } from '../auth/actions';

export const getCustomerSettings = () => (dispatch, getState, service) => {
    const { customerId } = JSON.parse(localStorage.getItem('user'));
    dispatch(actions.getCustomerSettingsStartedAction());
    return service({
        method: 'GET',
        path: `customer/${customerId}/`,
        sendToken: true,
    })
        .then((info) => {
            localStorage.setItem('user', JSON.stringify(info));

            dispatch(actions.getCustomerSettingsSuccessAction(info));
            return info;
        })
        .catch((error) => {
            dispatch(actions.getCustomerSettingsFailAction());
        });
};

export const getChefSettings = () => (dispatch, getState, service) => {
    const chefId = JSON.parse(localStorage.getItem('user'))?.chefId;
    dispatch(actions.getChefSettingsStartedAction());
    return service({
        method: 'GET',
        path: `chef/${chefId}/`,
        sendToken: true,
    })
        .then((info) => {
            dispatch(actions.getChefSettingsSuccessAction(info));
            localStorage.setItem('user', JSON.stringify(info));
            return info;
        })
        .catch((error) => {
            dispatch(actions.getChefSettingsFailAction());
        });
};

export const checkChefSettings = () => (dispatch, getState, service) => {
    const { chefId } = JSON.parse(localStorage.getItem('user'));
    dispatch(actions.checkChefSettingsStartedAction());
    return service({
        method: 'GET',
        path: `chef/listPendings/?chefId=${chefId}`,
        sendToken: true,
    })
        .then((info) => {
            dispatch(actions.checkChefSettingsSuccessAction(info));
        })
        .catch((error) => {
            dispatch(actions.checkChefSettingsFailAction());
        });
};

export const updateCustomerSettings = (formData, successMessage) => (dispatch, getState, service) => {
    dispatch(actions.updateCustomerSettingsStartedAction());
    const { customerId } = JSON.parse(localStorage.getItem('user'));
    return service({
        method: 'PUT',
        path: `customer/${customerId}/settings_update/`,
        sendToken: true,
        body: formData,
    })
        .then(() => {
            dispatch(actions.updateCustomerSettingsSuccessAction());
            swal({ icon: 'success', text: successMessage });
            const goToBasket = localStorage.getItem('goToBasket');
            if (goToBasket) {
                localStorage.removeItem('goToBasket');
                window.location.pathname = '/basket';
            }
        })
        .catch((error) => {
            dispatch(actions.updateCustomerSettingsFailAction());
        });
};

export const updateChefSettings = (formData, successMessage) => (dispatch, getState, service) => {
    dispatch(actions.updateChefSettingsStartedAction());
    const { chefId } = JSON.parse(localStorage.getItem('user'));
    return service({
        method: 'PUT',
        path: `chef/${chefId}/sendApprove/`,
        sendToken: true,
        body: formData,
    })
        .then(() => {
            dispatch(actions.updateChefSettingsSuccessAction());
            swal({ icon: 'success', text: successMessage });
            window.location.reload();
        })
        .catch((error) => {
            dispatch(actions.updateChefSettingsFailAction());
        });
};

export const changeCustomerPassword = (data, successMessage) => (dispatch, getState, service) => {
    dispatch(actions.changeCustomerPasswordStartedAction());
    const { customerId } = JSON.parse(localStorage.getItem('user'));
    return service({
        method: 'PUT',
        path: `customer/${customerId}/change_password/`,
        sendToken: true,
        body: data,
    })
        .then(() => {
            dispatch(actions.changeCustomerPasswordSuccessAction());
            swal({ icon: 'success', text: successMessage });
            // window.location.reload();
        })
        .catch((error) =>
            // dispatch(actions.changeCustomerPasswordFailAction(error));
            Promise.reject(error)
        );
};

export const changeChefPassword = (data, successMessage, errorMessage) => (dispatch, getState, service) => {
    dispatch(actions.changeChefPasswordStartedAction());
    const { chefId } = JSON.parse(localStorage.getItem('user'));
    return service({
        method: 'PUT',
        path: `chef/${chefId}/change_password/`,
        sendToken: true,
        body: data,
    })
        .then(() => {
            dispatch(actions.changeChefPasswordSuccessAction());
            swal({ icon: 'success', text: successMessage });
            //  window.location.reload();
        })
        .catch((error) => {
            //  dispatch(actions.changeChefPasswordFailAction());
            // swal({ icon: 'error', text: errorMessage });
            Promise.reject(error);
        });
};

export const chefForgotPasswordNew = (phoneNumber) => async (dispatch, getState, service) => {
    try {
        dispatch(actions.chefForgotPasswordNewStartedAction());
        const result = await service({
            method: 'POST',
            path: `chef/reset_password_request/`,
            sendToken: false,
            body: { phoneNumber },
        });
        dispatch(actions.chefForgotPasswordNewSuccessAction(result));
    } catch (err) {
        await swal({ icon: 'error', text: err.error });
        window.location.reload();
        dispatch(actions.chefForgotPasswordNewFailAction());
    }
};

export const chefForgotPassword = (pass, tok, numb, history) => (dispatch, getState, service) => {
    const data = {
        password: pass,
        password2: pass,
        code: tok,
        phoneNumber: numb,
    };

    dispatch(actions.chefForgotPasswordStartedAction());
    return service({
        method: 'POST',
        path: `chef/reset_password_with_phone/`,
        sendToken: false,
        body: data,
    })
        .then((res) => {
            dispatch(actions.chefForgotPasswordSuccessAction());
            swal({ icon: 'success', text: 'Şifreniz başarıyla değiştirildi' });
            history.push('/');
        })
        .catch((error) => {
            swal({ icon: 'error', text: { error } });
            dispatch(actions.chefForgotPasswordFailAction());
        });
};

export const customerForgotPasswordNew = (phoneNumber) => async (dispatch, getState, service) => {
    try {
        dispatch(actions.customerForgotPasswordNewStartedAction());
        const result = await service({
            method: 'POST',
            path: `customer/reset_password_request/`,
            sendToken: false,
            body: { phoneNumber },
        });

        dispatch(actions.customerForgotPasswordNewSuccessAction(result));
    } catch (err) {
        swal({ icon: 'error', text: err.error });
        dispatch(actions.customerForgotPasswordNewFailAction());
    }
};

export const customerForgotPassword = (pass, tok, numb, history) => (dispatch, getState, service) => {
    const data = {
        password: pass,
        password2: pass,
        code: tok,
        phoneNumber: numb,
    };

    dispatch(actions.customerForgotPasswordStartedAction());
    return service({
        method: 'POST',
        path: `customer/reset_password_with_phone/`,
        sendToken: false,
        body: data,
    })
        .then((res) => {
            dispatch(actions.customerForgotPasswordSuccessAction());
            swal({ icon: 'success', text: 'Şifreniz başarıyla değiştirildi' });
            history.push('/');
        })
        .catch((error) => {
            swal({ icon: 'error', text: { error } });

            dispatch(actions.customerForgotPasswordFailAction());
        });
};

export const getAddresses = () => async (dispatch, getState, service) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        try {
            dispatch(actions.getAddressesStartedAction());
            const result = await service({
                path: `order/address/`,
                method: 'GET',
                sendToken: true,
            });
            dispatch(actions.getAddressesSuccessAction(result));
        } catch (error) {
            dispatch(actions.getAddressesFailAction());
        }
    }
};

export const selectAddress = (item) => async (dispatch, getState, service) => {
    const id = item;

    try {
        dispatch(actions.selectedAddressStartedAction());
        const result = await service({
            path: `order/address/${id}/choose/`,
            method: 'PUT',
            sendToken: true,
        });
        dispatch(actions.selectedAddressSuccessAction(result));
        swal({ icon: 'success', text: 'Başarılı' });
        // window.location.reload();
    } catch (error) {
        dispatch(actions.selectedAddressFailAction());
    }
};

export const getSelectedAddress = () => async (dispatch, getState, service) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        try {
            dispatch(actions.getSelectedAddressStartedAction());
            const result = await service({
                path: `order/address/current/`,
                method: 'GET',
                sendToken: true,
            });
            dispatch(actions.getSelectedAddressSuccessAction(result));
        } catch (error) {
            dispatch(actions.getSelectedAddressFailAction());
        }
    }
};

export const createAddress = ({ name, address, latitude, description, longitude, region, city }, chefId, successMessage) => (
    dispatch,
    getState,
    service
) => {
    dispatch(actions.createAddressStartedAction());
    return service({
        method: 'POST',
        path: `order/address/?id=${chefId}`,
        sendToken: true,
        body: { name, address, latitude, longitude, description, region, city },
    })
        .then(() => {
            dispatch(actions.createAddressSuccessAction());
            swal({ icon: 'success', text: successMessage });
            dispatch(getAddresses());
        })
        .catch((error) => {
            dispatch(actions.createAddressFailAction());
        });
};

export const deleteAddress = (id) => (dispatch, getState, service) => {
    dispatch(actions.deleteAddressStarted());
    return service({
        method: 'DELETE',
        path: `order/address/${id}/`,
        sendToken: true,
    })
        .then(() => {
            dispatch(actions.deleteAddressSuccess());

            dispatch(getAddresses());
        })
        .catch((error) => {
            dispatch(actions.deleteAddressFail());
        });
};

export const changeAddress = ({ id, name, address, description, latitude, longitude }, chefId, region, city, successMessage) => (
    dispatch,
    getState,
    service
) => {
    dispatch(actions.editAddressStartedAction());
    return service({
        method: 'PUT',
        path: `order/address/${id}/`,
        sendToken: true,
        body: { name, address, latitude, longitude, description, region, city },
    })
        .then(() => {
            dispatch(actions.editAddressSuccessAction());
            swal({ icon: 'success', text: successMessage });
            dispatch(getAddresses());
        })
        .catch((error) => {
            dispatch(actions.editAddressFailAction());
        });
};

export const getMyPreferences = () => (dispatch, getState, service) => {
    dispatch(actions.getPreferencesStartedAction());
    return service({
        method: 'GET',
        path: `notification/notification/preferences_get/`,
        sendToken: true,
    })
        .then((info) => {
            dispatch(actions.getPreferencesSuccessAction(info));
            // swal({ icon: 'success', text: 'Başarılı' });
            // window.location.reload();
        })
        .catch((error) => {
            dispatch(actions.getPreferencesFailAction());
        });
};

export const updateMyPrefences = ({ order, reminder }) => (dispatch, getState, service) => {
    dispatch(actions.updatePreferencesStartedAction());
    dispatch(actions.updatePreferencesSuccessAction({ email_for_order: order, email_for_reminder: reminder }));

    return service({
        method: 'PUT',
        path: `notification/notification/preferences_set/`,
        sendToken: true,
        body: {
            email_for_order: order,
            email_for_reminder: reminder,
        },
    })
        .then(() => {
            // dispatch(getMyPreferences());
            // window.location.reload();
        })
        .catch((error) => {
            dispatch(actions.updatePreferencesFailAction());
        });
};

export const deleteUser = (chefId, successMessage) => (dispatch, getState, service) => {
    dispatch(actions.deleteUserStarted());
    return service({
        method: 'DELETE',
        path: `chef/${chefId}/`,
        sendToken: true,
    })
        .then(() => {
            dispatch(actions.deleteUserSuccess());
            swal({ icon: 'success', text: successMessage });
            logout();
            window.location.reload();
        })
        .catch((error) => {
            dispatch(actions.deleteUserFail());
        });
};

export const deleteCustomer = (chefId) => (dispatch, getState, service) => {
    dispatch(actions.deleteUserStarted());
    return service({
        method: 'DELETE',
        path: `customer/${chefId}/`,
        sendToken: true,
    })
        .then(() => {
            dispatch(actions.deleteUserSuccess());
            swal({ icon: 'success', text: 'Kullanıcı başarıyla silindi' });
            logout();
            window.location.reload();
        })
        .catch((error) => {
            dispatch(actions.deleteUserFail());
        });
};
