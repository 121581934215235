/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import { Col, Row, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
// import { YoutubeFilled, InstagramOutlined, TwitterOutlined, FacebookOutlined, YoutubeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import StyledFooter, {
    FooterBottomWrapper,
    FooterTopWrapper,
    FooterBadgeWrapper,
    FooterNavItemStyles,
    IconWrapperCircle,
    FooterCollapse,
    FooterCollapseWrapper,
} from './styles';
import googleplayIcon from '../../../../assets/shared/layout/googleplay.svg';
import appstoreIcon from '../../../../assets/shared/layout/appstore.svg';
import yemegimcoLogoWhite from '../../../../assets/yemegimCo_logo_white.png';
import { Text } from '../../Typography/styles';
import { getFooterCategories, getSocialMedias } from '../../../../services/Redux/layout/footer/api';
// import localizationIconEN from '../../../../assets/shared/common/localization/EN_white.svg';
// import Try from '../../../../assets/shared/layout/tr-icon.svg';
// import EUR from '../../../../assets/shared/layout/euro.svg';
// import USD from '../../../../assets/shared/layout/dollar.svg';

import facebook from '../../../../assets/shared/layout/facebook.svg';
import instagram from '../../../../assets/shared/layout/instagram.svg';
import twitter from '../../../../assets/shared/layout/twitter.svg';
import youtube from '../../../../assets/shared/layout/youtube.svg';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
// import CurrencyMenu from './CurrencyMenu';

const icons = {
    Instagram: instagram,
    Youtube: youtube,
    Twitter: twitter,
    Facebook: facebook,
};

// const CurrencyIcon = () => {
//     const currency = localStorage.getItem('currency');
//     if (!currency) {
//         localStorage.setItem('currency', 'TL');
//     }
//     const [currentCurrency, setCurrentCurrency] = useState(currency);

//     const currencies = {
//         TL: Try,
//         USD,
//         EUR,
//     };

//     return (
//         <Tooltip placement="top" title={<CurrencyMenu setCurrency={setCurrentCurrency} />} trigger="hover">
//             <img
//                 src={currencies[currentCurrency]}
//                 style={{
//                     width: '28px',
//                     height: '28px',
//                     border: '1px solid white',
//                     padding: '6px',
//                     borderRadius: '3px',
//                     cursor: 'pointer',
//                 }}
//                 alt="currency"
//             />
//         </Tooltip>
//     );
// };

const { Panel } = Collapse;

const Footer = () => {
    const { xs, sm, md, lg } = useResponsive();
    const { t, i18n } = useTranslation('shared');
    const dispatch = useDispatch();
    const { footerCategories, socialMedias } = useSelector((state) => state.footerStore);

    const [language, setLanguage] = useState();
    const history = useHistory();
    useEffect(() => {
        dispatch(getFooterCategories());
        dispatch(getSocialMedias());
        setLanguage(i18n.language);
    }, [dispatch, i18n.language]);

    // let fromFooter = true;
    // const setFromFooter = () => {
    //     fromFooter = false;
    // };
    const onClick = () => {
        history.push(`help-center`, {
            fromFooter: true,
        });
    };

    const footerBottom = t('layout.footer.footerBottom', { returnObjects: true });

    const FooterNavItem = ({ title, datas }) => (
        <FooterNavItemStyles span={8}>
            <Row>
                <Text color="white" weight="bold">
                    {title}
                </Text>
            </Row>
            {datas?.map((subcategory) => (
                <Row key={`subcategory-item-${subcategory.id}`}>
                    <Text
                        color="white"
                        onClick={
                            subcategory?.id === 21
                                ? () => {
                                      history.push(`/${subcategory.link}`, {
                                          fromFooter: true,
                                      });
                                  }
                                : () => {
                                      history.push(`/${subcategory.link}`);
                                  }
                        }
                        style={{ cursor: 'pointer' }}>
                        {subcategory[`name_${language}`]}
                    </Text>
                </Row>
            ))}
        </FooterNavItemStyles>
    );

    const FooterCollapseItem = ({ itemId, title, datas }) => (
        <FooterCollapse
            expandIcon={({ isActive }) => <RightOutlined style={{ color: '#FFFFFF' }} rotate={isActive ? 90 : 0} />}
            expandIconPosition="right"
            bordered={false}>
            <Panel
                header={
                    <Text weight="bold" color="white">
                        {title}
                    </Text>
                }
                key={itemId}>
                {datas?.map((subcategory) => (
                    <Row key={`subcategory-item-${subcategory.id}`}>
                        <Text
                            color="white"
                            onClick={
                                subcategory?.id === 21
                                    ? () => onClick()
                                    : () => {
                                          history.push(`/${subcategory.link}`);
                                      }
                            }
                            style={{ cursor: 'pointer' }}>
                            {subcategory[`name_${language}`]}
                        </Text>
                    </Row>
                ))}
            </Panel>
        </FooterCollapse>
    );

    return (
        <StyledFooter>
            <Col className="height-full">
                <FooterTopWrapper gutter={[0, 30]}>
                    <Col xs={14} sm={10} md={8} lg={8} xl={8} xxl={8}>
                        <FooterBadgeWrapper>
                            <Col span={24} className="spacebetween">
                                <Row>
                                    <img src={yemegimcoLogoWhite} alt="vidieats-logo" className="footer-logo" />
                                </Row>
                                <Row style={{ marginTop: 30, marginBottom: 30 }}>
                                    <Col span={12}>
                                        <a href="https://apps.apple.com/us/app/yemegim-co/id6475003527">
                                            <img src={appstoreIcon} alt="appstore-icon" className="appstore-logo" />
                                        </a>
                                    </Col>
                                    <Col span={12}>
                                        <a href="https://play.google.com/store/apps/details?id=com.yemegimco">
                                            <img src={googleplayIcon} alt="googleplay-icon" className="googleplay-logo" />
                                        </a>
                                    </Col>
                                </Row>
                                <Row align="middle" justify="space-around">
                                    {socialMedias?.map(({ id, name, link }) => (
                                        <IconWrapperCircle
                                            key={`social-media-${id}`}
                                            style={{ cursor: 'pointer', display: id === 2 ? 'none' : 'flex' }}
                                            onClick={() => {
                                                window.open(`https://${link}`, '_blank');
                                            }}>
                                            <img src={icons[name]} alt="social-media-logo" />
                                        </IconWrapperCircle>
                                    ))}
                                </Row>
                            </Col>
                        </FooterBadgeWrapper>
                    </Col>

                    {xs || sm || md || lg ? (
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                            <FooterCollapseWrapper>
                                {footerCategories?.map((category) => (
                                    <FooterCollapseItem
                                        itemId={category.id}
                                        title={category[`name_${language}`]}
                                        datas={category.subcategories}
                                        key={`category-${category.id}`}
                                        onClick={onClick}
                                    />
                                ))}
                            </FooterCollapseWrapper>
                        </Col>
                    ) : (
                        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                            <Row gutter={[{ xs: 44, sm: 44, md: 44, lg: 44, xl: 44 }, 0]}>
                                {footerCategories?.map((category) => (
                                    <FooterNavItem
                                        itemId={category.id}
                                        title={category[`name_${language}`]}
                                        datas={category.subcategories}
                                        key={category.id}
                                        onClick={onClick}
                                    />
                                ))}
                            </Row>
                        </Col>
                    )}
                </FooterTopWrapper>
                <FooterBottomWrapper align="middle" justify="space-between">
                    <Col span={24} className={(xs || sm || md || lg) && 'margin-top'}>
                        <Row align="middle" justify="space-between">
                            {/* <Text color="white" weight="medium">
                                <Icon src={localizationIconEN} alt="" />
                                {footerBottom.language}
                            </Text> */}
                            <Text color="white" weight="medium">
                                {footerBottom.title}
                            </Text>

                            {/* <Text color="white" weight="medium">
                                <Icon src={Try} alt="" />
                                TRY
                            </Text> */}
                            {/* <CurrencyIcon /> */}
                        </Row>
                    </Col>
                </FooterBottomWrapper>
            </Col>
        </StyledFooter>
    );
};

export default Footer;
