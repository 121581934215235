export const GET_CHEFS_MEALS = 'GET_CHEFS_MEALS';
export const GET_CHEFS_MEALS_SUCCESS = 'GET_CHEFS_MEALS_SUCCESS';
export const GET_CHEFS_MEALS_FAIL = 'GET_CHEFS_MEALS_FAIL';

export const GET_CHEFS_ALL_MEALS = 'GET_CHEFS_ALL_MEALS';
export const GET_CHEFS_MEALS_ALL_SUCCESS = 'GET_CHEFS_MEALS_ALL_SUCCESS';
export const GET_CHEFS_MEALS_ALL_FAIL = 'GET_CHEFS_MEALS_ALL_FAIL';

export const UPDATE_CHEFS_MEAL_STATUS = 'UPDATE_CHEFS_MEAL_STATUS';
export const UPDATE_CHEFS_MEAL_STATUS_SUCCESS = 'UPDATE_CHEFS_MEAL_STATUS_SUCCESS';
export const UPDATE_CHEFS_MEAL_STATUS_FAIL = 'UPDATE_CHEFS_MEAL_STATUS_FAIL';

export const CREATE_MEAL_STARTED = 'CREATE_MEAL_STARTED';
export const CREATE_MEAL_SUCCESS = 'CREATE_MEAL_SUCCESS';
export const CREATE_MEAL_FAIL = 'CREATE_MEAL_FAIL';

export const UPDATE_MEAL_STARTED = 'UPDATE_MEAL_STARTED';
export const UPDATE_MEAL_SUCCESS = 'UPDATE_MEAL_SUCCESS';
export const UPDATE_MEAL_FAIL = 'UPDATE_MEAL_FAIL';
