/* eslint-disable sonarjs/no-identical-functions */
import * as actionTypes from './actionTypes';

const initialState = {
    mealId: -1,
    loading: false,
    chefsMeals: null,
    chefsMealsError: null,
    chefMealUpdateStatusError: null,
};

const getChefsMealsReducer = (state) => ({
    ...state,
    loading: true,
});

const getChefsMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    chefsMeals: action.payload,
});

const getChefsMealsFailReducer = (state, action) => ({
    ...state,
    loading: false,
    chefsMealsError: action.payload,
});

const getChefAllMealsReducer = (state) => ({
    ...state,
    loading: true,
});

const getChefAllMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    chefsMeals: action.payload,
});

const getChefAllMealsFailReducer = (state, action) => ({
    ...state,
    loading: false,
    chefsMealsError: action.payload,
});

const updateMealStatusReducer = (state, action) => ({
    ...state,
    loading: true,
    mealId: action.payload,
});

const updateMealStatusSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const updateMealStatusFailReducer = (state, action) => ({
    ...state,
    loading: false,
    chefMealUpdateStatusError: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CHEFS_MEALS:
            return getChefsMealsReducer(state);
        case actionTypes.GET_CHEFS_MEALS_SUCCESS:
            return getChefsMealsSuccessReducer(state, action);
        case actionTypes.GET_CHEFS_MEALS_FAIL:
            return getChefsMealsFailReducer(state, action);

        case actionTypes.GET_CHEFS_ALL_MEALS:
            return getChefAllMealsReducer(state);
        case actionTypes.GET_CHEFS_MEALS_ALL_SUCCESS:
            return getChefAllMealsSuccessReducer(state, action);
        case actionTypes.GET_CHEFS_MEALS_ALL_FAIL:
            return getChefAllMealsFailReducer(state, action);

        case actionTypes.UPDATE_CHEFS_MEAL_STATUS:
            return updateMealStatusReducer(state, action);
        case actionTypes.UPDATE_CHEFS_MEAL_STATUS_SUCCESS:
            return updateMealStatusSuccessReducer(state);
        case actionTypes.UPDATE_CHEFS_MEAL_STATUS_FAIL:
            return updateMealStatusFailReducer(state, action);
        case actionTypes.CREATE_MEAL_STARTED:
            return { ...state, loading: true };
        case actionTypes.CREATE_MEAL_SUCCESS:
            return { ...state, loading: false };
        case actionTypes.CREATE_MEAL_FAIL:
            return { ...state, loading: false };

        default:
            return state;
    }
};

export default reducer;
