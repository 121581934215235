export const FontTheme = {
    Family: {
        primary: 'Poppins',
        secondary: 'Roboto',
    },
    Color: {
        black: '#5C5C5C',
        lightBlack: '#535353',
        darkBlack: '#000000',
        smoke: '#837F7F',
        grey: '#DDDDDD',
        green: '#39996D',
        lightGreen: '#3E986E',
        yellow: '#ffbc41',
        lightYellow: '#E5B02C',
        white: '#ffffff',
        red: '#F69F8E',
        brown: '#B56823',
        error: '#E67847',
        noInfo: '#f80510',
    },
    Weight: {
        light: '300',
        regular: '400',
        semiBold: '500',
        medium: '600',
        bold: '700',
        extraBold: '800',
    },
    Size: {
        tiny: '12px',
        regular: '16px',
        nobleRegular: '18px',
        description: '14px',
        title: '21px',
        bigTitle: '30px',
        motto: '72px',
        small: '13px',
    },

    Style: {
        normal: 'normal',
        italic: 'italic',
        oblique: 'oblique',
    },
};

// TODO RENKLERİ THEMA'DAN ÇEK
