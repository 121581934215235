// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import chefsEN from './namespaces/chefs/en';
import chefsTR from './namespaces/chefs/tr';
import sharedEN from './namespaces/shared/en';
import sharedTR from './namespaces/shared/tr';
import customerEN from './namespaces/customer/en';
import customerTR from './namespaces/customer/tr';

const resources = {
    en: {
        shared: sharedEN,
        chefs: chefsEN,
        customer: customerEN,
    },
    tr: {
        shared: sharedTR,
        chefs: chefsTR,
        customer: customerTR,
    },
};

// Old dual language support
// i18n.use(initReactI18next)
//     .use(LanguageDetector)
//     .init({
//         resources,
//         ns: ['shared', 'chefs', 'customer'],
//         // defaultNS: 'customer',
//         fallbackLng: 'en',
//         supportedLngs: ['en', 'tr'],
//         interpolation: {
//             escapeValue: false, // react already safes from xss
//         },
//     });

// New updated only turkish language support
i18n.use(initReactI18next).init({
    resources,
    ns: ['shared', 'chefs', 'customer'],
    fallbackLng: 'tr',
    lng: 'tr', // default language
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
