import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    chefInfo: null,
    customerInfo: null,
};

const sortJSON = (a, b) => {
    if (a.first_name === b.first_name) {
        // Price is only important when cities are the same
        return a.last_name.localeCompare(b.last_name);
    }
    return a.first_name.localeCompare(b.first_name);
};

// CHEFS
const getChefInfoStartedReducer = (state) => ({
    ...state,
    loading: true,
});
const getChefInfoSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    chefInfo: action.payload.results.sort((a, b) => sortJSON(a, b)),
});
const getChefInfoFailReducer = (state) => ({
    ...state,
    loading: false,
});

// CUSTOMERS
const getCustomerInfoStartedReducer = (state) => ({
    ...state,
    loading: true,
});
const getCustomerInfoSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    customerInfo: action.payload.results.sort((a, b) => sortJSON(a, b)),
});
const getCustomerInfoFailReducer = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CHEF_INFO_STARTED:
            return getChefInfoStartedReducer(action);
        case actionTypes.GET_CHEF_INFO_SUCCESS:
            return getChefInfoSuccessReducer(state, action);
        case actionTypes.GET_CHEF_INFO_FAIL:
            return getChefInfoFailReducer(state);
        case actionTypes.GET_CUSTOMER_INFO_STARTED:
            return getCustomerInfoStartedReducer(action);
        case actionTypes.GET_CUSTOMER_INFO_SUCCESS:
            return getCustomerInfoSuccessReducer(state, action);
        case actionTypes.GET_CUSTOMER_INFO_FAIL:
            return getCustomerInfoFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
