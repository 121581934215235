/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-prototype-builtins */
import swal from 'sweetalert';
import * as userActionTypes from './actionTypes';

export const autoLogin = () => ({ type: userActionTypes.AUTO_LOGIN });

export const loginStarted = (userCredentials) => ({ type: userActionTypes.LOGIN, payload: userCredentials });

export const loginSuccess = (userData) => {
    if (userData.user) {
        localStorage.setItem('id', userData.user.id);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData.user));
        localStorage.setItem('role', userData.user.hasOwnProperty('chefId') ? 'chef' : 'customer');
    } else {
        localStorage.setItem('id', userData.id);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('role', userData.hasOwnProperty('chefId') ? 'chef' : 'customer');
    }

    return { type: userActionTypes.LOGIN_SUCCESS, payload: userData };
};

export const loginFailure = () => ({ type: userActionTypes.LOGIN_FAILURE });
export const signupStarted = () => ({ type: userActionTypes.SIGNUP });
export const signupSuccess = () => ({ type: userActionTypes.SIGNUP_SUCCESS });
export const signupFailure = () => ({ type: userActionTypes.SIGNUP_FAILURE });

export const logout = () => {
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('basket');

    return { type: userActionTypes.LOGOUT };
};

export const signUpWithProviderSuccess = (userData) => {
    if (userData.user) {
        localStorage.setItem('id', userData.user.id);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData.user));
        localStorage.setItem('role', userData.user.hasOwnProperty('chefId') ? 'chef' : 'customer');
    } else {
        localStorage.setItem('id', userData.id);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('role', userData.hasOwnProperty('chefId') ? 'chef' : 'customer');
    }
    return {
        type: userActionTypes.LOGIN_SUCCESS,
        payload: userData,
    };
};

// eslint-disable-next-line
export const loginWithProviderSuccess = (userData) => {
    if (userData.user) {
        localStorage.setItem('id', userData.user.id);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData.user));
        localStorage.setItem('role', userData.user.hasOwnProperty('chefId') ? 'chef' : 'customer');
    } else {
        localStorage.setItem('id', userData.id);
        localStorage.setItem('token', userData.token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('role', userData.hasOwnProperty('chefId') ? 'chef' : 'customer');
    }
    return {
        type: userActionTypes.LOGIN_SUCCESS,
        payload: userData,
    };
};

// eslint-disable-next-line
export const signUpWithPhoneSuccess = (userData, token, role) => {
    localStorage.setItem('id', userData.id);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('role', role);

    return {
        type: userActionTypes.LOGIN_SUCCESS,
        payload: userData,
    };
};

// eslint-disable-next-line
export const loginWithPhoneSuccess = (userData, token, role) => {
    localStorage.setItem('id', userData.id);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('role', role);
    return {
        type: userActionTypes.LOGIN_SUCCESS,
        payload: userData,
    };
};

export const sendEmailVerificationStarted = () => ({
    type: userActionTypes.SEND_EMAIL_VERIFICATION_STARTED,
});

export const sendEmailVerificationSuccess = (successMessage) => {
    swal({ text: successMessage, icon: 'success' });

    return {
        type: userActionTypes.SEND_EMAIL_VERIFICATION_SUCCESS,
    };
};

export const sendEmailVerificationFail = (errorMessage) => {
    swal({ text: errorMessage, icon: 'error' });

    return {
        type: userActionTypes.SEND_EMAIL_VERIFICATION_FAIL,
    };
};

export const verifyPhoneNumberStarted = () => ({
    type: userActionTypes.SEND_PHONE_NUMBER_STARTED,
});

export const verifyPhoneNumberSuccess = (successMessage) => {
    swal({ text: successMessage, icon: 'success' });

    const user = JSON.parse(localStorage.getItem('user'));
    user.isPhoneVerified = true;
    localStorage.setItem('user', JSON.stringify(user));
    return {
        type: userActionTypes.SEND_PHONE_NUMBER_SUCCESS,
    };
};

export const verifyPhoneNumberFail = (errorMessage) => {
    swal({ text: errorMessage, icon: 'error' });

    return {
        type: userActionTypes.SEND_PHONE_NUMBER_FAIL,
    };
};

export const chefRegisterStarted = () => ({
    type: userActionTypes.CHEF_SIGNUP_STARTED,
});

export const chefRegisterSuccess = (userData) => ({
    type: userActionTypes.CHEF_SIGNUP_SUCCESS,
    payload: userData,
});

export const chefRegisterFail = () => ({
    type: userActionTypes.CHEF_SIGNUP_FAILURE,
});

export const chefLoginStarted = () => ({
    type: userActionTypes.CHEF_LOGIN_STARTED,
});

export const chefLoginSuccess = (userData) => ({
    type: userActionTypes.CHEF_LOGIN_SUCCESS,
    payload: userData,
});

export const chefLoginFail = () => ({
    type: userActionTypes.CHEF_LOGIN_FAILURE,
});

export const chefLoginFirstStarted = () => ({
    type: userActionTypes.CHEF_LOGIN_FIRST_STARTED,
});

export const chefLoginFirstSuccess = (userData) => ({
    type: userActionTypes.CHEF_LOGIN_FIRST_SUCCESS,
    payload: userData,
});

export const chefLoginFirstFail = () => ({
    type: userActionTypes.CHEF_LOGIN_FIRST_FAILURE,
});

export const customerRegisterStarted = () => ({
    type: userActionTypes.CUSTOMER_SIGNUP_STARTED,
});

export const customerRegisterSuccess = (userData) => ({
    type: userActionTypes.CUSTOMER_SIGNUP_SUCCESS,
    payload: userData,
});

export const customerRegisterFail = () => ({
    type: userActionTypes.CUSTOMER_SIGNUP_FAILURE,
});

export const customerLoginStarted = () => ({
    type: userActionTypes.CUSTOMER_LOGIN_STARTED,
});

export const customerLoginSuccess = (userData) => ({
    type: userActionTypes.CUSTOMER_LOGIN_SUCCESS,
    payload: userData,
});

export const customerLoginFail = () => ({
    type: userActionTypes.CUSTOMER_LOGIN_FAILURE,
});

export const customerLoginFirstStarted = () => ({
    type: userActionTypes.CUSTOMER_LOGIN_FIRST_STARTED,
});

export const customerLoginFirstSuccess = (userData) => ({
    type: userActionTypes.CUSTOMER_LOGIN_FIRST_SUCCESS,
    payload: userData,
});

export const customerLoginFirstFail = () => ({
    type: userActionTypes.CUSTOMER_LOGIN_FIRST_FAILURE,
});

export const userCreateDeviceStarted = () => ({
    type: userActionTypes.USER_LOGIN_CREATE_DEVİCE_STARTED,
});

export const userCreateDeviceSuccess = (data) => ({
    type: userActionTypes.USER_LOGIN_CREATE_DEVİCE_SUCCESS,
    payload: data,
});

export const userCreateDeviceFail = () => ({
    type: userActionTypes.USER_LOGIN_CREATE_DEVİCE_FAILURE,
});
