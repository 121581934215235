/* eslint-disable sonarjs/cognitive-complexity */
import swal from 'sweetalert';
import { logout } from '../Redux/auth/actions';

import './styles.css';
import 'dotenv/config';

export const CONFIGURATION = {
    // API_URL: process?.env?.NODE_ENV === 'production' ? 'https://backend.vidieats.com/' : 'https://backendprep.vidieats.com/',
    API_URL: 'https://backend.yemegim.co/',
    TIMEOUT: 35000,
    TOKEN_TYPE: 'JWT',
    WITH_CREDENTIALS: false,
    WS_URL: 'wss://backend.yemegim.co/websocket',
};

function xmlFetch(config) {
    const language = localStorage.getItem('i18nextLng');
    // const currency = localStorage.getItem('currency').toLowerCase();

    // const URL = `${CONFIGURATION.API_URL + language}/${currency}/ ${config.path}`;
    // const URL = config.URL ? config.URL : `${CONFIGURATION.API_URL + language}/${currency}/${config.path}`;
    const URL = config.URL ? config.URL : `${CONFIGURATION.API_URL + language}/tl/${config.path}`;
    return new Promise((resolve, reject) => {
        const xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.timeout = CONFIGURATION.TIMEOUT;

        xmlHttpRequest.ontimeout = () => reject(new Error('Zaman aşımı!'));
        xmlHttpRequest.onload = () => {
            const { status, response } = xmlHttpRequest;

            if (status === 401) {
                const role = localStorage.getItem('role');
                if (role === 'chef') {
                    logout();
                    window.location.href = '/chef-login';
                } else if (role === 'customer') {
                    logout();
                    window.location.href = '/login';
                }
                // if (window.location.pathname !== '/') {
                //     window.location.href = '/';
                // }
            }

            let parsedResponse;
            try {
                parsedResponse = JSON.parse(response);
            } catch (error) {
                parsedResponse = response;
            }
            // errors

            if (parsedResponse.error && parsedResponse.error.length === 1) {
                swal({ text: parsedResponse.error[0], icon: 'error' });
            } else if (parsedResponse.error) {
                swal({ text: parsedResponse.error, icon: 'error' });
            } else if (parsedResponse.non_field_errors) {
                swal({ text: parsedResponse.non_field_errors[0], icon: 'error' });
            } else if (parsedResponse.phoneNumber && parsedResponse.phoneNumber.length === 1) {
                swal({ text: parsedResponse.phoneNumber[0], icon: 'error' });
            } else if (parsedResponse.email && parsedResponse.email.length === 1) {
                swal({ text: parsedResponse.email[0], icon: 'error' });
            }
            // success
            // else if (parsedResponse.detail) {
            //     message.success(parsedResponse.detail);
            // }

            switch (parseInt(status / 100, 10)) {
                case 2: // Success
                    resolve(parsedResponse);
                    break;
                case 4: // Client Error
                    reject(parsedResponse);
                    break;
                case 5: // Server Error
                    reject(new Error(`Durum: ${status}, Sistemde bir hata oluştu`));
                    break;
                default:
                    reject(new Error(`Bilinmeyen hata! Durum:${status}`));
            }
        };
        xmlHttpRequest.open(config.method, URL);

        xmlHttpRequest.withCredentials = CONFIGURATION.WITH_CREDENTIALS;

        if (config.sendToken) {
            const token = window.localStorage.getItem('token');
            if (token) {
                xmlHttpRequest.setRequestHeader('Authorization', `${CONFIGURATION.TOKEN_TYPE} ${token} `);
            }
        }

        if (config.body) {
            const { body } = config;
            if (body instanceof FormData) {
                xmlHttpRequest.send(body);
            } else if (body instanceof Object) {
                xmlHttpRequest.setRequestHeader('Content-type', 'application/json');
                xmlHttpRequest.send(JSON.stringify(body));
            }
        } else xmlHttpRequest.send();
    });
}

export default xmlFetch;
