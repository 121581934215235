import { getYear } from 'date-fns';

/* eslint-disable sonarjs/no-duplicate-string */
export default {
    searchbar: {
        mobileWarning: 'Daha iyi bir deneyim için mobil uygulamamızı indirin',
        placeHolder: 'Ne yemek istersiniz?',
        location: 'Şehir Girin',
        headerPlaceholder: 'Yemek Adı Ara',
        addAddress: 'Adres Ekle',
        nextButtonText: 'İleri',
        backButtonText: 'Geri',
        lastButtonText: 'Haydi Başlayalım',
        step1Text: ` Yemegim.co 'ya hoş geldiniz!`,
        step2Text: ` Önce konumunuzu girin, dilerseniz sipariş verebileceğiniz şehire göre ve ya yemek ismi ile arama yapabilirsiniz.`,
        step3Text: ` Yemeklerin keşfini buradan gerçekleştirebilirsiniz.`,
        step4Text: ` Bölgenizdeki en popüler yemekleri buradan görebilirsiniz. Sizin yemekleriniz de olsun ister misiniz?`,
        step5Text: ` Kazanmak için önce 'Ayarlar' seçeneğinden bilgilerinizi doldurup, sonra 'Benim Menüm' seçeneğinden menünüzü oluşturabilirsiniz.`,
    },
    general: {
        currentAddres: 'Geçerli Adresim Yap',
        addAddress: 'Adres Ekle',
        shortName: 'Kısa İsim',
        giveAName: 'Adrese İsim Ver',
        region: 'İlçe',
        description: 'Detay',
        success: 'Başarılı',
        error: 'Hata',
        areYouSure: 'Kayıtlı adresi silmek istediğinize emin misiniz ?',
        approve: 'Onayla',
        tooltip: 'Yemegim.co siparişlerinizi sizlere ulaştırmak için adresinizi giriniz',
        shortDesc: 'Kapı ve bina no, adres tarifi',
        delete: 'Sil',
        title: 'Düzenle',
        yes: 'Evet',
        no: 'Hayır',
        open: 'Açık',
        closed: 'Kapalı',
        save: 'Kaydet',
        cancel: 'İptal Et',
        areYouSureText: 'Bu konumu kullanmak istediğinize emin misiniz?',
        required: 'Bu bölge zorunludur!',
        commentButton: 'Yorum yapmak ister misiniz?',
        createMenu: 'Menümü Oluştur',
        createSchedule: 'Programımı Oluştur',
        evaluation: 'Değerlendirmelerim',
        minute: 'Dk',
        viewProfile: 'Profili Görüntüle',
        portion: 'Porsiyon',
        addtoBasket: 'Sepete Ekle',
        moreDetails: 'Daha fazla detay',
        detail: 'Detay',
        sign: 'Giriş Yap',
        editDescription: 'Kendin hakkında birşeyler yaz',
        href: 'açıklama ',
        afterHref: 'ekleyebilirsin.',
        eMail: 'E-posta',
        verifyEmail: 'E-postanı onayla',
        add: 'Ekle',
        edit: 'Düzenle',
        sendForApproval: 'Onaya Gönder',
        confirmedBy: 'Onaylayan ',
        waitForApproval: 'Onay Bekleniyor',
        phoneNumber: 'Telefon Numarası',
        verifyPhoneNumber: 'Telefon numaranı onayla',
        seeProfile: 'Profili Gör',
        firstName: 'Ad',
        lastName: 'Soyad',
        fullName: 'Ad Soyad',
        birthday: 'Doğum günü',
        address: 'Adres',
        city: 'Şehir',
        district: 'İlçe',
        postCode: 'Posta Kodu',
        emergencyContact: 'Acil İletişim',
        emergencyContactName: 'Acil İletişim Adı',
        country: 'Ülke',
        male: 'Erkek',
        female: 'Kadın',
        profilePicture: 'Profil Resmi',
        upload: 'Yükleme',
        gender: 'Cinsiyet',
        aboutMe: 'Hakkımda',
        descriptionWarn: 'Bu alanı doldurun',
        id: 'Kimlik Numarası ve Dökümanı',
        addFile: 'Dosya Ekle',
        noFileWarning: 'Menünü oluşturmak için kullanıcı ayarlarından bilgilerini tamamlamalısın.',
        noFileWarning2: 'Programını oluşturmak için kullanıcı ayarlarından bilgilerini tamamlamalısın.',
        ibanNo: 'Iban Numarası',
        ageError: 'Şef olabilmek için 18 yaşını doldurmanız gerekmektedir',
        idError: 'Kimlik numarası zorunludur',
        idNumber: 'Kimlik numaranız',
        infoMessage: 'Bilgileriniz en son güncellendiği tarih',

        maps: {
            mapButton: 'Haritayı Aç',
            okText: 'Onayla',
            cancelText: 'İptal',
            chooseAddress: 'Adresinizi Seçiniz',
            loadingText: 'Adres Yükleniyor',
            mapInfo: 'Mutfağınızın adresini giriniz',
        },
        culinaryCertificate: 'Mutfak Sertifikası',
        tradesmanExemptionCertificate: 'Esnaf Muafiyet Belgesi veya Ticari Sicil Belgesi',
        messages: {
            eMail: ['E-posta adresiniz geçerli değil!', 'Lütfen e-posta adresinizi giriniz!'],
            phoneNumber: 'Lütfen telefon numaranızı giriniz!',
            firstName: 'Lütfen adınızı giriniz!',
            fullName: 'Lütfen tam adınızı giriniz!',
            lastName: 'Lütfen soyadınızı giriniz!',
            birthday: 'Lütfen doğum gününüzü giriniz!',
            address: 'Lütfen adresinizi giriniz!',
            city: 'Lütfen şehrinizi giriniz!',
            district: 'Lütfen ilçeyi giriniz!',
            shortDesc: 'Lütfen adres tarifi giriniz!',
            postCode: 'Lütfen posta kodunuzu giriniz!',
            emergencyContact: 'Lütfen acil iletişim numarası giriniz!',
            emergencyContactName: 'Lütfen acil iletişim adı giriniz!',
            country: 'Lütfen ülkenizi giriniz!',
            profilePicture: 'Lütfen resim yükleyin!',
            gender: 'Lütfen cinsiyet seçin!',
            aboutMe: 'Kendinizi tanıtın',
            addressDescription: 'Bu alan boş bırakılamaz',
            favMeal: 'Bu yemeği beğenmek için giriş yapmalısınız!',
            loginToPurchase: 'Sipariş verebilmek için giriş yapmalısınız!',
            newNotification: 'Yeni bir bildiriminiz var!',
            newOrder: 'Yeni bir siparişiniz var!',
            infoSuccessMessage: 'Değişiklikler Onaya Gönderildi',
            customerInfoChanged: 'Değişiklikler başarıyla kaydedildi!',
            passwordChangedSuccessMessage: 'Şifre başarıyla değiştirildi!',
            passwordChangedErrorMessage: 'Şifre değiştirilirken bir hata oluştu!',
        },
    },
    security: {
        password: 'Mevcut Şifre',
        newPassword: 'Yeni Şifre',
        confirmNewPassword: 'Yeni Şifre Onay',
        forgotPassword: 'Şifremi Unuttum?',
        updatePassword: 'Şifre Güncelle',
        socialAccounts: 'Sosyal Medyalar',
        approve: 'Onayla',
        giveUp: 'Vazgeç',
        successMessage: 'Kullanıcı Başarıyla Silindi!',
        areYouSure: 'Kullanıcıyı Silmek İstediğinize Emin Misiniz ?',
        deleteMyAccount: 'Hesabımı Sil',
        messages: {
            password: 'Lütfen eski şifrenizi giriniz!',
            newPassword: 'Lütfen yeni şifrenizi giriniz!',
            confirmNewPassword: 'Lütfen yeni şifrenizi tekrar giriniz!',
        },
        connected: 'Bağlandı',
        notConnected: 'Bağlanmadı',
        notification: 'Alışverişiniz oluşturuldu.',
        description: 'Bizi seçtiğiniz için teşekkür ederiz.',
        mealError: 'Lütfen tüm yemekleri aynı şeften sipariş edin!',
        delivery: 'Keşfet',
        becomeACourrier: 'Kurye Ol',
        all: 'Tüm',
        chefs: 'şefler',
        viewProfile: 'Profili Görüntüle',
        moreDetails: 'Daha Fazla Detay',
        portion: 'Porsiyon',
        minute: 'Dakika',
        addtoBasket: 'Sepete Ekle',
    },
    layout: {
        header: {
            navItemGuest: [
                // {
                //     id: 1,
                //     title: 'Hakkımızda',
                //     path: '/about',
                // },
                {
                    id: 1,
                    title: 'Ev Yapımı Ürünler',
                    path: '/homefood',
                },
                {
                    id: 2,
                    title: 'Şef Olun',
                    path: '/become-a-chef',
                },
                {
                    id: 3,
                    title: 'Kurye Olun',
                    path: '/become-a-courier',
                },
            ],
            navItemCustomer: [
                {
                    id: 1,
                    title: 'Ev Yapımı Ürünler',
                    path: '/homefood',
                },
                // {
                //     id: 1,
                //     title: 'Yemegim.co Hakkında',
                //     path: '/about',
                // },
                {
                    id: 2,
                    title: 'Şef Olun',
                    path: '/become-a-chef',
                },
                {
                    id: 3,
                    title: 'Kurye Olun',
                    path: '/become-a-courier',
                },
            ],
            navItemChef: [
                {
                    id: 1,
                    title: 'Ev Yapımı Ürünler',
                    path: '/homefood',
                },
                {
                    id: 2,
                    title: 'Yemegim.co Mağaza',
                    path: '/store',
                },
                {
                    id: 3,
                    title: 'Kurye Olun',
                    path: '/become-a-courier',
                },
            ],
            headerPopup: {
                overview: 'Benim Menüm',
                notification: 'Bildirimler',
                order: 'Siparişler',
                favourite: 'Favoriler',
                myBasket: 'Sepetim',
                setting: 'Ayarlar',
                helpCenter: 'Yardım(SSS)',
                logout: 'Çıkış',
                login: 'Oturum Açın',
                myProfile: 'Benim Menüm',
                register: 'Kaydolun',
                becomeAchef: 'Şefler/Üreticiler İçin',
            },
            headerHamburgerForChefs: {
                overview: 'Benim Menüm',
                notification: 'Bildirimler',
                order: 'Siparişler',
                favourite: 'Favoriler',
                myBasket: 'Sepetim',
                setting: 'Ayarlar',
                helpCenter: 'Yardım(SSS)',
                logout: 'Çıkış',
                login: 'Oturum Açın',
                myProfile: 'Benim Menüm',
                register: 'Kaydolun',
                becomeAchef: 'Şefler/Üreticiler İçin',
                becomeACourrier: 'Kurye Olun',
                vidiStore: 'Yemegim.co Mağaza',
                homeFood: 'Ev Yapımı Ürünler',
            },
            headerHamburgerForGuests: {
                becomeAchef: 'Şefler/Üreticiler İçin',
                becomeACourrier: 'Kurye Olun',
                logout: 'Çıkış',
                about: 'Yemegim.co Hakkında',
                register: 'Kaydolun',
                login: 'Oturum Açın',
                helpCenter: 'Yardım(SSS)',
                becomeAchef2: 'Nasıl Şef Olurum',
            },
            headerHamburgerForCustomers: {
                becomeACourrier: 'Kurye Olun',
                logout: 'Çıkış',
                about: 'Yemegim.co Hakkında',
                register: 'Kaydolun',
                login: 'Oturum Açın',
                helpCenter: 'Yardım(SSS)',
                becomeAchef2: 'Nasıl Şef Olurum',
                myProfile: 'Profilim',
                notification: 'Bildirimler',
                order: 'Siparişler',
                favourite: 'Favoriler',
                myBasket: 'Sepetim',
                setting: 'Ayarlar',
            },
        },
        footer: {
            titleItem: [
                {
                    id: 1,
                    title: 'HAKKIMIZDA',
                    datas: [
                        {
                            id: 1,
                            name: 'Neden Yemegim.co ?',
                            path: '/about',
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'HOST',
                    datas: [
                        {
                            id: 2,
                            name: 'Explore',
                        },
                        {
                            id: 3,
                            name: 'Order',
                        },
                        {
                            id: 4,
                            name: 'My Meals',
                        },
                        {
                            id: 5,
                            name: 'In Box',
                        },
                        {
                            id: 6,
                            name: 'Build Your Kitchen',
                        },
                        {
                            id: 7,
                            name: 'Join an Online Experience',
                        },
                        {
                            id: 8,
                            name: 'Become A Courier',
                        },
                    ],
                },
                {
                    id: 3,
                    title: 'FOR YOUR INFORMATION',
                    datas: [
                        {
                            id: 9,
                            name: 'For Guest',
                        },
                        {
                            id: 10,
                            name: 'For Chefs',
                        },
                        {
                            id: 11,
                            name: 'Cancellation Policy',
                        },
                        {
                            id: 12,
                            name: 'Privacy and Cookie Policy',
                            path: '/conditions-of-use',
                        },
                        {
                            id: 13,
                            name: 'Protection of Data',
                            path: '/protection-of-data',
                        },
                        {
                            id: 14,
                            name: 'KVKK',
                        },
                        {
                            id: 15,
                            name: 'User Agreement',
                            path: '/user-agreement',
                        },
                    ],
                },
                {
                    id: 4,
                    title: 'SUPPORT',
                    datas: [
                        {
                            id: 16,
                            name: 'Community Center',
                        },
                        {
                            id: 17,
                            name: 'Resource Center',
                        },
                        {
                            id: 18,
                            name: 'Press',
                        },
                    ],
                },
            ],
            footerBottom: {
                title: `© ${getYear(new Date())} Yemegim.co · Privacy · Terms · Sitemap`,
                language: 'İngilizce (US)',
            },
        },
        BasketPage: {
            title: 'Sepetim',
            order: 'Sipariş',
            continueShopping: 'Alışverişe Devam Et',
            description: 'Alışveriş yapabilmek için',
            basketEmptyDescription: 'Sepetinizde ürün bulunmamaktadır.',
            orderSummary: 'Sipariş Özeti',
            productTotal: 'Sepet Toplamı',
            cargo: 'Kargo',
            priceText: 'Ücret Toplamı',
            discount: 'İndirim',
            completeShopping: 'Alışverişi Tamamla',
            noAdressWarning: 'Devam etmek için adres bilgisi giriniz',
            agreementHref: 'Mesafeli Satış',
            agreement1: ' Sözleşmesi',
            seller: 'Satıcı: ',
            deliveryDate: 'Teslim Tarihi: ',
            emptyBasket: 'Sepetinizde ürün bulunmuyor.',
            delete: 'Bu yemeği silmek istediğinize emin misiniz?',
            store: 'Yemegim.co Mağaza',
            gotoAddress: 'Değiştirmek için tıklayın',
            currentAddres: 'Geçerli Adres',
            promotionCode: 'İndirim Kodu Seçiniz',
            deleteCode: 'Sil',
            apply: 'Uygula',
        },
        EmailVerifyPage: {
            seccessVerify:
                'Merhaba, \nTeşekkürler! E-posta adresiniz başarıyla doğrulandı. Hesabınız artık aktif. \nHaydi! Artık lezzetli yemekler servis edebilirsiniz.',
            errorVerify: 'Merhaba, \nE-posta adresiniz doğrulanamadı. \nPostanızı onayladıktan sonra lezzetli yemekler sipariş edebilirsiniz.',
            discoverNow: 'Keşfet',
            verified: 'E-posta Doğrulandı',
            error: 'Hata',
        },
        LandingPage: {
            dummyDataInfoSection: {
                howItWork: 'NASIL ÇALIŞIR',
                globalLocal: 'Yakınınızdaki küresel ve yerel yiyecekleri keşfettikten sonra anında sipariş verebilirsiniz.',
                desicionMeal: 'Favori yiyeceklerinize karar verdikten sonra dilerseniz haftalık menü oluşturabilirsiniz.',
                specialRequest: 'Özel siparişlerinizi şeflere iletebilir, size özgün lezzetlere kolaylıkla erişebilirsiniz.',
                deliverCook: 'Şeflerin mutfaklarında özenle hazırladıkları yiyecekleri kapınızda teslim alabilirsiniz.',

                tasteWant: 'YENİ LEZZETLER KEŞFEDİN',
                meat: 'Etli Ürünler',
                seaFood: 'Deniz Ürünleri',
                vegan: 'Vegan Yiyecekler',
                dessert: 'Tatlı Çeşitleri',
                cake: 'Kek Çeşitleri',
                special: 'Özel Siparişler',
            },
            BannerSection: {
                title: 'Yakındaki lezzetli yiyecekleri keşfedin',
            },
            VideoSection: {
                title: 'YEMEGIM.CODAKI MİLYONLARCA ŞEFE KATILIN',
            },
            FoodCardPreview: {
                detail: 'Detaylar',
                km: ' km uzağında',
            },
        },
        NearPlacesPage: {
            name: 'Keşfet',
            country: 'Ülkeler',
            mile: 'Mil',
            km: ' Km uzağında',
            price: 'Ücret',
            foodType: 'Yemek Tipi',
            writeFoodName: 'Yemek adını yazınız',
            noMealWarning: 'Aradığınız ürün bulunmamaktadır',
            noCityWarning: 'Yakında burada olacağız',
            noCategoryWarning: 'Kategoriye ait bir ürün bulunmamaktadır',
            success: 'Seçtiğiniz yemek favorilere eklenmiştir.',
            fail: 'Seçtiğiniz yemek favorilerden kaldırılmıştır.',
            region: 'Bölgeler',
            all: 'Hepsi',
            foodTypesMeat: 'Et',
            foodTypesSeafood: 'Deniz ürünleri',
            foodTypesVegan: 'Vegan',
            foodTypesDessert: 'Tatlı',
            foodTypesCake: 'Kek',
            foodTypesSpecial: 'Spesiyal',
            vidiFood: 'Ev Yapımı Ürünler',
        },
        MealChoicePage: {
            back: 'Geri',
            foodTitle: 'Seçtiğiniz Yemek',
            chefTitle: 'Aynı Yemeği Yapan Diğer Şefler',
            emptyChefMessage: 'Bu yemeği yapan başka şef yok',
        },
        MealModal: {
            message: 'Sadece şefin çalıştığı saat aralıklarında sipariş verebilirsiniz.',
            date: 'Tarih',
            time: 'Zaman',
            note: 'Notlar',
            addtoBasket: 'Sepete Ekle',
            timePlaceHolder: 'Saat seçiniz',
            datePlaceHolder: 'Tarih seçiniz',
            notePlaceHolder: 'Not giriniz',
            ownMealFail: 'Kendi yemeğinizi sepete ekleyemezsiniz!',
            fillfieldFail: 'Lütfen bütün boşlukları doldurun!',
            now: 'Şimdi Gelsin',
            future: 'İleri Tarihli Sipariş Oluştur',
            chefError: 'Kendi yemeğinizi sepete ekleyemezsiniz!',
            basketError: 'Aynı anda iki farklı şeften sipariş veremezsiniz!',
            chefBasketError: 'Sepetinizde aynı anda yemek ve mağaza ürünleri bulunduramazsınız!',
        },
        PreviewUserProfilePage: {
            myMenu: 'Menüm',
            profileCardDescription: 'Ayarlardan kendinizle ilgili bir açıklama ekleyebilirsiniz.',
            scheduleOfChef: 'Şefin Çalışma Takvimi',
            emptyMessage: 'Şef henüz ajandasını oluşturmadı!',
            startDate: 'Çalışma Saati Başlangıç',
            endDate: 'Çalışma Saati Bitiş',
            days: 'Günler',
            monday: 'Pazartesi',
            tuesday: 'Salı',
            wednesday: 'Çarşamba',
            thursday: 'Perşembe',
            friday: 'Cuma',
            saturday: 'Cumartesi',
            sunday: 'Pazar',
        },
    },
    FoodDetailPage: {
        back: 'Geri',
        addtoBasket: 'Sepete Ekle',
        ingredient: 'Malzemeler:',
        text: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.. Save the Date: 23.02.2021',
        minute: 'dk',
        portion: 'porsiyon',
        TextSection: {
            comment: 'Yorum Yapın',
            rate: 'Puan:',
            meal: 'Lezzet:',
            service: 'Servis:',
            quality: 'Kalite:',
            yourComment: 'Yorumun:',
            send: 'Gönder',
            inputComment: 'Lütfen yorumunuzu girin!',
            commentReceived: 'Yorumunuz alındı. Onaylandıktan sonra görebileceksiniz.',
        },
    },
    PaymentPage: {
        successContentUp: 'Teşekkürler. Siparişin başarıyla alındı. En kısa sürede şef size dönüş yapacaktır.',
        successContentDown: 'Haydi! Lezzetli yemekleri keşfetmeye devam et.',
        buttonText: 'Anasayfa',
        paymentSuccess: 'Ödeme Başarılı',
        failContentUp: 'Ops.. Ödemenle ilgili bir şeyler yanlış gidiyor. Lütfen bilgilerini kontrol eder misin?',
        failContentDown: 'Haydi! Lezzetli yemekleri keşfetmeye devam et.',
        paymentFail: 'Ödeme Başarısız',
    },
    HelpCenterPage: {
        back: 'Geri',
        title: 'Destek Merkezi',
        placeholder: 'Size nasıl yardım edebiliriz?',
        faqs: 'Sıkça Sorulan Sorular',
        firstButton: 'Yemegim.co nedir?',
        secondButton: 'Kuryelik',
        thirdButton: 'Üyelik Yönetimi',
        fourthButton: '0850 888 8434',
        answer: 'Tüm sorular ve Cevapları',
        firstHeader: 'Yemegim.co nedir?',
        question1: 'Yemegim.co nedir?',
        question2: 'Yemegim.co nasıl çalışır?',
        question3: 'Yemegim.co ile nasıl iletişim kurabilirim?',
        question4: 'Yemegim.co ile işbirliği yapmak istiyorum!',
        question5: 'Yemegim.co satış vergisi uyguluyor mu?',
        question6: 'Ek sorular için kiminle iletişim kurabilirim?',
        contact: 'İletişim',
        address: 'Adres:',
        fullAddress: 'Nispetiye Mahallesi, Nispetiye Caddesi, No: 23/17, Beşiktaş/İstanbul',
        email: 'E-mail:',
        forDelivery: 'Teslimat için:',
        deliveryMail: 'teslimatdestek@vidieats.com',
        forPayment: 'Ödemeler için:',
        paymentMail: 'ödemedestek@vidieats.com',
        forTechnical: 'Teknik konular için:',
        technicalMail: 'teknikdestek@vidieats.com',
        forOther: 'Diğer konular için:',
        otherMail: 'destek@vidieats.com',
        phone: 'Telefon:',
        phoneNumber: '0850 888 84 34',

        header1: 'App Hakkında',
        header2: 'Sipariş Yönetimi',
        header3: 'Taşıma Hakkında',
        header4: 'Ödemeler & İndirimler',
        header5: 'Şef Hakkında',
        header6: 'Kurye Hakkında',
        header7: 'Diğer Konular',

        header1Desc1: 'App’i nasıl indiririm?',
        header1Desc2: 'Yemek seçimini nasıl yaparım?',
        header1Desc3: 'Sepetimi nasıl oluştururum?',
        header1Answ1:
            'iOS uygulamasını indirmek için App Store sayfasına veya Android uygulamasını indirmek için Google Play Store sayfasına giriş yaptıktan sonra Yemegim.co uygulamasını seçip indirmeye başlayabilirsiniz.',
        header1Answ2: 'Uygulamaya giriş yaptıktan sonra keşfet alanından filtreleme işlemi yaparak arzu ettiğiniz yemeği seçebilirsiniz.',
        header1Answ3:
            'Seçtiğiniz yemeği inceledikten sonra, sayfa içerisindeki Sepete Ekle’yi işaretlediğinizde karşınıza çıkan ekrandan porsiyon sayısını, teslimat tarihini ve dilerseniz ilave notlarınızı girerek sepete ekleme işleminizi tamamlayabilirsiniz.',

        header2Desc1: 'Sipariş veren kişinin yemeği teslim almak için evde bulunması gerekiyor mu? ',
        header2Desc2: 'Sipariş takip bilgilerimi nerede bulabilirim?',
        header2Desc3: 'Ödeme alındıktan sonra siparişimi iptal edebilir miyim?',
        header2Desc4: 'Yanlış sipariş bana ulaştığında ne yapmalıyım?',
        header2Desc5: 'Bana ulaşan siparişten memnun kalmadıysam ne yapmalıyım?',

        header2Answ1:
            'Sipariş veren kişi dışında evde bulunan bireylerden biri yemeğinizi teslim alabilir. Adres değişikliği yapılması gerektiğinde veya teslimat ile ilgili sorularınız için İletişim başlığından bize ulaşabilirsiniz. ',
        header2Answ2:
            'Siparişiniz, şef tarafından hazırlanmaya başladığında ve hazırlama işlemi tamamlanıp kurye taşıma işlemine başladığında uygulama tarafından bilgilendirileceksiniz. Ayrıca uygulama içerisinden “Siparişim Nerede?” bölümü üzerinden anlık olarak sipariş takibini yapabilirsiniz. Teslimat adresinin bulunduğu bölgeye göre taşıma işlemi 15-45 dakika sürebilmektedir.',
        header2Answ3:
            'Sipariş iptal süreniz dolmadan önce siparişlerim sayfanızda “İptal-Değiştir” seçenekleri aktif olacaktır. Şef yemeğinizi hazırlamaya başlayıncaya (yemeğiniz hazırlanmaya başladığında uygulama üzerinden bilgilendirileceksiniz) kadar siparişinizi iptal edebilir veya değişiklik yapabilirsiniz. Sipariş verdikten 15 dakika sonra yapılacak iptallerde ödenen tutar üzerinden %15 ile %30 oranında kesinti yapılır (siparişte kullanılan malzemeye bağlı olarak değişiklik gösterir). Yemeğiniz taşıma işlemi için hazır duruma getirilmeye başlandığında “İptal-Değişiklik” işlemleri gerçekleştirilemeyecektir. Bu nedenle herhangi bir sorunuzun bulunması durumunda size yardımcı olabilmemiz için en kısa sürede bizimle iletişime geçebilirsiniz.',
        header2Answ4:
            'Uygulamaya girişinizden, aradığınız lezzete ulaşıncaya kadar harika bir deneyim yaşamanız bizim için gerçekten çok önemli. Bunun en önemli aşaması tam olarak sipariş ettiğiniz yiyeceğin size ulaşmış olmasıdır. Eğer doğru yiyeceği teslim almadığınızı düşünüyorsanız vakit kaybetmeden bizimle iletişime geçebilirsiniz. Gerekli değişim-iade işlemlerini tamamlayarak size aradığınız lezzeti sunmaktan mutluluk duyacağız.',
        header2Answ5:
            'Sizin mükemmel bir deneyim yaşayarak siparişiniz ile mutlu olmanız bizim için önceliktir. Bu nedenle eğer siparişinizden memnun kalmadıysanız veya herhangi bir sorun ile karşılaştıysanız lütfen bizimle iletişime geçiniz.',

        header3Desc1: 'Nerelerden yemek siparişi verebilirim? ',
        header3Desc2: 'Ne kadar kurye ücreti öderim?',
        header3Desc3: 'Taşıma işlemini kim gerçekleştirecek?',
        header3Desc4: 'Taşıma süresi ne kadardır?',

        header3Answ1: 'Bulunduğunuz konumdan 15 km mesafede bulunan tüm şeflerden sipariş vermeniz mümkündür. ',
        header3Answ2:
            'Sipariş verdiğiniz aşçı ile yiyeceğin taşınacağı bölge arasındaki mesafenin 5 km`den az olması durumunda, yiyecek ücreti dışında ilave bir kurye ücreti ödemezsiniz. Taşıma mesafesinin 5 ile 15 km arasında olması durumunda, mesafeye ve yemek miktarına göre 3 ile 8 TL arasında kurye ücreti ilave edilebilmektedir. Satın alma işlemi aşamalarında taşıma ücreti bilginize sunulmuş olacaktır. ',
        header3Answ3:
            'Yiyeceklerinizin taze ve hızlı ulaşması için, Yemegim.co kurye uygulamasına kayıtlı kuryelerimiz tarafından taşıma işlemi gerçekleştirilmektedir. ',
        header3Answ4:
            'Sipariş verilen aşçı ile teslimatın yapılacağı adres arasındaki mesafeye bağlı olarak taşıma süresi 15-60 dakika sürebilmektedir.\nÖnemli not: Taşıma süresine şeflerin yemek hazırlama süreleri dahil değildir. Yiyecek seçiminizi yaparken, seçim ekranında, şeflerin siparişi hazırlama süresi ayrıca belirtilmektedir. Bu süre yiyeceğin çeşidine ve miktarına göre değişiklik göstermektedir. ',

        header4Desc1: 'Hangi tür ödemeler kabul edilmektedir? ',
        header4Desc2: 'Herhangi bir indirim uygulaması var mı?',

        header4Answ1:
            'Bankalar tarafından oluşturulan bankamatik veya kredi kartları (MasterCard, Visa, American Express vb.) ile online ödeme gerçekleştirebilirsiniz. ',
        header4Answ2:
            'Öğrencilere ve yılın belirli zaman dilimlerinde tüm kullanıcılara özel Yemegim.co uygulaması üzerinden indirim kampanyaları düzenlenmektedir. Bu kampanyalardan haberdar olabilmeniz için uygulama kullanım şartlarında sizinle iletişime geçmemize izin vermiş olmanız gerekmektedir. ',

        header5Desc1: 'Şef olmak için ne yapmalıyım?',
        header5Desc2: 'Dilediğim menüyü oluşturabilir miyim?',
        header5Desc3: 'Hangi zamanlarda çalışabilirim?',
        header5Desc4: 'Menüde bulunan yiyecekler için istediğim fiyatı belirleyebilir miyim?',
        header5Desc5: 'Müşteri tarafından oluşturulan sipariş bana ulaştığında siparişi kabul etmeyebilir miyim?',
        header5Desc6: 'Sipariş kabul ettikten sonra yiyeceği zamanında hazır edemezsem ne olur?',
        header5Desc7: 'Satış işlemleri sonucunda ne kadar vergi öderim?',
        header5Desc8: 'Neden Yemegim.co ile şeflik yapmalıyım?',

        header5Answ1:
            '•  Yemegim.co’nun şefler dünyasına katılmak için Şef Olun bölümündeki bilgileri, aşağıda belirtilen hususları dikkate alarak, doldurmanız gerekmektedir.',
        header5Answ2: '•  E-mail veya telefon bilgilerin ile uygulamaya giriş yaptıktan sonra ilk olarak istenilen bilgileri doldurmanız gerekir. ',
        header5Answ3:
            '•  Yemegim.co’nun sizinle paylaşacağı önlük, bone ve eldivenleri giyip yüzünüzün ve mutfağınızın fotoğrafını çekin. Profiliniz için en iyi fotoğrafı seçmeye çalışın.',
        header5Answ4:
            '•  Müşteriler, siparişlerinin nasıl bir ortamda hazırlandığını görmek isterler. Bu yüzden mutfağınızın resimlerini eklemeyi unutmamalısınız. Bu müşterilerinizin kararını olumlu şekilde etkileyecektir. En iyi fotoğraf için mutfağınızı toparlamayı, doğal ışık kullanmayı ve mümkünse kameranızı yatay şekilde konumlandırmayı unutmayınız.',
        header5Answ5:
            '•  Eğer herhangi bir bankada ticari bir hesabınız yoksa Türkiye Cumhuriyeti Vergi Dairelerinden “Esnaflık Muafiyet Belgesi” alıp uygulamaya yüklemeniz gerekmektedir. ',
        header5Answ6: '•  Esnaf muafiyet belgenizi aldıktan sonra istediğiniz bankada ticari hesap açıp hesap dekontunuzu uygulamaya yüklemelisiniz.',

        header52Answ1:
            'Mutfağınızın en lezzetli ürünlerini içeren bir menü oluşturmalısınız. Menüyü hazırlarken yemeklerinizi özel kılan ayrıntıları (vejetaryen, deniz, et veya pasta, kek gibi) vurgulamayı unutmayın. Ayrıca yemeklerinizin ana malzemelerini paylaşıp taze olduklarını belirtebilirsiniz. Müşterilerinizin daha kolay karar verebilmesi için siparişlerin ne kadar sürede hazırlanacağını ve en az kaç kişilik olduğunu paylaşabilirsiniz.',
        header52Answ2:
            'İstediğiniz herhangi bir zaman diliminde tam gün veya belirli saat aralıklarında çalışmanız mümkündür. Bunun için yapmanız gereken tek şey uygulama üzerinden kendi takviminizi belirlemenizdir. Rezervasyon alabileceğiniz uygun zamanlara göre takviminizi güncelleyebilirsiniz. ',
        header52Answ3:
            'Porsiyon fiyatlarını belirlerken gider tablonuza göre kendinizin belirlediği fiyatlamayı kullanabilirsiniz. Bunun yanında, Yemegim.co’nun yemek kategorilerine göre en düşük ve en yüksek olarak belirlediği fiyat kontrolleri bulunmaktadır. Fiyatlamada belirlenen bu değerler dışına çıkılamaz. ',
        header52Answ4:
            'Belirlediğiniz çalışma takvimleri dışında size sipariş geçilmeyecektir. Bu nedenle eğer sipariş kabul etmeme durumundaysanız menünüzü offline konuma getirmeniz gerekmektedir. Sipariş geçildikten sonra kabul etmemeniz, uygulamadaki beğeni durumunuzu düşürerek üyeliğinizin askıya alınmasına sebep olabilecektir. ',
        header52Answ5:
            'Gelen siparişi onayladıktan sonra yiyeceği menüde belirttiğiniz süre içerisinde hazırlamanız gerekmektedir. Yiyeceği hazırlama sürenizi takip eden kurye bu süre bitiminde yiyeceği almak için kapınızda olacaktır. Müşteri-Şef-Kurye-Müşteri akışının zamanında gerçekleşeceği Yemegim.co’nun taahhüdü olduğundan, menünün belirlenmiş sürede hazırlanmasına azami derecede dikkat edilmelidir. ',
        header52Answ6:
            'Eğer Türkiye Cumhuriyeti Vergi Dairelerinde esnaf kaydiniz mevcut değilse, Yemegim.co üzerinden satış yapılabilmeniz için Türkiye Cumhuriyeti Vergi Dairelerinden “Esnaf Vergi Muafiyet Belgesi” almanız gerekmektedir. Bu belgeyi temin edenler istediği bir banka şubesinden ticari hesap açarak satış yapmaya başlayabilir ve gelirlerinin 2% ile 4% oranlarında vergi kesintisi ile vergi sorumluluklarını yerine getirmiş olurlar. Uygulamada şef olarak kayıtlı bulunan birey tek başına hizmet verecekse vergi kesintisi 4% eğer yanında bir başkasını da çalıştıracak olursa (bu eşi veya herhangi bir yakını olabilir) vergi kesintisi 2% olarak uygulanır. ',
        header52Answ7:
            '•  Kendi mutfağınızda, ilave bir yatırım yapmadan, kendi işinizin patronu olup istediğiniz zaman istediğiniz şekilde çalışabilirsiniz. ',
        header52Answ8: '•   Belirlediğiniz sürelerde part-time veya tam zamanlı hizmet sunabilirsiniz.',
        header52Answ9:
            '•  Kazandığınız ücretler en geç beş iş günü içerisinde hesabınıza yatacağından, aile bütçenize rahatlıkla ilave katkı sağlayabilirsiniz.',
        header52Answ10: '•  Başka aşçılarla bilgi alışverişinde bulunabilir ve menünüzü zenginleştirebilirsiniz.',
        header52Answ11: '•  Yemek yapma, bilgi alışverişinde bulunma ve yerelde menülerinizi zenginleştirme imkanı elde edebilirsiniz. ',
        header52Answ12:
            '•  Yemegim.co’nun sunacağı gurme turlarıyla dünyanın farklı ülkelerine gitme ve bu şekilde global düzeyde menünüzü zenginleştirme imkanına erişebilirsiniz.',

        header6Desc1: 'Kimler kurye olabilir?',
        header6Desc2: 'Büyük araç ile kurye olmak için: ',
        header6Desc3: 'Kurye olmak için ne yapmalıyım?',
        header6Desc4: 'Hangi zamanlarda çalışabilirim?',
        header6Desc5: 'Nasıl ödeme alırım?',
        header6Desc6: 'Şef’in yemeğini taşımaya talip olduktan sonra taşımaktan vazgeçebilir miyim?',
        header6Desc7: 'Satış işlemleri sonucunda ne kadar vergi öderim?',
        header61Answ1: 'Aşağıda belirtilen şartları yerine getiren herkes Yemegim.co kurye ağına katılabilir.',
        header61Answ2: '•	18 yaşından büyük olmak',
        header61Answ3: '•	Bisiklet veya 50 cc altında motosiklet sahibi olmak',
        header61Answ4: '•	Sahip olduğu ve taşıma işlemine kullanacağı araçlar için sürücü belgesi ve koruyucu donanım sahibi olmak',
        header61Answ5:
            '•	Ticaret kaydının bulunması (“Esnaf Kurye Modeli” şahıs şirketi dâhil vergiye tabi bir işletme kaydı) veya “Esnaf Vergi Muafiyet Belgesi” sahibi olmak ',
        header61Answ6: '•	Harita bilgilerinin paylaşımı için, akıllı telefon sahibi olmak',
        header62Answ1: '• 	18 yaşından büyük olmak',
        header62Answ2: '•	Ehliyet sahibi olmak',
        header62Answ3: '•	Şirket sahibi (şahıs veya A.s, Ltd.) olmak',
        header62Answ4: '•	SRC-4 belgesi sahibi olmak',
        header62Answ5: '•	Sabıka kaydı olmamak',
        header63Answ1:
            'Yemegim.co’nun sunduğu kurye ağına katılmak için web sayfasındaki Kurye Ol bölümündeki formu doldurmanız veya mobil uygulamalardan “Kayıt Ol” sekmesindeki aşamaları takip ederek süreci tamamlamanız yeterlidir. Aşağıda belirtilen hususlara dikkat etmeniz kayıt işleminizin başarı ile tamamlanması için önem arz etmektedir. ',
        header63Answ2:
            '•	E-mail veya telefon bilgileriniz ile uygulamaya giriş yaptıktan sonra talep edilen tüm kişisel bilgilerinizi doldurmalısınız.',
        header63Answ3: '•	Profil fotoğrafını özenle seçmelisiniz.',
        header63Answ4: '•	Ticari hesabınızın bulunduğu banka şubesinden alacağınız hesap dekontunu uygulamaya yüklemelisiniz.',
        header64Answ1:
            'İstediğiniz herhangi bir zaman diliminde tam gün veya belirli süreli çalışmanız mümkündür. Bunun için yapmanız gereken tek şey uygulama üzerinden kendi takviminizi belirlemenizdir. Taşıma yapabileceğiniz zamanları belirleyerek oluşturduğunuz takviminize göre, uygulamada aktif kurye olarak görünür olacaksınız. Bu aşamadan sonra paket hazırlamakta olan şefleri harita üzerinden takip ederek verilen siparişi taşımaya talip olmanız harekete geçmeniz için yeterli olacaktır.',
        header64Answ2:
            'Yapacağınız her teslimat başına en az 5 TL ödeme yapılmaktadır. Yapılacak ödemeler teslimat mesafesine göre değişken tarifelidir. Her teslimat ücretini taşıma yapmadan önce uygulama üzerinden, kendi ekranınızda, görebilmeniz mümkündür. Ücretler en geç 5 iş günü içerisinde hesabınıza yatırılmaktadır. ',
        header64Answ3: `Uygulama üzerinde size tanınan süre içerisinde taşıma yapmaktan vazgeçebilirsiniz. Uygulamada belirlenen süre dışında, olağandışı durumlardan dolayı, taşıma işleminin iptal edilmesi için bizimle iletişime geçin. Müşteri-Şef-Kurye-Müşteri akışının zamanında gerçekleşeceği Yemegim.co’nun taahhüdü olduğundan, süre konusuna azami derecede dikkat edilmelidir.  `,
        header64Answ4:
            'Ticaret kaydınızın veya esnaf vergi muafiyetinizin olması durumuna göre Türkiye Cumhuriyeti Vergi Dairelerinin öngördüğü vergi oranlarında vergi ödemesi yapmanız gerekmektedir.  ',
        header7Desc2: 'Yiyeceklerin son kullanma tarihi var mı?',
        header7Desc3: 'Yiyecekleri nasıl taze tutuyorsunuz?',
        header7Desc4: 'Taşıma ve paketlemede kullanılan ürünler çevreye duyarlı mı?',
        header7Desc5: 'Şifremi unuttum nasıl giriş yapabilirim?',
        header7Answ2:
            'Yiyecekleriniz günlük olarak şeflerimiz tarafından hazırlanarak size ulaştırılmaktadır. Bu nedenle kendi mutfağınızda hazırlanmış gibi soğuma raf ömrüne sahiptirler ve iki gün içerisinde tüketilmesi önerilmektedir. Yemeklerden en iyi tadı almanız için hemen teslimat sonrasında tüketilmesini tavsiye ediyoruz, fakat yiyeceğinizi bitiremediğiniz durumlarda uygun koşullarda 48 saat saklamanız mümkündür. ',
        header7Answ3:
            'Yiyecek siparişiniz şefe ulaştıktan sonra şef tarafından yemekte kullanılacak olan malzemeler tedarik edilir ve siparişiniz hazırlanır. Hazırlanan siparişleri taze şekilde müşteriye ulaştırmak amacıyla Yemegim.co tarafından özel olarak tasarlanmış özel yemek kutularına/kaplarına konulur, yemekler bu şekilde müşteriye sunulur. ',
        header7Answ4:
            'Doğamızı korumak için üzerimize düşen tüm sorumlulukları yerine getiriyoruz, kullandığımız tüm paket, etiket, çanta vb. ürünlerin geri dönüştürülebilir olmalarına özen gösteriyoruz.  ',
        header7Answ5:
            'Eğer şifrenizi unuttuğunuz için uygulamaya giriş yapamıyorsanız, “Şifremi Unuttum” seçeneği üzerinden parolanızı yenileyebilirsiniz. Parolanızı yenilemek için sisteme kayıtlı telefon numaranızı yazmanız istenecektir ve yenileme şifreniz telefonunuza SMS olarak gönderilecektir. Size iletilen yenileme şifresini girdikten sonra şifrenizi yenileyebilirsiniz.',
    },
    AboutVidiPage: {
        title1: 'Neden Yemegim.co?',
        title2: 'Misyon',
        title3: 'Vizyon',
        title4: 'Yemegim.co Nasıl Çalışır?',
        desc1:
            'Yaşadığımız lokasyondan bağımsız olarak, hangi kültürde veya toplumda yetiştiysek o yöreye ait lezzetler damak tadımızı oluşturur. Damak tadı oluşma süreci ilk olarak çocukluk yıllarımızda ailemizin yaptığı yemekler ile başlar ve yıllar içerisinde şekillenir. Kişiye özgü olan damak tadını ancak aynı kültürden gelen insanlar yaşatabilir.  Bir yemek kültürünü yaşatıp sürdürerek var olan lezzetleri müşterilere ulaştırmak ve aynı zamanda bu saklı tatları hazırlayan şeflere kazanç sağlamak amacıyla Yemegim.co platformunu kurmaya karar verdik.  ',
        desc5:
            'Yemegim.co, 2021 yılında kurulmuş, restoran kültürü dışında yeme-içme sektörüne farklı bakış açısı getiren yaratıcı bir şirkettir. Tüm hijyen şartlarını sağlayan, lezzetli, sağlıklı, ev yapımı yemekleri müşterilerin kapısına teslim ederek onların memnuniyetini hedefleyen bir ortaklıktır. Bu hizmeti sağlarken, ev hanımlarını kendi mutfaklarında özel aşçıya dönüştüren, boş vakti olan her türlü araç sahibine (bisiklet, motosiklet vb. sahibi) kuryelik yapabilme olanağı sağlayan böylelikle hane gelirini arttırmayı destekleyen sosyal bir platformdur. Tüm bu özellikler ile Yemegim.co yeme-içme sektörünün vazgeçilmezi olmaya aday bir kuruluştur.  ',
        desc6:
            'Yemegim.co olarak; maddi veya manevi açıdan rekabet avantajı olmayan herkese en iyi bildikleri yani yetiştikleri kültüre özgü tatları hazırlayarak başarılı olmaları için yer alabilecekleri bir platform olmayı amaçladık. ',
        desc7:
            'Yemegim.co, şeflerin yarıştığı bir ortam değildir. Yemegim.co gerçek tatların, sağlıklı ve tamamen ev koşullarında hazırlanan yiyeceklerin tüketiciye sunulduğu bir adrestir. Yemegim.co şefleri sipariş üzerine yemekte kullanılacak miktarda malzemeyi temin eder ve bu sayede gıda israfını azaltmış olur. Öncelikli hedefimiz sağlıklı yemekleri tüketiciye ulaştırmak ve düzenli geliri olmayan bireylerin de Yemegim.co aracılığıyla kazanç sağlamalarına yardımcı olmaktır.',
        desc9: ` Yemegim.co, yöresel ve geleneksel lezzetlerini paylaşmak isteyen aşçıların menülerini yayınlar, müşteriler bu menüden
            beğendiğini seçer ve siparişinin tamamlar. Yemegim.co Kuryesi, hazırlanan siparişleri müşterinin belirtmiş olduğu adrese tam
            zamanında ulaştırır. `,
        desc2:
            'Yemegim.co; lokal, bölgesel ve dünya mutfağının birbirinden farklı lezzetlerini evlerinde yaşatanlarla, bu tatlara erişmek isteyenlerin buluştuğu bir adrestir. Her kültürün ve coğrafyanın saklı yemek veya içeceklerinin tanınmasına katkıda bulunmak isterken; kimseye bağlı kalmadan çalışma zamanını ve koşullarını kendi belirlemek isteyen ev hanımlarına, profesyonel aşçılara, öğrencilere hatta gezginlere para kazanma fırsatı sunmaktadır. ',
        desc8:
            'Yemegim.co müşterilerine, yemeklerini kişiselleştirebilme ve özel taleplerini aşçılara iletebilme imkânı vererek kendi damak zevklerine göre menü oluşturma şansı sağlar. Yemegim.co endüstriyel ürünlerin satışına izin vermez, sadece müşterilerin talebine uygun şekilde hazırlanacak tamamen ev yapımı sağlıklı yiyecek ve içeceklerin satışına izin verir. Yoğun şekilde çalışan kullanıcılara, evde yemek yapma imkânı bulamayan herkese haftalık menü oluşturma imkânı sağlar. ',
        desc3:
            'Öncelikle Türkiye’de daha sonra Avrupa ve Amerika’da saklı tatların keşfedildiği, farklı lezzetlerin paylaşıldığı, global düzeyde gerçek gurmelerin ve bu sağlıklı, benzersiz tatlara erişmek isteyenlerin buluştuğu lider platform olmaktır.  ',
        desc4:
            'Kendine özgü olarak geliştirdiğimiz iş modeli sayesinde, anne mutfağından çıkan geleneksel veya özel lezzetleri siz müşterilerimizin kapısına getiriyoruz. Bu eşsiz hizmete ulaşmak için sizlerin Yemegim.co çevrimiçi platformuna',
        desc4Href: ' girip',
        desc4Next: ' istediğiniz ürünleri sipariş etmeniz yeterlidir.',
        text1: 'Eşsiz lezzetler dünyasını',
        text2: 'Şimdi Keşfet',
    },
    BecomeACourier: {
        defaultQuestion: 'Uygun vakitlerinizi değerlendirerek gelir elde etmeye hazır mısınız?',
        title1: 'Nasıl Kurye Olurum?',
        title4: 'Ve daha fazlasını öğrenmek için',
        buttonText: 'Kurye Hakkında',
        buttonText2: 'Kurye Kayıt Formu',

        button1Ques: 'Sizin İçin Her Zaman Buradayız',
        button1Answ:
            'Yolda olduğunuz her zaman sizinleyiz. Herhangi bir yardım, tavsiye ve destek ihtiyacı halinde uygulama üzerinden anlık şekilde bizimle iletişime geçebilirsiniz.',
        button2Ques: 'Rekabetçi Ücretler',
        button2Answ:
            'Teslim ettiğiniz her bir sipariş için rekabetçi ücretler ödemekteyiz. Ek olarak siparişlerinizi teslim ettiğiniz zaman müşterilerden bahşiş kazanabilirsiniz. Böylelikle hedeflerinize daha kolay bir şekilde ulaşabilirsiniz.',
        button3Ques: 'Kazancınızı Arttırabilirsiniz',
        button3Answ: 'Yoğun bölgelerin avantajından faydalanarak yakın mesafelerde ilave transferler ile daha fazla kazanabilirsiniz.',
        button4Ques: 'Beş İş Günü İçerisinde Ödeme Yapıyoruz',
        button4Answ: 'Kazançlarınızı haftalık olarak hesaplarınıza gönderiyoruz.',
    },
    BecomeAChef: {
        defaultQuestion: 'Lezzetlerinizi Tüm Dünya ile Paylaşmaya Hazır mısınız?',

        title1: 'Nasıl Şef Olurum?',
        title2: 'Lezzetlerinizi Tüm Dünya ile Paylaşmaya Hazır mısınız?',
        title4: 'Yemegim.co’da Şef Olmak için',
        buttonText: 'Kaydol',
        button1Ques: 'Neden Yemegim.co ile Şeflik Yapmalısınız?',

        button1Answ: 'Yemegim.co’nun sağladığı imkanları ve avantajları keşfetmek için tıklayınız.',

        button2Ques: 'Yemegim.co ile Gelir Elde Etme',
        button2Answ: 'Fiyatlandırma ve ödeme hakkında bilmeniz gerekenlere tıklayarak ulaşabilirsiniz.',
        button3Ques: 'Mutfağım ve menüm Yemegim.co için uygun mu?',
        button3Answ: 'Her lezzet ve tat için mükemmel bir müşteri vardır, işin sırrını öğrenmek için tıklayınız.',
        button4Ques: 'Yemegim.co macerana başla!',
        button4Answ: 'Hesap oluşturmaktan menü hazırlamaya, Yemegim.co ile çalışmanın temellerini öğrenmek için tıklayın.',

        ques1Desc1:
            ' Lezzetli yemekler, tatlılar veya hamurlu yiyecekler yaptığınıza inanıyorsanız ve mutfağınızın Yemegim.co`in temel hijyen gereksinimlerini karşıladığını düşünüyorsanız yapacağınız yiyeceklerden gelir edebilir aynı zamanda çok farklı insanlarla bağlantı kurup yöresel hatta dünya mutfağına ait tatları paylaşma imkânına kavuşabilirsiniz. Peki, menünüzün fiyatına ve ödeme almak için hangi yöntemi kullanacağınıza nasıl karar vereceksiniz? İşte bilmeniz gerekenler:',
        ques1Answer1: 'Yemegim.co sizlere kendi işinizin patronu olup istediğiniz zaman kendi mutfağınızda çalışma imkanını sunar.',
        ques1Answer2: 'Elde ettiğiniz gelirlerle aile bütçenize katkı sağlayabilirsiniz.',
        ques1Answer3:
            ' Bürokratik işlemler ile vakit kaybetmeden çok düşük bir vergi diliminde çalışırsınız. (Esnaf Muafiyet Belgesi ile kişi başına yıllık 220,000 TL’ye kadar 2-4% vergi ile çalışma olanağı)',
        ques1Answer4: ' Şef olarak elde ettiğiniz kazançlara en geç beş iş günü içerisinde ulaşabilirsiniz.',
        ques1Answer5: 'Farklı lezzetler sunan diğer aşçılar ile bilgi alışverişinde bulunarak menünüzü geliştirebilirsiniz. ',
        ques1Answer6:
            'Yemegim.co’nun sunacağı gurme turlarıyla farklı ülkelere gitme imkanı kazanabilirsiniz. Böylelikle küresel bir şekilde menülerinizi zenginleştirip geniş bir tüketici kitlesine ulaşıp daha fazla gelir elde edebilirsiniz.',
        ques2Desc1:
            'Lezzetli yemekler, tatlılar ve hamur ürünleri yaptığınıza inanıp mutfağınızın Yemegim.co’nun temel hijyen gereksinimlerini karşıladığını düşünüyorsanız aramıza katılmalısınız. Şef olarak yapacağınız lezzetli yemeklerden gelir elde ederken farklı insanlarla bağlantı kurarak yöresel ve hatta dünya mutfağına ait lezzetleri paylaşma imkanı bulacaksınız. Her şey tamam ama menünüzün fiyatına ve alacağınız ödemelerde hangi yöntemi kullanacağınıza nasıl mı karar vereceksiniz? İşte tüm bilmeniz gerekenler:',
        ques2Header1: 'Mutfaginizi kaydetmek ücretsizdir',
        ques2Answer1: 'Yemegim.co’ya',
        ques2Answer1Href: 'kaydolmak',
        ques2Answer1Next:
            've menünüzü yayınlamak tamamen ücretsizdir. Tek yapmanız gereken ilgi çekici bir kayıt oluşturup siparişlerinizi beklemek olacaktır. Bununla beraber tarafınıza yapılacak olan her ödemeden şef hizmet bedeli kesilir. Yapılan kesintiler ile Yemegim.co giderleri karşılanmakta olup sizlere daha iyi bir hizmet vermek amacıyla sürekli geliştirmeler yapılmaktadır. Tarafınıza yapılacak ödeme, müşteriden talep ettiğiniz ücretten şef hizmet bedeli çıkarıldığında elde edilen tutardır.',

        ques2Header2: 'Kazancınızı siz belirlersiniz',
        ques2Answer2:
            'Porsiyon fiyatlarını belirlerken gider tablonuza göre kendinizin belirlediği fiyatlamayı kullanabilirsiniz. Bunun yanında, Yemegim.co’nun yemek kategorilerine göre en düşük ve en yüksek olarak belirlediği fiyat kontrolleri bulunmaktadır. Fiyatlamada belirlenen bu değerler dışına çıkılamaz.',
        ques2Answer2Italic: ' Özel günler için şefin yapacağı özel menüde fiyat belirleme alt limiti tamamı ile şef’e aittir. ',
        ques2Header3: 'Ödemenizi teslimattan sonra alırsınız',
        ques2Answer3:
            'Yemegim.co, ödemelerinizi siparişleriniz müşterilerinize teslim edildikten en erken 24 saat sonra en geç 5 iş günü içerisinde göndererek tamamlamış olur. Ödemelerinizin durumunu istediğiniz zaman işlem geçmişinizi inceleyerek kontrol edebilirsiniz. Ödemelerin tarafınıza nasıl yapılacağı sizin tercihinizdedir. Kullanılabilir ödeme yöntemleri bulunduğunuz yere bağlı olarak bankanıza doğrudan havale, EFT, (PayPal, Western Union, ön ödemeli kartlar) vb. seçeneklerini içermektedir.',

        ques2Header4: 'Vergiler uygulanabilir',
        ques2Answer4:
            'Ticari kayıtları bulunmayan şeflerin Vidieats üzerinden satış yapabilmesi için Türkiye Cumhuriyeti Vergi Dairelerinden “Esnaflık Muafiyet Belgesi” almaları zorunludur. Bu belgeyi temin eden şefler istedikleri bir banka şubesinden ticari hesap açar ve bu hesap üzerinden 2% ile 4% vergi kesintisi uygulanır.',
        ques2Answer4Italic:
            ' Şef kendisi ile birlikte bir çalışan bulundurması durumunda gelir vergisi kesintisi %2 olarak uygulanırken, faaliyetleri tek başına yürütüyor olması durumunda ise %4 olarak uygulanır. ',
        ques2Header5: 'Kaydolun, ayarlayın, kazanın',
        ques2Answer5:
            'Vidieats ailesi olarak sizlere kendi mutfağınız üzerinden gelir elde etmenizi sağlayıp başarıya ulaşmanız için çalışırız. Güvenli bir ödeme sistemi ve istediğiniz fiyatlandırmayı seçme özgürlüğünüz ile ticari hedeflerinize uygun bir',
        ques2Answer5Href: ' kayıt ',
        ques2Answer5Next: 'oluşturabilirsiniz.',
        ques3Desc1:
            ' Her lezzet ve tat için mükemmel bir müşteri vardır. Önemli olan işin sırrını öğrenmektir. Tuzlu bir çayın veya şekerli suda haşlanan bir balığın Vidieats’te popüler olmayacağını düşünebilirsiniz. Aslında müşterilere hitap eden birçok farklı türde yemek vardır; benzersiz ya da mütevazı olanlar bile. Yemekleriniz ister sıra dışı, ister sade ve uygun fiyatlı olsun, onları çekici kılan püf nokta ayrıntılı ve dürüst bir kayıt oluşturmaktır.',
        ques3Header1: 'Temel öğelerle başlayın',
        ques3Answer1:
            '    Bir Vidieats menüsü için nelere ihtiyacınız var? Müşterilerinize hazırladığınız yiyeceklerin sağlıklı ve taze olduğunu, aynı zamanda siparişlerin onların istedikleri zaman teslim edilebilme gibi imkânlara sahip olduklarını hissettirmelisiniz. Her yemeğin hazırlanması için gerekli minimum süreyi ve yemek içerisinde bulunan malzemeleri detaylı olarak açıklayarak müşterilerinizi bilgilendirmeniz gerekmektedir.',
        ques3Header2: 'Yemek çeşidinizi tanımlayın',
        ques3Answer2:
            '     Vidieats’te her çeşit yemek bulunur, bu nedenle kaydınızda sunduğunuz yiyeceğin türünü tam olarak belirtmeniz arama önceliği için önemlidir. Vejetaryen mi, etli mi ya da tatlı mı? Hamur ürünü mü ya da benzersiz bir lezzet mi? Tüm bunları eksiksiz bir şekilde belirtmeniz yemeklerinizin bulunmasını kolaylaştıracaktır.',
        ques3Header3: 'Müşterilerinizin neleri sipariş edebileceğini belirleyin',
        ques3Answer3:
            '      Müşterilerinize menünüzden seçtikleri yiyecek ile birlikte başka neleri sipariş edebileceklerine dair bilgiler sunabilirsiniz. Örneğin; ev yapımı içecek, börek ya da mevsime uygun bir salata.',
        ques3Header4: 'Artılar ve eksiler hakkında dürüst olun',
        ques3Answer4:
            '    Adana’da şırdan yapan bir şef şırdanın çok lezzetli ve sağlıklı olduğunu söyleyebilir. Fakat sakatata alerjisi olan biri için öncesinde uyarıcı bilgilendirme yapmak uygun olacaktır. Bu nedenle her ürün ile birlikte varsa sınırlayıcı tüketim özellikleri belirtilmelidir.',
        ques3Header5: 'Sunumda fark yaratın',
        ques3Answer5:
            '        Kullanılan malzemelerin nereden ve nasıl temin edildiğini belirtmeniz müşteri kitlenizi genişletmeniz için faydalı bir adımdır. Örneğin; ürünlerinizde ev yapımı salça veya kendi üretiminiz olan zeytinyağı kullanarak diğer şefler arasından ön plana çıkabilirsiniz.',
        ques3Header6: 'Doğru hedef kitlesine odaklanın',
        ques3Answer6:
            '  Her yiyecek türünün kendine özgü bir müşteri kitlesi vardır. Örneğin; Ege yöresine ait lezzetlerden hoşlanan biri için acılı yemekler pek ilgi çekici olmayabilir ama Güneydoğu Anadolu bölgesindeki müşterileriniz için bu vazgeçilmez bir lezzettir. Bu nedenle her ürünün herkes için uygun olmadığını unutmamanız gerekmektedir.',
        ques3Header7: 'Menünüzü gerçekçi biçimde fiyatlandırın',
        ques3Answer7:
            '  Menünüzdeki yemeklerinizi bulunduğunuz yörenin sosyo-ekonomik yapısına uygun olacak şekilde fiyatlandırmanız müşteri kitlenizin genişlemesini sağlayabilir. Ürünlerinizi farklı bölgelerde satış yapılan ücretler ile kıyaslayarak pazarlamanız kazancınızı olumsuz etkileyebilir.',
        ques3Header8: 'Herkese uygun bir lezzet',
        ques3Answer8:
            "Becerilerinize güveniyorsanız ve yemeklerinize uygun müşteriler bulabileceğinizi düşünüyorsanız, Vidieats'te her türlü yiyeceği satabilirsiniz. Pişireceğiniz güzel ve lezzetli yemeklerle kendi müşteri tabanınızı oluşturacaksınız. Başarı sizin elinizde. Buna ek olarak, müşterilerinizin puanlaması sizi Vidieats'teki sıralamada daha belirgin hale getirecektir. Aynı şekilde, kötü hizmetin bir sonucu olarak, Vidieats üyeliğinizi sonlandırmak zorunda kalabilir. ",
        ques3Answer8Href: ' blog ',
        ques3Answer8Next:
            'sayfamızı ziyaret edebilirsiniz). Kendi müşteri kitlenizi yapacağınız güzel ve lezzetli yemekler ile kendiniz yaratacaksınız. Başarı sizin elinizde. Ayrıca müşterilerinizin yapacağı puanlama sizi Vidieats içerisindeki sıralamada daha öne çıkaracaktır. Aynı şekilde kötü hizmet sonucunda Vidieats’te üyeliğinizi sonlandırmak zorunda kalabilir. ',
        ques4Desc:
            'Kaydınızı mutfağınızın bir reklamı olarak düşünebilirsiniz. Bu yüzden mutfağın ile ilgili tüm ayrıntıları belirterek onu ilgi çekici bir hale getirmeyi unutma.',

        ques4Answer1: 'E-mail veya telefon bilgilerin ile uygulamaya ',
        ques4Answer1Href: 'giriş ',
        ques4Answer1Next: 'yaptıktan sonra ilk olarak istenilen bilgileri eksiksiz doldurmalısın',

        ques4Answer2:
            'Vidieats’in sizinle paylaşacağı önlük, bone ve eldivenleri giyip yüzünüzün ve mutfağınızın fotoğrafını çekin. Profiliniz için en iyi fotoğrafı seçmeye çalışın.',
        ques4Answer3:
            'Müşteriler, siparişlerinin nasıl bir ortamda hazırlandığını görmek isterler. Bu yüzden mutfağınızın resimlerini eklemeyi unutmamalısınız. Bu müşterilerinizin kararını olumlu şekilde etkileyecektir. En iyi fotoğraf için mutfağınızı toparlamayı, doğal ışık kullanmayı ve mümkünse kameranızı yatay şekilde konumlandırmayı unutmayınız.',

        ques4Answer4: 'Kişisel özelliklerini açıklayabileceğin',
        ques4Answer4Href: 'Hakkında ',
        ques4Answer4Next: ' sekmesi ile kendiniz ve mutfağınız hakkında temel bilgiler verebilirsiniz. Mutfağınızın hangi yöreye ait olduğundan,',
        ques4Answer4Href1: ' menünün ',
        ques4Answer4Next1: ' zenginliğine kadar birçok detaydan bahsedebilirsiniz.',

        ques4Answer5:
            'Eğer herhangi bir bankada ticari bir hesabınız yoksa Türkiye Cumhuriyeti Vergi Dairelerinden “Esnaflık Muafiyet Belgesi” alıp uygulamaya yüklemeniz gerekmektedir.',
        ques4Answer6: 'Esnaf muafiyet belgenizi aldıktan sonra istediğiniz bankada ticari hesap açıp hesap dekontunuzu uygulamaya yüklemelisiniz. ',
        ques4Answer7:
            'Mutfağınızın en lezzetli ürünlerini içeren bir menü oluşturmalısınız. Menüyü hazırlarken yemeklerinizi özel kılan ayrıntıları (vejetaryen, deniz, et veya pasta, kek gibi) vurgulamayı unutmayın. Ayrıca yemeklerinizin ana malzemelerini paylaşıp taze olduklarını belirtebilirsiniz. Müşterilerinizin daha kolay karar verebilmesi için siparişlerin ne kadar sürede hazırlanacağını ve en az kaç kişilik olduğunu paylaşabilirsiniz.',
        ques4Answer8: 'Kendi çalışma zaman dilimlerinizi belirleyerek ',
        ques4Answer8Href: ' takviminizi ',
        ques4Answer8Next: 'oluşturmalısınız. Şef olarak istediğiniz zaman diliminde çalışabilirsiniz. Vidieats üzerinden kendi',

        ques4Answer8Next1: ' belirleyerek rezervasyon alabileceğiniz uygun zamanları güncelleyebilirsiniz.',

        ques4Answer9:
            'Ürünleriniz için belirlemiş olduğunuz fiyatları müşterilerinizin beğenisine sunmalısınız. Porsiyon fiyatları için gider tablonuza göre kendi belirlediğiniz fiyatlandırmayı kullanabilirsiniz. Bunun dışında Vidieats’in ortalama taban fiyat, hafta sonu, bayram tatili gibi belirli dönemler için özel fiyat kontrolleri bulunmaktadır. Talep etmeniz halinde, ürünlerinizin fiyatını belirleyebilmeniz için Akıllı Fiyatlandırma araçlarımız ile bu konuda sizlere yardımcı olabiliriz. ',
        ques4Answer10:
            'Yemek siparişinizi belirttiğiniz süre içerisinde hazırlamış olup kuryenin teslim almasını sağlamalısınız. Müşteri-Şef-Kurye akışının zamanında gerçekleşecek olması Vidieats’in taahhüdü olduğu için aldığınız siparişi belirlenmiş sürede hazırlamaya dikkat etmelisiniz. ',
    },
    CourrierRegistrationPage: {
        firstName: 'İsim',
        lastName: 'Soy İsim',
        phone: 'Telefon',
        mail: 'E-mail',
        required: 'Bu alanı doldurun',
        sendForApproval: 'Kayıt Ol',
        courrierRegistrationForm: 'Kurye Kayıt Formu',
        termsOfUse: ' Kullanıcı Sözleşmesi ',
        termsOfUseBefore: '   Üye olmakla ',
        termsOfUseAfter: " ' nı onaylamış olursunuz. ",
        termsOfUse2: ' Aydınlatma Metni ',
        termsOfUseBefore2: 'Kişisel verilerinize dair',
        termsOfUseAfter2: 'için tıklayınız.',
        password: 'Şifre',
        confirmPassword: 'Şifre Tekrar',
        error: 'Bir Sorun Oluştu',
        registerSuccess: 'Kaydınız Oluşturuldu',
        passwordMessage: 'Şifrenizin en az 8 karakter ve en az 1 büyük harf içermesi gerekir',
        passwordError: 'Şifreler uyuşmuyor',
        phoneNumberPlaceHolder: 'Telefon numarası (5XXXXXXXXX)',
    },
    CancelletaionPolicyPage: {
        title: ' Vidieats İptal Politikası',
        subtitle1: 'Müşteri tarafından yapılan iptal işlemlerinde uygulanacak kurallar: ',
        subtitle1FirstDesc:
            ' Sipariş verilip aşçı tarafından onaylandıktan sonraki ilk 15 dakika içerisinde iptal edilirse hiçbir kesinti yapılmadan iade edilir.',
        subtitle1SecondDesc: `Siparişin üzerinden 15 dakika geçmiş ise, şefin yemeği hazırlama süresinin yarısı süreye kadar yapılan iadelerde %50
        kesinti yapılır. (örneğin : Şef yemeği 120 dakikada hazırlıyor olsun, eğer müşteri ilk 15 dakika içerisinde iptal
        ederse kesinti yapılmayacak ancak 15-60 dakika arasında iptal edilirse %50 kesinti yapılır. 60 dakika sonra iptal
        edilirse hiçbir iade yapılmaz.)`,
        subtitle2: ' Aşçı tarafından yapılacak herhangi bir iptal durumunda;',
        subtitle2FirstDesc: 'Aşçı tarafından yapılacak iptallerde ücretin tamamı iade edilir.',
    },
};
