export const GET_PAYOUT_INFO_STARTED = 'GET_PAYOUT_INFO_STARTED';
export const GET_PAYOUT_INFO_SUCCESS = 'GET_PAYOUT_INFO_SUCCESS';
export const GET_PAYOUT_INFO_FAIL = 'GET_PAYOUT_INFO_FAIL';

export const CREATE_PAYOUT_INFO_STARTED = 'CREATE_PAYOUT_INFO_STARTED';
export const CREATE_PAYOUT_INFO_SUCCESS = 'CREATE_PAYOUT_INFO_SUCCESS';
export const CREATE_PAYOUT_INFO_FAIL = 'CREATE_PAYOUT_INFO_FAIL';

export const UPDATE_PAYOUT_INFO_STARTED = 'UPDATE_PAYOUT_INFO_STARTED';
export const UPDATE_PAYOUT_INFO_SUCCESS = 'UPDATE_PAYOUT_INFO_SUCCESS';
export const UPDATE_PAYOUT_INFO_FAIL = 'UPDATE_PAYOUT_INFO_FAIL';
