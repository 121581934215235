import * as actionTypes from './actionTypes';

const initialState = {
    bannerPhoto: null,
    loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BANNER_INFO_STARTED:
            return { ...state, loading: true };
        case actionTypes.GET_BANNER_INFO_SUCCESS:
            return { ...state, bannerPhoto: action.payload.bannerPhoto, loading: !action.payload };
        case actionTypes.GET_BANNER_INFO_FAIL:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default reducer;
