/* eslint-disable sonarjs/no-duplicate-string */
export default {
    general: {
        moreDetail: 'Daha Fazla',
        addtoBasket: 'Sepete Ekle',
        removeMessage: 'Seçtiğiniz yemek başarılı bir şekilde favorilerden kaldırıldı.',
        addedMessage: 'Seçili yemek başarıyla favorilerinize eklendi.',
        notSale: 'satışta değil',
        onSale: 'satışta',
        waiting: 'Onay bekleniyor',
        edit: 'Düzenle',
        min: 'dk',
        portion: 'pors..',
    },
    favourites: {
        title: 'Favoriler',
        emptyFavourite: 'Favori yemeğin yok.',
    },
    notification: {
        title: 'Bildirimler',
        emptyNotif: 'Şu anda bildirim bulunmamaktadır.',
    },
    order: {
        title: 'Siparişler',
        emptyNotif: 'Şu anda bildirim bulunmamaktadır.',
        noOrder: 'Şu anda siparişiniz bulunmamaktadır.',
        today: 'Aktif',
        past: 'Geçmiş',
        future: 'Onay Bekleyen',
        preparing: 'Sipariş Hazırlanıyor',
        onDeliver: 'Kurye Bekleniyor',
        cancelled: 'Sipariş İptal Edildi',
        completed: `Sipariş Tamamlandı`,
        waiting: 'Onay Bekleniyor',
        warningMessage: 'Emin misiniz? Bu işlem geri alınamaz!',
    },
    evaluations: {
        welcome: 'Hoşgeldiniz',
        title: 'Değerlendirmeler',
        emptyEvaluation: 'Hiç değerlendirmeye sahip değilsin.',
    },
    setting: {
        title: 'Kullanıcı Ayarları',
        personalInformation: 'Kişisel Bilgiler',
        paymentMethods: 'Ödeme Yöntemleri',
        notificationSettings: 'Bildirim Ayarları',
        securityPassword: 'Güvenlik/Şifre',
        generalPreferences: 'Genel Tercihler',
        payment: {
            info: 'Ödeme yaparken kullandığınız kartlarınızı burada görüntüleyebilir, dilediğiniz kartı silebilirsiniz.',
            cardName: 'Kart Adı',
            cardNo: 'Card Numarası',
            cardholderName: 'Kartın Üstündeki Ad',
            expirationDate: 'Son kullanma Tarihi',
            paymentCheck: 'Ücretleri ve masrafları ve çevrimiçi deneyimleri onaylıyorum',
            whatsCVC2: 'CVC2 nedir?',
            month: 'Ay',
            year: 'Yıl',
            areYouSure: 'Emin misin ?',
            cancel: 'Vazgeç',
            approve: 'Onayla',
            messages: {
                cardName: 'Lütfen kart adını giriniz!',
                cardNo: 'Lütfen card numarasını giriniz!',
                cardholderName: 'Lütfen kart üzerindeki adı giriniz!',
                month: 'Lütfen Ay giriniz!',
                year: 'Lütfen yıl giriniz!',
            },
            addCard: 'Kart Ekle',
        },
        notification: {
            messages: 'Mesajlar',
            messagesInfo: 'Siparişlerinizin takibi dahil olmak üzere yorumlarınız ve siparişleriniz hakkında mesajlar alın.',
            email: 'E-posta',
            textMessages: 'Metin mesajları',
            browserNotifications: 'Tarayıcı bildirimleri',
            browserNotificationsInfo: 'Tarayıcı dışında masaüstü ve mobil web üzerinden bildirimler alın',
            reminders: 'Hatırlatmalar',
            remindersInfo:
                'Çevrimiçi Deneyim etkinlikleriyle ilgili sipariş hatırlatıcıları, inceleme istekleri, fiyatlandırma bildirimleri ve diğer hatırlatıcıları alın.',
            choosePhone: 'Telefon seç',
            textMessagesTop:
                'SMS kutusunu işaretleyerek, aşağıdaki adrese otomatik olarak gönderilecek promosyon mesajlarını almayı kabul etmiş olursunuz',
            textMessagesBottom:
                "Daha fazla bilgi için, INFO ile 123456'ya kısa mesajla gönderin. Mobil bildirimleri iptal etmek için İPTAL yazarak 123456'ya yanıt verin. Mesaj ve veri ücretleri geçerli olabilir.",
        },
        generalPreference: {
            preferredLanguage: 'Tercihedilen Dil',
            language: 'Dil',
            turkishLira: 'Türk Lirası',
            preferredCurrency: 'Tercihedilen Para Birimi',
            timeZone: 'Saat Dilimi',
            turkish: 'Türkçe',
            messages: {
                preferredLanguage: 'Lütfen tercih ettiğiniz dili seçiniz!',
                preferredCurrency: 'Lütfen tercih ettiğiniz para birimini seçiniz!',
                timeZone: 'Lütfen saat dilimini seçiniz!',
            },
            generalChoices: 'Genel Tercihleriniz',
            generalChoicesInfo:
                'Para biriminizi değiştirdiğinizde Fiyatların görüntülenme şekli de değişir. Ödemelerinizi, ödemelerinizi alma şekliniz ve ödeme tercihleriniz bölümünden değiştirebilirsiniz.',
        },
        adressesTexts: {
            title: 'Adreslerim',
        },
    },
    SignupPage: {
        emailCard: {
            countryRegion: 'Ülke/ Bölge',
            errorMessage: 'Bir şeyler yanlış gitti',
            sign: 'Kaydol',
            login: 'Giriş yap',
            moreOption: 'Daha fazla seçenek',
            haveAccount: 'Hesabınız var mı?',
            forgotPassword: 'Şifremi unuttum',
            firstNameMessage: 'Lütfen adınızı giriniz',
            firstNamePlaceHolder: 'Ad',
            lastNameMessage: 'Lütfen soyadınızı giriniz',
            lastNamePlaceHolder: 'Soyad',
            countryCodeMessage: 'Lütfen ülke kodunuzu girin',
            phoneNumberMessage: 'Lütfen telefon numaranızı girin',
            phoneNumberPlaceHolder: 'Telefon numarası (5XXXXXXXXX)',
            emailMessage: 'Lütfen e-mail adresinizi giriniz',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Şifrenizin en az 8 karakter ve en az 1 büyük harf içermesi gerekir',
            passwordPlaceHolder: 'Şifre',
            passwordAgainMessage: 'Lütfen şifreyi tekrar giriniz',
            passwordAgainPlaceHolder: 'Şifre tekrar',
            passwordError: 'Şifreler uyuşmuyor',
        },
        otpInputCard: {
            sign: 'Kaydol',
            login: 'Giriş yap',
            continue: 'Devam et',
            haveAccount: 'Hesabınız var mı?',
            dontHaveAccount: 'Hesabınız yok mu?',
            registrationCode: 'Lütfen kayıt kodunu giriniz.',
            digitMessage: '*****',
            digitCodeMessage: ' ile biten telefon numaranıza SMS gönderildi. Lütfen 6 haneli kodu giriniz.',
            resendCode: 'Yeniden Gönder',
        },
        signupCard: {
            sign: 'Kaydol',
            login: 'Giriş Yap',
            message: 'Bu e-postaya sahip bir hesap var. Lütfen şifrenizle giriş yapın.',
            text: 'Bildirimleri açmak ister misiniz? Yeni lezzetli ve çevrimiçi deneyimler gibi önemli mesajları kaçırmayın.',
            haveAccount: 'Hesabınız var mı?',
            text1: 'Müşteri olmak için',
        },
    },
    LoginPage: {
        loginCard: {
            login: 'Giriş Yap',
            message: 'Bu e-postaya sahip bir hesap var. Lütfen şifrenizle giriş yapın',
            create: 'Bir tane yarat.',
            emailMessage: 'Lütfen e-mail adresinizi giriniz',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Şifrenizin en az 8 karakter ve en az 1 büyük harf içermesi gerekir',
            passwordPlaceHolder: 'Şifre',
            wrongPassword: 'Hatalı şifre veya e-mail!',
            countryRegion: 'Ülke / Bölge',
            countryCodeMessage: 'Lütfen ülke kodunuzu giriniz',
            phoneNumberMessage: 'Lütfen telefon numaranızı giriniz',
            phoneNumberPlaceHolder: 'Telefon numarası (5XXXXXXXXX)',
            notificationText: 'Bildirimleri açmak ister misiniz? Yeni lezzetli ve çevrimiçi deneyimler gibi önemli mesajları kaçırmayın.',
            continue: 'Devam et',
            textOne: 'Lorem Ipsum is simply dummy text of the printing and typesetting ',
            textTwo: 'Lorem Ipsum is simply dummy text of the printing and',
            haveAccount: 'Hesabınız yok mu?',
            forgotPassword: 'Şifreni mi unuttun ?',
        },
        provider: {
            email: 'E-mail ile devam et',
            apple: 'Apple ile devam et',
            google: 'Google ile devam et',
            sms: 'Sms ile devam et',
            facebook: 'Facebook ile devam et',
        },
    },
};
