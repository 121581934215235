export const GET_SAVED_CARDS_STARTED = 'GET_SAVED_CARDS_STARTED';
export const GET_SAVED_CARDS_SUCCESS = 'GET_SAVED_CARDS_SUCCESS';
export const GET_SAVED_CARDS_FAIL = 'GET_SAVED_CARDS_FAIL';

export const DELETE_SAVED_CARD_STARTED = 'DELETE_SAVED_CARD_STARTED';
export const DELETE_SAVED_CARD_SUCCESS = 'DELETE_SAVED_CARD_SUCCESS';
export const DELETE_SAVED_CARD_FAIL = 'DELETE_SAVED_CARD_FAIL';

export const GET_UPCOMING_PAYMENTS_STARTED = 'GET_UPCOMING_PAYMENTS_STARTED';
export const GET_UPCOMING_PAYMENTS_SUCCESS = 'GET_UPCOMING_PAYMENTS_SUCCESS';
export const GET_UPCOMING_PAYMENTS_FAIL = 'GET_UPCOMING_PAYMENTS_FAIL';

export const GET_COMPLETED_PAYMENTS_STARTED = 'GET_COMPLETED_PAYMENTS_STARTED';
export const GET_COMPLETED_PAYMENTS_SUCCESS = 'GET_COMPLETED_PAYMENTS_SUCCESS';
export const GET_COMPLETED_PAYMENTS_FAIL = 'GET_COMPLETED_PAYMENTS_FAIL';
