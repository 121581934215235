import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    landingMeals: null,
    landingVideo: null,
    location: localStorage.getItem('myLocation') === 'undefined' ? '' : JSON.parse(localStorage.getItem('myLocation')),
    // currentLocation: localStorage.getItem('myCurrent') === 'undefined' ? '' : JSON.parse(localStorage.getItem('myCurrent')),
};

const getLandingMealsStartReducer = (state) => ({
    ...state,
    loading: true,
});

const getLandingMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    landingMeals: action.payload.results,
});

const getLandingMealsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getLandingVideoStartReducer = (state) => ({
    ...state,
    loading: true,
});

const getLandingVideoSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    landingVideo: action.payload,
});

const getLandingVideoFailReducer = (state) => ({
    ...state,
    loading: false,
});

const setLocationSuccessReducer = (state, action) => ({
    ...state,
    location: action.payload,
});

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANDING_MEALS_START:
            return getLandingMealsStartReducer(state);
        case actionTypes.GET_LANDING_MEALS_SUCCESS:
            return getLandingMealsSuccessReducer(state, action);
        case actionTypes.GET_LANDING_MEALS_FAIL:
            return getLandingMealsFailReducer(state);

        case actionTypes.GET_LANDING_VIDEO_START:
            return getLandingVideoStartReducer(state);
        case actionTypes.GET_LANDING_VIDEO_SUCCESS:
            return getLandingVideoSuccessReducer(state, action);
        case actionTypes.GET_LANDING_VIDEO_FAIL:
            return getLandingVideoFailReducer(state);
        case actionTypes.SET_LOCATION_SUCCESS:
            return setLocationSuccessReducer(state, action);

        default:
            return state;
    }
};

export default reducer;
