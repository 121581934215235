/* eslint-disable sonarjs/no-identical-functions */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    meals: null,
    mealsError: '',
    retrievedMeal: null,
    mealCount: null,
    homeFood: null,
    homeFoodCount: null,
    mealsForSearch: null,
    mealsForSearchCount: null,
};

const getMealsReducer = (state) => ({
    ...state,
    loading: true,
});

const getMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    meals: action.payload.results,
    mealCount: action.payload.count,
});

const getMealsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getAllMealsReducer = (state) => ({
    ...state,
    loading: true,
});

const getAllMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    meals: action.payload.results,
    mealCount: action.payload.count,
});

const getAllMealsFailReducer = (state) => ({
    ...state,
    loading: false,
});

// FOR SEARCH
const getAllMealsForSearchReducer = (state) => ({
    ...state,
    loading: true,
});

const getAllMealsForSearchSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    meals: action.payload.results,
    mealCount: action.payload.count,
});

const getAllMealsForSearchFailReducer = (state) => ({
    ...state,
    loading: false,
});
// HOMEFOOD
const getAllHomeFoodsReducer = (state) => ({
    ...state,
    loading: true,
});

const getAllHomeFoodsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    homeFood: action.payload.results,
    homeFoodCount: action.payload.count,
});

const getAllHomeFoodsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const retrieveMealReducer = (state) => ({
    ...state,
    loading: true,
});

const retrieveMealSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    retrievedMeal: action.payload,
});

const retrieveMealFailReducer = (state) => ({
    ...state,
    loading: false,
});

const updateMealReducer = (state) => ({
    ...state,
    loading: true,
});
const updateMealSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const updateMealFailReducer = (state) => ({
    ...state,
    loading: false,
});

const createMealReducer = (state) => ({
    ...state,
    loading: true,
});
const createMealSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const createMealFailReducer = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEALS:
            return getMealsReducer(state);
        case actionTypes.GET_MEALS_SUCCESS:
            return getMealsSuccessReducer(state, action);
        case actionTypes.GET_MEALS_FAIL:
            return getMealsFailReducer(state);

        case actionTypes.GET_ALL_MEALS:
            return getAllMealsReducer(state);
        case actionTypes.GET_ALL_MEALS_SUCCESS:
            return getAllMealsSuccessReducer(state, action);
        case actionTypes.GET_ALL_MEALS_FAIL:
            return getAllMealsFailReducer(state);

        case actionTypes.GET_ALL_MEALS_FOR_SEARCH:
            return getAllMealsForSearchReducer(state);
        case actionTypes.GET_ALL_MEALS_FOR_SEARCH_SUCCESS:
            return getAllMealsForSearchSuccessReducer(state, action);
        case actionTypes.GET_ALL_MEALS_FOR_SEARCH_FAIL:
            return getAllMealsForSearchFailReducer(state);

        case actionTypes.GET_ALL_HOMEFOODS:
            return getAllHomeFoodsReducer(state);
        case actionTypes.GET_ALL_HOMEFOODS_SUCCESS:
            return getAllHomeFoodsSuccessReducer(state, action);
        case actionTypes.GET_ALL_HOMEFOODS_FAIL:
            return getAllHomeFoodsFailReducer(state);

        case actionTypes.RETRIEVE_MEAL:
            return retrieveMealReducer(state);
        case actionTypes.RETRIEVE_MEAL_SUCCESS:
            return retrieveMealSuccessReducer(state, action);
        case actionTypes.RETRIEVE_MEAL_FAIL:
            return retrieveMealFailReducer(state);
        case actionTypes.UPDATE_MEAL:
            return updateMealReducer(state);
        case actionTypes.UPDATE_MEAL_SUCCESS:
            return updateMealSuccessReducer(state);
        case actionTypes.UPDATE_MEAL_FAIL:
            return updateMealFailReducer(state);
        case actionTypes.CREATE_MEAL:
            return createMealReducer(state);
        case actionTypes.CREATE_MEAL_SUCCESS:
            return createMealSuccessReducer(state);
        case actionTypes.CREATE_MEAL_FAIL:
            return createMealFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
