/* eslint-disable import/order */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './presentation/themes/mainTheme';
import Routes from './services/routes';
import { WebSocketComponent } from './services/Websocket/websocket';
import './App.less';
import './i18n';

export default function App() {
    const isLoggedIn = localStorage.getItem('user');

    return (
        <ThemeProvider theme={theme}>
            {isLoggedIn ? <WebSocketComponent /> : null}
            <Routes />
        </ThemeProvider>
    );
}
