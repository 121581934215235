/* eslint-disable sonarjs/no-duplicate-string */
export default {
    general: {
        order: 'Order',
        moreDetail: 'More Detail',
        active: 'Active',

        chefTitle: 'Lorem ipsum dolor sit amet (EN)',
        chefDescription:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.(EN)',
    },
    setting: {
        title: 'Account Settings',
        personalInformation: 'Personal Information',
        paymentMethods: 'Payment Methods',
        notificationSettings: 'Notification Settings',
        securityPassword: 'Security/Password',
        generalPreferences: 'General Preferences',
        payoutMethods: 'My Earnings',
        adresses: 'My Addresses',
        payment: {
            info: 'Set up a payment method Use your payment method to purchase on Yemegim.co',
            cardName: 'Card Name',
            cardNo: 'Card No',
            cardholderName: 'Cardholder Name',
            expirationDate: 'Expiration Date',
            paymentCheck: 'I confirm fees and charges and online experiences',
            whatsCVC2: "What's CVC2?",
            month: 'Month',
            year: 'Year',
            messages: {
                cardName: 'Please input a card name!',
                cardNo: 'Please input the card numbers!',
                cardholderName: 'Please input the cardholder name!',
                month: 'Please select the expiration month!',
                year: 'Please select the expiration year!',
            },
            addCard: 'Add Card',
        },
        payout: {
            info: 'You can see the completed and upcoming payments on Yemegim.co',
            paymentCheck: 'I confirm fees and charges and online experiences',
            shippingOption: 'Shipping Option',
            cardholderName: 'Cardholder Name',
            recipientName: 'Recipient Name Surname/Title:',
            messages: {
                shippingOption: 'Please select a shipping option!',
                iban: 'Please input a IBAN!',
            },

            completedPayments: 'Click For Payments ',
            upcomingPayments: 'Upcoming Payments',
        },
        notification: {
            messages: 'Messages',
            messagesInfo: 'Receive messages about your comments and orders, including tracking your orders.',
            email: 'E-mail',
            textMessages: 'Text Messages',
            browserNotifications: 'Browser notifications',
            browserNotificationsInfo: 'Receive notifications outside the browser via desktop and mobile web',
            reminders: 'Reminders',
            remindersInfo: 'Receive order reminders, review requests, pricing notifications, and other reminders about Online Experience events.',
            choosePhone: 'Choose a Phone',
            textMessagesTop:
                'By checking the SMS box, you agree to receive promotional messages that will be automatically sent to the address below:',
            textMessagesBottom:
                'For more information, send it in a text message to 123456 with INFO. To cancel mobile notifications, reply to 123456 by typing CANCEL. Message and data charges may apply.',
        },
        generalPreference: {
            preferredLanguage: 'Preferred Language',
            language: 'Language',
            turkishLira: 'Turkish Lira',
            preferredCurrency: 'Preferred Currency',
            timeZone: 'Time Zone',
            turkish: 'Turkish',
            messages: {
                preferredLanguage: 'Please select a preferred language!',
                preferredCurrency: 'Please select a preferred currency!',
                timeZone: 'Please select a time zone!',
            },
            generalChoices: 'Your General Choices',
            generalChoicesInfo:
                'When you change your currency The way prices are displayed also changes. The way you receive your payments, payments and You can change it in your payment preferences section.',
        },
    },
    SignupPage: {
        emailCard: {
            countryRegion: 'Country / Region',
            errorMessage: 'Something went wrong',
            sign: 'Sign up',
            login: 'Log in',
            moreOption: 'More option',
            haveAccount: 'Do you have an account?',
            forgotPassword: 'Forgot my password',
            firstNameMessage: 'Please enter your first name',
            firstNamePlaceHolder: 'First name',
            lastNameMessage: 'Please enter your last name',
            lastNamePlaceHolder: 'Last name',
            countryCodeMessage: 'Please enter your country code',
            phoneNumberMessage: 'Please enter your phone number ',
            phoneNumberPlaceHolder: 'Phone number (5XXXXXXXXX)',
            emailMessage: 'Please enter your e-mail',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Your password must contain at least 8 characters and at least 1 uppercase letter',
            passwordPlaceHolder: 'Password',
            passwordAgainMessage: 'Please enter your password again',
            passwordAgainPlaceHolder: 'Password repeat',
            passwordError: 'Passwords dont match',
        },
        otpInputCard: {
            sign: 'Sign up',
            login: 'Log in',
            continue: 'Continue',
            registrationCode: 'Please enter the registration code.',
            digitMessage: 'SMS sent to your phone number ending with *****',
            digitCodeMessage: 'Please enter the 6 digit code.',
            haveAccount: 'Do you have an account?',
            register: "You don't have an account? To register ",
        },
        signupCard: {
            beforeSign: 'For customer',
            sign: 'Sign up',
            login: 'Log in',
            message: 'There exists an account with this email. Please login with your password',
            text: "Turn on notifications? Don't miss important messages like new delicious and online experiences",
            haveAccount: 'Do you have an account?',
            chefContract: 'Chef Contract',
        },
    },
    LoginPage: {
        loginCard: {
            login: 'Log in',
            message: 'There exists an account with this email. Please login with your password',
            create: 'Create one.',
            emailMessage: 'Please enter your e-mail',
            emailPlaceHolder: 'E-mail',
            passwordMessage: 'Your password should be contain least 1 upper letter and least 8 character ',
            passwordPlaceHolder: 'Password',
            wrongPassword: 'Wrong password or e-mail!',
            countryRegion: 'Country / Region',
            countryCodeMessage: 'Please enter your country code',
            phoneNumberMessage: 'Please enter your phone number',
            phoneNumberPlaceHolder: 'Phone number (5XXXXXXXXX)',
            notificationText: "Turn on notifications? Don't miss important messages like new delicious and online experiences",
            continue: 'Continue',
            register: "You don't have an account? ",
            forgotPassword: 'Forgot Password ?',

            textOne: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
            textTwo: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.',
        },
        provider: {
            email: 'Continue with E-mail',
            apple: 'Continue with Apple',
            google: 'Continue with Google',
            sms: 'Continue with Sms',
            facebook: 'Continue with Facebook',
        },
        signupCard: {
            sign: 'Sign up',
            login: 'Log in',
            message: 'There exists an account with this email. Please login with your password',
            text: "Turn on notifications? Don't miss important messages like new delicious and online experiences",
            haveAccount: 'Do you have an account?',
        },
    },
    NotificationPage: {
        notification: 'Notifications',
        noDataText: 'There is no notification at the moment.',
        newNotif: 'New',
        areYouSure: 'Are you sure delete this notification ?',
        approve: 'Approve',
        cancel: 'Cancel',
    },
    ProfilePage: {
        welcome: 'Welcome',
        join: 'Joined in 2021',
        evaluation: 'My Evaluations',
        commentMessage: 'You don`t have any evaluations.',
    },
    MyMenuPage: {
        welcome: 'Welcome',
        welcomeText: 'Which of these sounds most like your meal? Finish your menu to start earning.',
        createMyMenu: 'Create My Menu',
        categories: 'Meal Categories',
        portion: 'Portion',
        price: 'Price',
        time: 'Time (mins)',
        description: 'Meal Description',
        name: 'Meal Name',
        ingredients: 'Ingredients Used in Meal',
        country: 'Country',
        region: 'Region',
        labels: 'Food Labels',
        successMessage: 'Meal sent for approval.',
        errorMessage: 'There was an error sending your request!',
        minimumPrice: 'Min. price is ',
        validTime: '',
        foodPortionQuantityError: '',
        foodPortionQuantityErrorForKg: 'Min. 3',

        noTags: 'Please select at least one food label!',
        noCategoryError: 'Please select a category!',
        noIngredientError: 'Please add at least one ingredient!',
        noImageError: 'Please upload at least one image!',
        requiredError: 'This input is required!',
        ingredientExists: 'You cannot add the same ingredient twice!',
        imgRecommend: 'Yemegim.co recommends uploading horizontal photos for a better view',
        categoryInfo: 'Choose the category that best describes your meal.',
        portionInfo: 'Enter the portion information of your meal.',
        priceInfo: 'Enter the price information for your meal.',
        timeInfo: 'Enter the average time your meal will be ready.',
        mealDescriptionInfo: 'Explain what your customers need to know about your food.',
        mealNameInfo: 'Enter the name of your meal.',
        ingredientsNameInfo: 'Enter the ingredients of your meal.',
        countryInfo: 'Enter the country where your kitchen is located.',
        regionInfo: 'Enter the area where your kitchen is located.',
        foodLabelInfo: 'Select the labels that define your meal.',
    },
    FavouritesPage: {
        name: 'Favourites',
        emptyMessage: 'You don`t have any favourite meals.',
    },
    PaymentsPage: {
        name: 'Payments',
        completed: 'Completed Payments',
        upcoming: 'Upcoming Payments',
        CompletedPayments: {
            method: 'Payment Method:',
            paymentMessage: 'Please select a payment method!',
            paymentPlaceHolder: 'All Payment Methods',
            range: 'Date Range:',
            rangeMessage: 'Please input the date range!',
            paid: 'Paid:',
            download: 'Download CSV',
            year: 'year',
            month: 'month',
        },
        UpcomingPayments: {
            method: 'Payment Method:',
            paymentMessage: 'Please select a payment method!',
            paymentPlaceHolder: 'All Payment Methods',
            range: 'Date Range:',
            rangeMessage: 'Please input the date range!',
            paid: 'Paid:',
            download: 'Receive Payment',
            year: 'year',
            month: 'month',
            warningMessage: 'You have not received any payments for the currently selected dates, sign ups, and payment method.',
        },
        MealTable: {
            id: 'Meal ID:',
            meal: 'Meal:',
            date: 'Date:',
            currency: 'Currency:',
            price: 'Meal Price:',
            fee: 'Yemegim.co Fee:',
            out: 'Paid Out:',
            payment: 'Request Payment',
        },
    },
    SchedulePage: {
        welcome: 'Welcome',
        description: 'When are you available? Complete your programming to start earning.',
        create: 'Create My Schedule',
        Calendar: {
            CalendarHeader: {
                week: 'Week',
                weekly: 'Weekly',
                monthly: 'Monthly',
                info: 'Working hours are 9am to 6pm',
            },
            MonthlyCalendar: {
                Sider: {
                    order: 'You don`t have any orders',
                },
                order: 'Order',
                start: 'Start',
                end: 'End',
            },
            WeeklyCalendar: {
                Footer: {
                    send: 'Send for Approval',
                    wait: 'Pending for Approval',
                    edit: 'Edit',
                    message: 'Submitted for Approval',
                },
                work: 'Working Hours',
                status: 'Order Receiving Status',
            },
        },
    },
    OverviewPage: {
        updateSuccess: 'Meal Updated Successfully, you can see the changes afterb approval.',
        noMenuWarning: 'Welcome to Yemegim.co! To create your menu, you must  ',
        noMenuWarningLink: 'complete your information ',
        noMenuWarning2: 'in the account settings. ',
        createMenuWarning: 'Your account has been created. Now you can create ',
        menuHref: 'your menu ',
        afterHref: 'and start selling.',
        MyMenuPage: {
            menu: 'My Menu',
        },
        MyMenuSettingsPage: {
            welcome: 'Welcome',
            create: 'Create My Menu',
            text: 'Which of these sounds most like your meal? Finish your menu to start earning.',
            CreateMealSection: {
                category: 'Meal Categories:',
                name: 'Meal Name:',
                nameMessage: 'Please input your meal name',
                namePlaceHolder: 'Meal Name',
                country: 'Country:',
                countryMessage: 'Please input your country !',
                region: 'Region:',
                regionMessage: 'Please input your region !',
                foodLabel: 'Food Labels:',
                portion: 'Portion',
                price: 'Price',
                time: 'Time',
                minimumPrice: 'Min. price is',
                priceTl: 'price (TL)',
                timeMins: 'Time (Mins)',
                validTime: 'Enter valid time',
                description: 'Product Description:',
                wait: 'Pending for Approval',
                descriptionMessage: 'Please input your last description!',
                inputPlaceHolder:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation consequat. ',
                ingredient: 'Ingredients:',
                ingredientPlaceHolder: 'Add ingredient',
                add: 'Add',
                edit: 'Edit',
                sendApprove: 'Send for Approval',
                ingredientErrorMessage: 'You cannot add the same ingredient twice!',
                ingredientEmptyMessage: 'Enter ingredient name',
                approveSuccess: 'Product submitted for approval.',
                approveError: 'The product could not be submitted for approval.',
            },
            FoodEditSection: {
                Modal: {
                    successMessage: 'Success',
                    errorMessage: 'Error',
                    recommended: 'Recommend a Tag:',
                    recommended2: 'Recommend a Tag:',
                    send: 'Send',
                    foodLabel: 'Please select a food label',
                    add: 'Add',
                    cancel: 'Cancel',
                },
            },
            // FoodLabel: {},
            // IngredientsLabel: {},
        },
    },
    OrderPage: {
        moreDetail: 'More Detail',
        pending: 'Pending',
        today: 'Active',
        history: 'History',
        allType: 'All Type',
        delivired: 'Order Delivered',
        preparing: 'Preparing',
        cancelled: 'Order Cancelled',
        cancelledByVidieats: 'Cancelled By Yemegim.co',
        onDelivery: 'On Delivery',
        ready: 'Order Ready',
        date: 'Date',
        time: 'Time',
        mealName: 'Meal Name',
        mealPrice: 'Meal Price',
        total: 'Total',
        earning: 'Earning',
        note: 'Note',
        orderApproved: 'Order has been taken',
        rejectOrder: 'Reject Order',
        acceptOrder: 'Accept Order',
        title: 'My Orders',
        future: 'Future',
        myOrder: 'My Order',
        receiveOrder: 'Receive Order',
        orderReady: 'Order Ready',
        waitForCourrier: 'Waiting for courier',
        deliveryCode: 'Confirmation Code:',
        deliveryCodeDesc: 'Determined when the courier picks up the order.',
        sentMngMessage: 'You can deliver your order to the nearest MNG cargo branch with the delivery code',
        orderDeleteModal: {
            modalHeader: 'Are you sure?',
            modalText: 'This action is irreversible!',
        },

        orderDetailModal: {
            sent: 'Sent',
            chef: 'Chef',
            orderNo: 'Order No',
            sendingDate: 'Sending Date',
            sendingTime: 'Sending Time',
            paymentMethod: 'Payment Method',
            address: 'Address',
            comment: 'Comment',
        },
        orderRejectModal: {
            sent: 'Sent',
            info: "Please select one of the following reasons to cancel the person's order.",
            modalTitle: 'Reject Order',
            modalHeader: 'Are you sure?',
            modalText: 'This action is irreversible!',
            cancel: 'Cancel',
            approve: 'Approve',
        },
        callCourrier: 'Call a Courrier',
        vehicleType:
            ' Is your order ready? Please select the vehicle type according to the size of your package. After acceptance, the courier will be notified.',
    },
    storePage: {
        bigTitle: 'Store',
        welcomeText: 'Welcome to Yemegim.co Store',
        description: 'All you need when preparing your delicious orders from each other is in Yemegim.co store.',
    },
    storeItemCard: {
        name: 'Lorem',
        description: ' Lorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod….',
        price: '40',
        current: 'TL',
        quantity: 'Quantity',
        addToBasket: 'Add to basket',
    },
};
