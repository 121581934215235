/* eslint-disable sonarjs/no-identical-functions */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    favMeals: null,
    favMealsError: '',
};

const getFavMealsReducer = (state) => ({
    ...state,
    loading: true,
});

const getFavMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    favMeals: action.payload,
});

const getFavMealsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const updateFavMealReducer = (state) => ({
    ...state,
    loading: true,
});
const updateFavMealSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const updateFavMealFailReducer = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_FAV_MEALS:
            return getFavMealsReducer(state);
        case actionTypes.GET_FAV_MEALS_SUCCESS:
            return getFavMealsSuccessReducer(state, action);
        case actionTypes.GET_FAV_MEALS_FAIL:
            return getFavMealsFailReducer(state);
        case actionTypes.UPDATE_FAV_MEALS:
            return updateFavMealReducer(state);
        case actionTypes.UPDATE_FAV_MEALS_SUCCESS:
            return updateFavMealSuccessReducer(state);
        case actionTypes.UPDATE_FAV_MEALS_FAIL:
            return updateFavMealFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
