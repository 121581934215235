import * as actionTypes from './actionTypes';

const initialState = {
    loading: true,
    items: null,
    itemsCount: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STORE_ITEMS_STARTED:
            return { ...state };
        case actionTypes.GET_STORE_ITEMS_SUCCESS:
            return { ...state, loading: false, items: action.payload.results, itemsCount: action.payload.count };
        case actionTypes.GET_STORE_ITEMS_FAIL:
            return { ...state, loading: false };

        default:
            return state;
    }
};

export default reducer;
