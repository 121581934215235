import * as actionTypes from './actionTypes';

const initialState = {
    info: null,
    loading: true,
    sendingRequest: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PAYOUT_INFO_SUCCESS:
            return { ...state, loading: false, info: action.payload };
        case actionTypes.GET_PAYOUT_INFO_FAIL:
            return { ...state, loading: false };
        case actionTypes.UPDATE_PAYOUT_INFO_STARTED:
            return { ...state, sendingRequest: true };
        case actionTypes.UPDATE_PAYOUT_INFO_SUCCESS:
            return { ...state, sendingRequest: false };
        case actionTypes.UPDATE_PAYOUT_INFO_FAIL:
            return { ...state, sendingRequest: false };
        case actionTypes.CREATE_PAYOUT_INFO_STARTED:
            return { ...state, sendingRequest: true };
        case actionTypes.CREATE_PAYOUT_INFO_SUCCESS:
            return { ...state, sendingRequest: false };
        case actionTypes.CREATE_PAYOUT_INFO_FAIL:
            return { ...state, sendingRequest: false };
        default:
            return state;
    }
};

export default reducer;
