import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    meals: null,
};

const getMealsStartReducer = (state) => ({
    ...state,
    loading: true,
});

const getMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    meals: action.payload.results,
});

const getMealsFailReducer = (state) => ({
    ...state,
    loading: false,
});

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEALS_START:
            return getMealsStartReducer(state);
        case actionTypes.GET_MEALS_SUCCESS:
            return getMealsSuccessReducer(state, action);
        case actionTypes.GET_MEALS_FAIL:
            return getMealsFailReducer(state);

        default:
            return state;
    }
};

export default reducer;
