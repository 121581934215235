// CUSTOMER
export const GET_CUSTOMER_SETTINGS_STARTED = 'GET_CUSTOMER_SETTINGS_STARTED';
export const GET_CUSTOMER_SETTINGS_SUCCESS = 'GET_CUSTOMER_SETTINGS_SUCCESS';
export const GET_CUSTOMER_SETTINGS_FAIL = 'GET_CUSTOMER_SETTINGS_FAIL';

export const UPDATE_CUSTOMER_SETTINGS_STARTED = 'UPDATE_CUSTOMER_SETTINGS_STARTED';
export const UPDATE_CUSTOMER_SETTINGS_SUCCESS = 'UPDATE_CUSTOMER_SETTINGS_SUCCESS';
export const UPDATE_CUSTOMER_SETTINGS_FAIL = 'UPDATE_CUSTOMER_SETTINGS_FAIL';

export const CHANGE_CUSTOMER_PASSWORD_STARTED = 'CHANGE_CUSTOMER_PASSWORD_STARTED';
export const CHANGE_CUSTOMER_PASSWORD_SUCCESS = 'CHANGE_CUSTOMER_PASSWORD_SUCCESS';
export const CHANGE_CUSTOMER_PASSWORD_FAIL = 'CHANGE_CUSTOMER_PASSWORD_FAIL';

// CHEF
export const GET_CHEF_SETTINGS_STARTED = 'GET_CHEF_SETTINGS_STARTED';
export const GET_CHEF_SETTINGS_SUCCESS = 'GET_CHEF_SETTINGS_SUCCESS';
export const GET_CHEF_SETTINGS_FAIL = 'GET_CHEF_SETTINGS_FAIL';

export const UPDATE_CHEF_SETTINGS_STARTED = 'UPDATE_CHEF_SETTINGS_STARTED';
export const UPDATE_CHEF_SETTINGS_SUCCESS = 'UPDATE_CHEF_SETTINGS_SUCCESS';
export const UPDATE_CHEF_SETTINGS_FAIL = 'UPDATE_CHEF_SETTINGS_FAIL';

export const CHANGE_CHEF_PASSWORD_STARTED = 'CHANGE_CHEF_PASSWORD_STARTED';
export const CHANGE_CHEF_PASSWORD_SUCCESS = 'CHANGE_CHEF_PASSWORD_SUCCESS';
export const CHANGE_CHEF_PASSWORD_FAIL = 'CHANGE_CHEF_PASSWORD_FAIL';

export const CHECK_CHEF_SETTINGS_STARTED = 'CHECK_CHEF_SETTINGS_STARTED';
export const CHECK_CHEF_SETTINGS_SUCCESS = 'CHECK_CHEF_SETTINGS_SUCCESS';
export const CHECK_CHEF_SETTINGS_FAIL = 'CHECK_CHEF_SETTINGS_FAIL';

// GET ADDRESSES
export const GET_ADDRESSES_STARTED = 'GET_ADDRESSES_STARTED';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAIL = 'GET_ADDRESSES_FAIL';

// CREATE ADDRESS
export const CREATE_ADDRESS_STARTED = 'CREATE_ADDRESS_STARTED';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAIL = 'CREATE_ADDRESS_FAIL';

// DELETE ADDRESS
export const DELETE_ADDRESS_STARTED = 'DELETE_ADDRESS_STARTED';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

// EDIT ADDRESS
export const EDIT_ADDRESS_STARTED = 'EDIT_ADDRESS_STARTED';
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS';
export const EDIT_ADDRESS_FAIL = 'EDIT_ADDRESS_FAIL';

// SELECTED ADDRESS
export const SELECTED_ADDRESS_STARTED = 'SELECTED_ADDRESS_STARTED';
export const SELECTED_ADDRESS_SUCCESS = 'SELECTED_ADDRESS_SUCCESS';
export const SELECTED_ADDRESS_FAIL = 'SELECTED_ADDRESS_FAIL';

export const GET_SELECTED_ADDRESS_STARTED = 'GET_SELECTED_ADDRESS_STARTED';
export const GET_SELECTED_ADDRESS_SUCCESS = 'GET_SELECTED_ADDRESS_SUCCESS';
export const GET_SELECTED_ADDRESS_FAIL = 'GET_SELECTED_ADDRESS_FAIL';

export const CHEF_FORGOT_PASSWORD_STARTED = 'CHEF_FORGOT_PASSWORD_STARTED';
export const CHEF_FORGOT_PASSWORD_SUCCESS = 'CHEF_FORGOT_PASSWORD_SUCCESS';
export const CHEF_FORGOT_PASSWORD_FAIL = 'CHEF_FORGOT_PASSWORD_FAIL';

export const CUSTOMER_FORGOT_PASSWORD_STARTED = 'CUSTOMER_FORGOT_PASSWORD_STARTED';
export const CUSTOMER_FORGOT_PASSWORD_SUCCESS = 'CUSTOMER_FORGOT_PASSWORD_SUCCESS';
export const CUSTOMER_FORGOT_PASSWORD_FAIL = 'CUSTOMER_FORGOT_PASSWORD_FAIL';

export const GET_PREFERENCES_STARTED = 'GET_PREFERENCES_STARTED';
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const GET_PREFERENCES_FAIL = 'GET_PREFERENCES_FAIL';

export const UPDATE_PREFERENCES_STARTED = 'UPDATE_PREFERENCES_STARTED';
export const UPDATE_PREFERENCES_SUCCESS = 'UPDATE_PREFERENCES_SUCCESS';
export const UPDATE_PREFERENCES_FAIL = 'UPDATE_PREFERENCES_FAIL';

// DELETE USER
export const DELETE_USER_STARTED = 'DELETE_USER_STARTED';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

// NEW (27.12)
export const CHEF_FORGOT_PASSWORD_NEW_STARTED = 'CHEF_FORGOT_PASSWORD_NEW_STARTED';
export const CHEF_FORGOT_PASSWORD_NEW_SUCCESS = 'CHEF_FORGOT_PASSWORD_NEW_SUCCESS';
export const CHEF_FORGOT_PASSWORD_NEW_FAIL = 'CHEF_FORGOT_PASSWORD_NEW_FAIL';

export const CUSTOMER_FORGOT_PASSWORD_NEW_STARTED = 'CUSTOMER_FORGOT_PASSWORD_NEW_STARTED';
export const CUSTOMER_FORGOT_PASSWORD_NEW_SUCCESS = 'CUSTOMER_FORGOT_PASSWORD_NEW_SUCCESS';
export const CUSTOMER_FORGOT_PASSWORD_NEW_FAIL = 'CUSTOMER_FORGOT_PASSWORD_NEW_FAIL';
