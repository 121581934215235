import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    contracts: null,
};

const getContractsStartedReducer = (state) => ({
    ...state,
    loading: true,
});

const getContractsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    contracts: action.payload.results,
});

const getContractsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONTRACTS_STARTED:
            return getContractsStartedReducer(state);
        case actionTypes.GET_CONTRACTS_SUCCESS:
            return getContractsSuccessReducer(state, action);
        case actionTypes.GET_CONTRACTS_FAIL:
            return getContractsFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
