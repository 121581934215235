import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    comments: null,
};

// GET
const getCommentsStartedReducer = (state) => ({
    ...state,
    loading: true,
});
const getCommentsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    comments: action.payload.results,
});
const getCommentsFailReducer = (state) => ({
    ...state,
    loading: false,
});
// DELETE
const deleteCommentsStartedReducer = (state) => ({
    ...state,
    loading: true,
});
const deleteCommentsSuccessReducer = (state) => ({
    ...state,
    loading: false,
});
const deleteCommentsFailReducer = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMMENTS_STARTED:
            return getCommentsStartedReducer(action);
        case actionTypes.GET_COMMENTS_SUCCESS:
            return getCommentsSuccessReducer(state, action);
        case actionTypes.GET_COMMENTS_FAIL:
            return getCommentsFailReducer(state);
        case actionTypes.DELETE_COMMENT_STARTED:
            return deleteCommentsStartedReducer(action);
        case actionTypes.DELETE_COMMENT_SUCCESS:
            return deleteCommentsSuccessReducer(state);
        case actionTypes.DELETE_COMMENT_FAIL:
            return deleteCommentsFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
