export const GET_BASKET_LIST_STARTED = 'GET_BASKET_LIST_STARTED';
export const GET_BASKET_LIST_SUCCESS = 'GET_BASKET_LIST_SUCCESS';
export const GET_BASKET_LIST_FAIL = 'GET_BASKET_LIST_FAIL';

export const DELETE_BASKET_LIST_STARTED = 'DELETE_BASKET_LIST_STARTED';
export const DELETE_BASKET_LIST_SUCCESS = 'DELETE_BASKET_LIST_SUCCESS';
export const DELETE_BASKET_LIST_FAIL = 'DELETE_BASKET_LIST_FAIL';

export const UPDATE_BASKET_LIST_STARTED = 'UPDATE_BASKET_LIST_STARTED';
export const UPDATE_BASKET_LIST_SUCCESS = 'UPDATE_BASKET_LIST_SUCCESS';
export const UPDATE_BASKET_LIST_FAIL = 'UPDATE_BASKET_LIST_FAIL';

export const SET_GUEST_BASKET = 'SET_GUEST_BASKET';

export const GET_PROMOTION_LIST_STARTED = 'GET_PROMOTION_LIST_STARTED';
export const GET_PROMOTION_LIST_SUCCESS = 'GET_PROMOTION_LIST_SUCCESS';
export const GET_PROMOTION_LIST_FAIL = 'GET_PROMOTION_LIST_FAIL';

export const APPROVE_PROMOTION_STARTED = 'APPROVE_PROMOTION_STARTED';
export const APPROVE_PROMOTION_SUCCESS = 'APPROVE_PROMOTION_SUCCESS';
export const APPROVE_PROMOTION_FAIL = 'APPROVE_PROMOTION_FAIL';

export const DELETE_PROMOTION_STARTED = 'DELETE_PROMOTION_STARTED';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_FAIL = 'DELETE_PROMOTION_FAIL';
