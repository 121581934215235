import * as actionTypes from './actionTypes';

const initialState = {
    url: null,
    hash: null,
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAYMENT_STARTED:
            return { ...state, loading: true };
        case actionTypes.PAYMENT_SUCCESS:
            return { ...state, url: action.payload.url, hash: action.payload.hash, loading: !action.payload };
        case actionTypes.PAYMENT_FAIL:
            return { ...state, loading: false };
        case actionTypes.CLEAN_PAYMENT_INFO:
            return { ...state, url: null, hash: null };
        default:
            return state;
    }
};

export default reducer;
