import * as actionTypes from './actionTypes';

const removeItemFromSelectedLabels = (array, obj) => array.filter((item) => item.id !== obj.id);

const initialState = {
    selectedTags: [],
    tagsFromAPI: null,
    tagsFromAPIerror: null,
};

const setSelectedFoodLabelsReducer = (state, action) => ({
    ...state,
    selectedTags: action.payload,
});
const deleteSelectedFoodLabelsReducer = (state, action) => ({
    ...state,
    selectedTags: removeItemFromSelectedLabels(state.selectedTags, action.payload),
});

const getFoodLabelsSuccessReducer = (state, action) => ({
    ...state,
    tagsFromAPI: action.payload.results,
});

const getFoodLabelsFailReducer = (state, action) => ({
    ...state,
    tagsFromAPIerror: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_FOOD_LABELS:
            return setSelectedFoodLabelsReducer(state, action);
        case actionTypes.DELETE_SELECTED_FOOD_LABELS:
            return deleteSelectedFoodLabelsReducer(state, action);
        case actionTypes.GET_SELECTED_FOOD_LABELS_SUCCESS:
            return getFoodLabelsSuccessReducer(state, action);
        case actionTypes.GET_SELECTED_FOOD_LABELS_FAIL:
            return getFoodLabelsFailReducer(state, action);
        case actionTypes.RESET_SELECTED_FOOD_LABELS:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
