import styled from 'styled-components';
import { between } from 'polished';
import { Row } from 'antd';
import { Text } from '../../../components/Typography/styles';

export const Title = styled(Text)`
    font-size: ${between('21px', '30px')} !important;
    margin-bottom: 14px;
`;

export const Description = styled(Text)`
    margin-bottom: 40px;
`;

export const VideosContainer = styled(Row)`
    margin-bottom: 60px;
    margin-top: 20px;
    width: 100%;
`;
export const VideoTextArea = styled(Row)`
    background-color: #328d63;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    .padding-text {
        padding: 10px 20px;
    }
    width: 100%;

    video {
        outline: none !important;
        border-radius: 15px;
        /* border-top-right-radius: 15px; */
        object-fit: unset;
    }
`;
export const VideoStarter = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(5, 2, 1, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
`;
export const VideoPlayIcon = styled.img`
    opacity: none !important;
`;

export const BreadcrumbWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 3%;
    :hover {
        cursor: pointer;
    }
`;
