import * as actions from './actions';

export const getHeaderInfo = () => async (dispatch, getState, service) => {
    try {
        dispatch(actions.getHeaderInfoStartedAction());
        const result = await service({
            method: 'GET',
            path: 'webpage/header/1/',
        });
        dispatch(actions.getHeaderInfoSuccessAction(result));
    } catch (error) {
        dispatch(actions.getHeaderInfoFailAction());
    }
};
