import { getYear } from 'date-fns';

/* eslint-disable sonarjs/no-duplicate-string */
export default {
    searchbar: {
        mobileWarning: 'Download our mobile app for a better experience',
        placeHolder: 'What do you want to eat?',
        location: 'Location',
        headerPlaceholder: 'Food Name',
        addAddress: 'Add Address',
        nextButtonText: 'Next',
        backButtonText: 'Back',
        lastButtonText: `Let's Go!`,
        step1Text: ` Welcome to Yemegim.co!`,
        step2Text: ` Enter your location first, or you can search by city or by food name.`,
        step3Text: ` You can explore the food here.`,
        step4Text: ` You can see the most popular dishes in your area here. Would you like to have your food?`,
        step5Text: ` To win, you can first fill in your information from the 'Settings' option and then create your menu from the 'My Menu' option.`,
    },
    general: {
        currentAddres: 'Make current Address',
        addAddress: 'Add address',
        shortName: 'Name',
        delete: 'Delete',
        tooltip: 'Enter your address to deliver your Yemegim.co orders to you',
        areYouSure: 'Are You Sure ?',
        approve: 'Approve',
        shortDesc: 'Door and building number, directions',
        success: 'Success',
        error: 'Error',
        giveAName: 'Short name',
        region: 'Region',
        description: 'Address Detail',
        title: 'Edit photo',
        yes: 'Yes',
        no: 'No',
        open: 'Open',
        closed: 'Closed',
        save: 'Save',
        cancel: 'Cancel',
        areYouSureText: 'Are you sure you want to use this location?',
        required: 'This area is required!',
        commentButton: 'Would you like to comment?',
        createMenu: 'Create My Menu',
        createSchedule: 'Create My Schedule',
        evaluation: 'My Evaluations',
        minute: 'Min',
        portion: 'Portion',
        addtoBasket: 'Add to Basket',
        viewProfile: 'View Profile',
        moreDetails: 'More Details',
        detail: 'Detail',
        sign: 'Sign In',
        editDescription: 'You can add a description about yourself from the settings',
        seeProfile: 'See Profile',
        edit: 'Edit',
        sendForApproval: 'Send for Approval',
        waitForApproval: 'Wait For Approval',
        add: 'Add',
        eMail: 'E-mail',
        verifyEmail: 'Verify your e-mail',
        phoneNumber: 'Phone Number',
        verifyPhoneNumber: 'Verify your phone number',
        firstName: 'First Name',
        lastName: 'Last Name',
        fullName: 'Full Name',
        birthday: 'Birthday',
        address: 'Address',
        city: 'City',
        district: 'District',
        postCode: 'Post Code',
        emergencyContact: 'Emergency Contact',
        emergencyContactName: 'Emergency Contact Name',
        country: 'Country',
        male: 'Male',
        female: 'Female',
        profilePicture: 'Profile Picture',
        upload: 'Upload',
        gender: 'Gender',
        aboutMe: 'About Me',
        descriptionWarn: 'This field is required',
        id: 'Identity Number and Document',
        addFile: 'Add File',
        noFileWarning: 'To create your menu, you must complete your information in the account settings.',
        ibanNo: 'Iban No',
        confirmedBy: 'Confirmed By ',
        ageError: 'You must be over 18 to become a chef',
        idError: 'Citizenship Number is required',
        idNumber: 'Citizenship Number',
        infoMessage: 'Your information was last updated at',
        maps: {
            mapButton: 'Open Map',
            okText: 'Ok',
            cancelText: 'Cancel',
            chooseAddress: 'Choose Your Location',
            loadingText: 'Location is Loading',
            mapInfo: 'Enter the address of your kitchen',
        },
        order: {
            title: 'Orders',
            emptyNotif: 'You have no any notification.',
            noOrder: 'You have no any order.',
            today: 'Today',
            past: 'Past',
            future: 'Future',
            preparing: 'Order preparing',
            cancelled: 'Order canceled',
            completed: `Order done`,
            waiting: 'Wait for approval',
            warningMessage: 'Are you sure? This process cannot be undone!',
        },
        culinaryCertificate: 'Culinary Certificate',
        tradesmanExemptionCertificate: 'Tradesman Exemption Certificate or Commercial Registration Certificate',
        messages: {
            eMail: ['Please provide a valid e-mail!', 'Please input your e-mail!'],
            phoneNumber: 'Please input a phone number!',
            firstName: 'Please input your first name!',
            lastName: 'Please input your last name!',
            birthday: 'Please select a birthdate!',
            address: 'Please input your address!',
            addressDescription: 'This area is required',

            fullName: 'Please input a name and surname!',
            city: 'Please input a city!',
            district: 'Please input a district!',
            shortDesc: 'Please input a descriptioin!',
            postCode: 'Please input a post code!',
            emergencyContact: 'Please input a contact!',
            emergencyContactName: 'Please input a contact name!',
            country: 'Please select a country!',
            profilePicture: 'Please upload photo!',
            aboutMe: 'Please describe yourself!',
            gender: 'Please select a gender!',
            favMeal: 'You must login to your account in order to like this meal!',
            loginToPurchase: 'You must login to your account in order to complete your order!',
            newNotification: 'You have a new notification!',
            newOrder: 'You have a new order!',
            infoSuccessMessage: 'Changes are sent for approval!',
            customerInfoChanged: 'Changes are saved successfully!',
            passwordChangedSuccessMessage: 'Password is changed successfully!',
            passwordChangedErrorMessage: 'An error occurred while changing the password!',
        },
    },
    security: {
        password: 'Current Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        forgotPassword: 'Forgot Password?',
        updatePassword: 'Update Password',
        socialAccounts: 'Social Accounts',
        approve: 'Approve',
        giveUp: 'Cancel',
        successMessage: 'Successfully Deleted',

        areYouSure: 'Are you sure delete this account ?',
        deleteMyAccount: 'Delete My Account',
        messages: {
            password: 'Please input the old password',
            newPassword: 'Please input a new password!',
            confirmNewPassword: 'Please input the new password!',
        },
        connected: 'Connected',
        notConnected: 'Not Connected',
        notification: 'Your transaction has been approved.',
        description: 'Thank you for choosing us.',
        mealError: 'Please order all meals from the same chef!',
        delivery: 'Discover Now',
        becomeACourrier: 'Become a Courier',
        all: 'All',
        chefs: 'chefs',
        viewProfile: 'View Profile',
        moreDetails: 'More Details',
        portion: 'Portions',
        minute: 'Minutes',
        addtoBasket: 'Add to Basket',
    },
    layout: {
        header: {
            navItemGuest: [
                {
                    id: 1,
                    title: 'HomeFood',
                    path: '/homefood',
                },
                // {
                //     id: 1,
                //     title: 'About Us',
                //     path: '/about',
                // },
                {
                    id: 2,
                    title: 'Become a chef',
                    path: '/become-a-chef',
                },
                {
                    id: 3,
                    title: 'Become a courier',
                    path: '/become-a-courier',
                },
            ],
            navItemCustomer: [
                {
                    id: 1,
                    title: 'HomeFood',
                    path: '/homefood',
                },
                // {
                //     id: 1,
                //     title: 'About Vidieats',
                //     path: '/about',
                // },
                {
                    id: 2,
                    title: 'Become a chef',
                    path: '/become-a-chef',
                },
                {
                    id: 3,
                    title: 'Become a courier',
                    path: '/become-a-courier',
                },
            ],
            navItemChef: [
                {
                    id: 1,
                    title: 'HomeFood',
                    path: '/homefood',
                },
                {
                    id: 2,
                    title: 'Yemegim.co Store',
                    path: '/store',
                },
                {
                    id: 3,
                    title: 'Become a courier',
                    path: '/become-a-courier',
                },
            ],
            headerPopup: {
                overview: 'My Menu',
                notification: 'Notifications',
                order: 'Orders',
                favourite: 'Favourites',
                myBasket: 'My Basket',
                setting: 'Settings',
                helpCenter: 'Help Center',
                logout: 'Logout',
                login: 'Log In',
                myProfile: 'My Profile',
                register: 'Register',
                becomeAchef: 'For Chefs',
            },
            headerHamburgerForChefs: {
                overview: 'My Menu',
                notification: 'Notifications',
                order: 'Orders',
                favourite: 'Favourites',
                myBasket: 'My Basket',
                setting: 'Settings',
                helpCenter: 'Help Center',
                logout: 'Logout',
                login: 'Log In',
                myProfile: 'My Profile',
                register: 'Register',
                becomeAchef: 'For Chefs',
                becomeACourrier: 'Become A Courier',
                vidiStore: 'Yemegim.co Mağaza',
                homeFood: 'HomeFood',
            },
            headerHamburgerForGuests: {
                becomeAchef: 'For Chefs',
                becomeACourrier: 'Become A Courier',
                logout: 'Logout',
                about: 'About Yemegim.co',
                register: 'Register',
                login: 'Log In',
                helpCenter: 'Help Center',
                becomeAchef2: 'Become A chef',
            },
            headerHamburgerForCustomers: {
                becomeACourrier: 'Become A Courier',
                logout: 'Logout',
                about: 'About Yemegim.co',
                register: 'Register',
                login: 'Log In',
                helpCenter: 'Help Center',
                becomeAchef2: 'Become A chef',
                myProfile: 'My Profile',
                notification: 'Notifications',
                order: 'Orders',
                favourite: 'Favourites',
                myBasket: 'My Basket',
                setting: 'Settings',
            },
        },
        footer: {
            titleItem: [
                {
                    id: 1,
                    title: 'ABOUT',
                    datas: [
                        {
                            id: 1,
                            name: 'Why Yemegim.co?',
                            path: '/about',
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'HOST',
                    datas: [
                        {
                            id: 2,
                            name: 'Explore',
                        },
                        {
                            id: 3,
                            name: 'Order',
                        },
                        {
                            id: 4,
                            name: 'My Meals',
                        },
                        {
                            id: 5,
                            name: 'In Box',
                        },
                        {
                            id: 6,
                            name: 'Build Your Kitchen',
                        },
                        {
                            id: 7,
                            name: 'Join an Online Experience',
                        },
                        {
                            id: 8,
                            name: 'Become Courier',
                        },
                    ],
                },
                {
                    id: 3,
                    title: 'FOR YOUR INFORMATION',
                    datas: [
                        {
                            id: 9,
                            name: 'For Guest',
                        },
                        {
                            id: 10,
                            name: 'For Chefs',
                        },
                        {
                            id: 11,
                            name: 'Cancellation Policy',
                        },
                        {
                            id: 12,
                            name: 'Privacy and Cookie Policy',
                            path: '/privacy',
                        },
                        {
                            id: 13,
                            name: 'Protection of Data',
                            path: '/protection-of-data',
                        },
                        {
                            id: 14,
                            name: 'KVKK',
                        },
                        {
                            id: 15,
                            name: 'User Agreement',
                            path: '/user-agreement',
                        },
                    ],
                },
                {
                    id: 4,
                    title: 'SUPPORT',
                    datas: [
                        {
                            id: 16,
                            name: 'Community Center',
                        },
                        {
                            id: 17,
                            name: 'Resource Center',
                        },
                        {
                            id: 18,
                            name: 'Press',
                        },
                    ],
                },
            ],
            footerBottom: {
                title: `© ${getYear(new Date())} Yemegim.co · Privacy · Terms · Sitemap`,
                language: 'English (US)',
            },
        },
        BasketPage: {
            title: 'My Basket',
            order: 'Order',
            continueShopping: 'Continue Shopping',
            description: 'To complete your shopping faster',
            basketEmptyDescription: 'There are no items in your basket.',
            orderSummary: 'Order Summary',
            productTotal: 'Product Total',
            cargo: 'Cargo',
            priceText: 'Total Price',
            discount: 'Discount',
            completeShopping: 'Complete Shopping',
            noAdressWarning: 'Address information required to continue shopping',
            agreementHref: 'Sales ',
            agreement1: ' Contract',
            seller: 'Seller: ',
            deliveryDate: 'Delivery Date: ',
            emptyBasket: 'Your basket is empty.',
            delete: 'Are you sure you want to delete this food?',
            store: 'Yemegim.co Store',
            gotoAddress: 'Click to change',
            currentAddres: 'Current address',
            promotionCode: 'Select Promotion Code',
            deleteCode: 'Delete',
            apply: 'Apply',
        },
        EmailVerifyPage: {
            seccessVerify:
                'Hi, \nThank You, your email address was successfully verified. Your account is now active. \nLets! Now you can serve delicious food.',
            errorVerify: 'Hi, \nYour email address could not verified. \nAfter confirming your mail, you can order delicious food.',
            discoverNow: 'Discover Now',
            verified: 'Email Verified',
            error: 'Error',
        },
        LandingPage: {
            dummyDataInfoSection: {
                howItWork: 'HOW IT WORKS',
                globalLocal: 'You can find global & local food wherever you are in',
                desicionMeal: 'After deciding your favorite meal, create an order with time schedule',
                specialRequest: 'Also you can contact to our chefs for your special requests',
                deliverCook: 'You can track your order while we deliver',

                tasteWant: 'TASTE WHATEVER YOU WANT',
                meat: 'Meat',
                seaFood: 'Seafood',
                vegan: 'Vegan',
                dessert: 'Dessert',
                cake: 'Cake',
                special: 'Special',
            },
            BannerSection: {
                title: 'Discover tasty foods nearby',
            },
            VideoSection: {
                title: 'JOIN MILLIONS OF CHEFS ON Yemegim.co',
            },
            FoodCardPreview: {
                detail: 'Details',
                km: ' km ',
            },
        },
        NearPlacesPage: {
            name: 'Explore',
            country: 'Countries',
            mile: 'Mile',
            price: 'Price',
            km: ' Km ',
            foodType: 'Food Type',
            writeFoodName: 'Write food name',
            noMealWarning: 'This meal cannot be found',
            noCityWarning: 'We will be here soon',
            noCategoryWarning: 'There are no products that belong to the category',
            success: 'Selected meal successfully added to your favourites.',
            fail: 'Selected meal successfully removed from your favourites.',
            region: 'Regions',
            all: 'All',
            foodTypesMeat: 'Meat',
            foodTypesSeafood: 'Seafood',
            foodTypesVegan: 'Vegan',
            foodTypesDessert: 'Dessert',
            foodTypesCake: 'Cake',
            foodTypesSpecial: 'Special',
            vidiFood: 'HomeFood',
        },
        MealChoicePage: {
            back: 'Back',
            foodTitle: 'Food of Your Choice',
            chefTitle: 'Other Chefs Doing the Same Food',
            emptyChefMessage: 'There is no other chef making this dish',
        },
        MealModal: {
            message: 'You can only order during the hours the chef works.',
            date: 'Date',
            time: 'Time',
            note: 'Notes',
            now: 'Now',
            future: 'Order for Future Date',
            addtoBasket: 'Add to Basket',
            timePlaceHolder: 'Select Time',
            datePlaceHolder: 'Select Date',
            notePlaceHolder: 'Enter the note',
            ownMealFail: 'You cannot add your own meal to basket!',
            fillfieldFail: 'Please fill in all fields!',
            chefError: 'You cannot add your own meal to basket!',
            basketError: 'You cannot order meals from different chefs at once!',
            chefBasketError: 'You cannot have meal and store products in your basket!',
        },
        PreviewUserProfilePage: {
            myMenu: 'My Menu',
            profileCardDescription: 'You can add a description about yourself from the settings.',
            scheduleOfChef: "Chief's Business calendar",
            emptyMessage: "The chief hasn't set his agenda yet!",
            startDate: 'Start Date',
            endDate: 'End Date',
            days: 'Days',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
    },
    FoodDetailPage: {
        back: 'Back',
        addtoBasket: 'Add to Basket',
        ingredient: 'Ingredients:',
        text: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.. Save the Date: 23.02.2021',
        minute: 'mins',
        portion: 'portions',
        TextSection: {
            comment: 'Add Comment',
            rate: 'Rating:',
            meal: 'Meal:',
            service: 'Service:',
            quality: 'Quality:',
            yourComment: 'Your Comment:',
            send: 'Send',
            inputComment: 'Please input your comment!',
            commentReceived: 'Your comment has been received, you will be able to see it after approval.',
        },
    },
    PaymentPage: {
        successContentUp: 'Thanks. Your order was successfully received. The chief will get back to you as soon as possible.',
        successContentDown: "Come on, let's go! Keep exploring delicious food.",
        buttonText: 'Home Page',
        paymentSuccess: 'Payment Success',
        failContentUp: "Ops.. Something's wrong with your payment. Would you please check his information?",
        failContentDown: "Come on, let's go! Keep exploring delicious food.",
        paymentFail: 'Payment Fail',
    },
    HelpCenterPage: {
        back: 'Back',
        title: 'Help Center',
        placeholder: 'What can we help you with?',
        faqs: 'Frequently Asked Questions',
        firstButton: 'What is Yemegim.co?',
        secondButton: 'Courier Service',
        thirdButton: 'Membership',
        fourthButton: '0850 888 8434',
        answer: 'All questions and their answers',
        firstHeader: 'What is Yemegim.co?',
        question1: 'What is Yemegim.co?',
        question2: 'How does Yemegim.co work?',
        question3: 'How can I contact Yemegim.co?',
        question4: 'I want to cooperate with Yemegim.co!',
        question5: 'Does Vidieats impose sales tax?',
        question6: 'Who can I contact for additional questions?',
        contact: 'Communication',
        address: 'Address:',
        fullAddress: 'Nispetiye Mahallesi, Nispetiye Caddesi, No: 23/17, Beşiktaş/İstanbul',
        email: 'E-mail:',
        forDelivery: 'For Delivery:',
        deliveryMail: 'teslimatdestek@vidieats.com',
        forPayment: 'For Payments:',
        paymentMail: 'ödemedestek@vidieats.com',
        forTechnical: 'For Technical Issues: ',
        technicalMail: 'teknikdestek@vidieats.com',
        forOther: 'For Other Subjects: ',
        otherMail: 'destek@vidieats.com',
        phone: 'Phone:',
        phoneNumber: '0850 888 84 34',

        header1: 'About the App ',
        header2: 'Order Management ',
        header3: 'About the Delivery ',
        header4: 'Payments & Discounts ',
        header5: 'About the Chef ',
        header6: 'About the Courier ',
        header7: 'Other Subjects ',

        header1Desc1: 'How do I download the app? ',
        header1Desc2: 'How do I choose a meal? ',
        header1Desc3: 'How do I add to my cart? ',
        header1Answ1:
            'After logging into the App Store page to download the iOS app or the Google Play Store page to download the Android app, you can select the Yemegim.co app and start downloading. ',
        header1Answ2: 'After logging into the application, you can choose the food you want by filtering from the explore area. ',
        header1Answ3:
            'After reviewing the food you have chosen, you can complete your adding process by entering the number of servings, delivery date and additional notes if you wish, on the screen that appears when you click Add to Cart. ',

        header2Desc1: 'Does the person ordering need to be at home to receive the food?',
        header2Desc2: 'Where can I find my order tracking information? ',
        header2Desc3: 'Can I cancel my order after payment is received? ',
        header2Desc4: 'What should I do when I receive the wrong order? ',
        header2Desc5: 'What should I do if I am not satisfied with the order I received? ',

        header2Answ1:
            'One of the individuals at home other than the person who ordered can receive the food. You can contact us from the Contact section when you need to change your address or have any questions about delivery.  ',
        header2Answ2:
            'You will be informed by the application when your order starts to be prepared by the chef and the preparation is completed and the courier begins the transport process. In addition, you can track your order instantly from the "Where is my order?" section within the application. Depending on the region where the delivery address is located, the transportation process may take 15-45 minutes. ',
        header2Answ3:
            'Before your order cancellation period expires, "Cancel-Change" options will be active on your orders page. You can cancel or make changes to your order until the chef starts to prepare your meal (you will be notified via the app when your meal starts to be prepared). For cancellations made 15 minutes after placing an order, a 15% to 30% deduction will be made from the amount paid (depending on the ingredients used in the order). Once your food is ready for transport, "Cancel-Change" operations will not be performed. Therefore, if you have any questions, you can contact us as soon as possible so that we can help you. ',
        header2Answ4:
            'It is really important for us that you have a great experience from entering the application until you reach the flavor you are looking for. The most important stage of this is that the food you order reaches you. If you think you have not received the right food, you can contact us immediately. We will be happy to deliver the food you are looking for by completing the necessary exchange-return procedures. ',
        header2Answ5:
            'It is our priority that you have an excellent experience and that you are satisfied with your order. Therefore, if you are not satisfied with your order or have any problems, please contact us. ',

        header3Desc1: 'Where can I order food from? ',
        header3Desc2: 'How much shipping fee do I pay? ',
        header3Desc3: 'Who will do the delivery? ',
        header3Desc4: 'How long is the delivery time? ',

        header3Answ1: 'It is possible to order from all chefs located 15 km from your location. ',
        header3Answ2:
            'If the distance between the chef and the area where the food will be transported is less than 5 km, you will not pay an additional courier fee other than the food fee. In case the transportation distance is between 5 and 15 km, a courier fee between 3 and 8 TL can be added depending on the distance and the amount of food. During the purchasing process, the transportation fee will be presented to your information. ',
        header3Answ3:
            'In order for your food to arrive fresh and fast, transportation is carried out by our couriers registered in the Yemegim.co courier application. ',
        header3Answ4:
            "Depending on the distance between the chef and the address where the delivery will be made, the delivery time may take 15-60 minutes. \nImportant note: Chefs' meal preparation times are not included in the delivery time. While making your food selection, the chefs' time to prepare the order is also indicated on the selection screen. This period varies according to the type and amount of food. ",

        header4Desc1: 'What types of payments are accepted? ',
        header4Desc2: 'Are there any discount campaigns? ',

        header4Answ1: 'You can make online payments with debit or credit cards (MasterCard, Visa, American Express, etc.) created by banks. ',
        header4Answ2:
            'Discount campaigns are organized for students and for all users at certain times of the year through the Yemegim.co application. In order to be aware of these campaigns, you must have given us permission to contact you in the application terms of use. ',

        header5Desc1: 'What should I do to become a Chef? ',
        header5Desc2: 'Can I create the menu I want? ',
        header5Desc3: 'What times can I work? ',
        header5Desc4: 'Can I set the price I want for the food on my menu? ',
        header5Desc5: 'Can I refuse to accept the order created by the customer when I receive it? ',
        header5Desc6: "What if I can't get the food ready on time after accepting an order? ",
        header5Desc7: 'How much tax do I pay as a result of sales transactions? ',
        header5Desc8: 'Why should I be a chef at Yemegim.co? ',

        header5Answ1:
            '•  To join the world of chefs of Yemegim.co, you must fill in the information in the Become a Chef section, taking into account the following points.',
        header5Answ2:
            '•  After logging into the application with your e-mail or phone information, you must first fill in the required information. ',
        header5Answ3:
            '•  Put on the apron, cap and gloves that Yemegim.co will share with you and take a photo of your face and kitchen. Try to choose the best photo for your profile. ',
        header5Answ4:
            "•  Customers want to see the environment in which their orders are prepared. That's why you should not forget to include pictures of your kitchen. This will positively influence your customers' decision. For the best photo, don't forget to tidy up your kitchen, use natural light, and position your camera horizontally if possible.",
        header5Answ5:
            '•  If you do not have a commercial account in any bank, you must obtain a "Tradesman Tax Exemption Certificate" from the Tax Offices of the Republic of Turkey and upload it to the application.',
        header5Answ6:
            '•  After receiving your Tradesman Tax Exemption Certificate, you should open a commercial account at the bank you want and upload your account receipt to the application. ',

        header52Answ1:
            'You should create a menu that includes the most delicious products of your kitchen. When preparing the menu, do not forget to emphasize the details that make your meals special (such as vegetarian, seafood, meat or cake). You can also share the main ingredients of your meals and indicate that they are fresh. To make it easier for your customers to decide, you can share how long their orders will take to prepare and the portion of the meal. ',
        header52Answ2:
            'It is possible to work full time or at certain time intervals in any time period you want. All you have to do for this is to set your own calendar through the application. You can update your calendar according to your availability. ',
        header52Answ3:
            'While determining the portion prices, you can use the pricing that you determine according to your expense table. In addition, there are price controls determined by Yemegim.co as the lowest and highest according to the food categories. These values ​​determined in the pricing cannot be exceeded. ',
        header52Answ4:
            'You will not be given an order outside of the working schedules you have determined. Therefore, if you do not accept orders, you need to bring your menu offline. Rejection after the order has been placed may decrease your liking status in the application and may cause your membership to be suspended. ',
        header52Answ5:
            'After confirming the incoming order, you must prepare the food within the time you specify on the menu. The courier following your food preparation time will be at your door to take the food at the end of this period. Since it is the commitment of Yemegim.co that the Customer-Chef-Courier-Customer flow will take place on time, maximum attention should be paid to the preparation of the menu within the specified time. ',
        header52Answ6:
            'If you do not have a tradesman record in the Tax Offices of the Republic of Turkey, you must obtain a "Tradesman Tax Exemption Certificate" from the Tax Offices of the Republic of Turkey in order to be able to sell through Yemegim.co. Those who provide this document can start selling by opening a commercial account in a bank of their choice and fulfill their tax responsibilities with a tax deduction of 2% to 4% of their income. If the individual who is registered as a Chef will serve alone, the tax deduction is 4%, if he or she will employ another person (this may be his spouse or any relative), the tax deduction is 2%. ',
        header52Answ7:
            '•  You can be your own boss and work the way you want, whenever you want, in your own kitchen, without making any additional investments. ',
        header52Answ8: '•   You can work part-time or full-time during the periods you determine. ',
        header52Answ9:
            '•  Since the payments you earn will be credited to your account within five working days at the latest, you can easily contribute to your family budget. ',
        header52Answ10: '•  You can exchange information with other Chefs and enrich your menu.',
        header52Answ11: '•  You can have the opportunity to cook, exchange information and enrich your menus locally.',
        header52Answ12:
            '•  With the gourmet tours offered by Yemegim.co, you have the opportunity to go to different countries of the world and thus enrich your menu on a global level.',

        header6Desc1: 'Who can be a courier? ',
        header6Desc2: 'To be a courier with a large vehicle: ',
        header6Desc3: 'What should I do to become a courier? ',
        header6Desc4: 'What times can I work? ',
        header6Desc5: 'How do I get paid? ',
        header6Desc6: "Can I reject delivering the Chef's food after I request to deliver it? ",
        header6Desc7: 'How much tax do I pay as a result of sales transactions? ',
        header61Answ1: 'Anyone who meets the conditions listed below can become a courier on Yemegim.co. ',
        header61Answ2: '•	Being over 18 years old.',
        header61Answ3: '•	Owning a bicycle or motorcycle under 50 cc. ',
        header61Answ4: "•	Having a driver's license and protective equipment for the vehicles that he/she owns and will use in the delivery process. ",
        header61Answ5:
            '•	Having a business registration (“Tradesman Courier Model” taxable business registration, including a sole proprietorship) or holding a “Tradesman Tax Exemption Certificate”.',
        header61Answ6: '•	Having a smartphone for sharing map information.',
        header62Answ1: '• 	Being over 18 years old ',
        header62Answ2: "•	Having a driver's license ",
        header62Answ3: '•	Being a company owner (individual or Inc., Ltd.) ',
        header62Answ4: '•	Having SRC-4 certificate',
        header62Answ5: '•	Not having a criminal record',
        header63Answ1: 'To join the courier network offered by Yemegim.co, simply fill out the form in the ',
        header63Answ1Href: 'Become a Courier ',
        header63Answ1AfterHref:
            'section on the web page or complete the process by following the steps in the "Register" tab from the mobile applications. It is important to pay attention to the following points for the successful completion of your registration process. ',
        header63Answ2:
            '•	After logging into the application with your e-mail or phone information, you must fill in all your personal information requested.',
        header63Answ3: '•	You should choose your profile photo carefully.',
        header63Answ4: '•	 You must upload the account receipt from the bank where you have your commercial account to the application. ',
        header64Answ1:
            'It is possible to work full time or at any time you want. All you have to do for this is to set your own calendar through the application. You will be visible as an active courier in the application according to your calendar that you have created by determining the times you can transport. After this stage, it will be enough for you to take action by following the Chefs on the map and request to carry the order. ',
        header64Answ2:
            'A minimum of 5 TL is paid for each delivery you make. Payments to be made vary according to the delivery distance. It is possible to see each delivery fee on your own screen, through the application, before the delivery. Fees are deposited into your account within 5 working days at the latest. ',
        header64Answ3: `You can opt out of transporting within the period given to you on the application. Contact us to cancel the transport due to unusual circumstances outside the time specified in the application. Since it is the commitment of Yemegim.co that the Customer-Chef-Courier-Customer flow will take place on time, maximum attention should be paid to the time issue. `,
        header64Answ4:
            "Depending on your trade registration or tradesman's tax exemption, you must pay tax at the tax rates stipulated by the Tax Offices of the Republic of Turkey. ",
        header7Desc2: 'Does food have an expiration date? ',
        header7Desc3: 'How do you keep food fresh? ',
        header7Desc4: 'Are the products used in packaging environmentally friendly? ',
        header7Desc5: 'I forgot my password, how can I log in? ',

        header7Answ2:
            'Your food is prepared daily by our chefs and delivered to you. For this reason, they have a shelf life as if they were prepared in your own kitchen and it is recommended to be consumed within two days. In order to get the best taste from the food, we recommend it to be consumed immediately after delivery, but in cases where you cannot finish your food, it is possible to store it for 48 hours under suitable conditions. ',
        header7Answ3:
            'After your food order reaches the chef, the ingredients to be used in the meal are supplied by the chef and your order is prepared. In order to deliver the prepared orders to the customers in a fresh way, they are placed in special lunch boxes/containers specially designed by Yemegim.co, and the meals are presented to the customers in this way. ',
        header7Answ4:
            'We do all our responsibilities to protect our nature, we care about the recyclability of all packages, labels, bags, etc. we use.',
        header7Answ5:
            'If you can not login to the application because you forgot your password, you can refresh your password using the “Forgot Password” option. In order to renew your password, you will be asked to enter your phone number registered in the system, and your renewal password will be sent to your phone as an SMS. After entering the renewal password that was sent to you, you can renew your password. ',
    },
    AboutVidiPage: {
        title1: 'Why Yemegim.co?',
        title2: 'Mission',
        title3: 'Vision',
        title4: 'How Does Yemegim.co Work?',
        desc1:
            'Regardless of the location we live in, the dishes of the region we grow up form our taste. The process of forming our food tastes first starts with the meals our family cooks in our childhood years and is shaped over the years. We decided to establish the Yemegim.co platform in order to deliver these flavors to the customers by keeping a food culture alive and at the same time to give the opportunity to earn income for the chefs who prepare these hidden flavors. ',
        desc2:
            'Yemegim.co is an address where those who prepare different tastes of local, regional and world cuisine at home meet with those who want to reach these tastes. While trying to contribute to the recognition of the hidden food or beverages of every culture and geography; Yemegim.co offers the opportunity to earn money to housewives, professional cooks, students and even travelers who want to determine their own working time and conditions without being tied to anyone. ',
        desc3:
            'Our vision is to be the leading platform where hidden tastes are discovered, different flavors are shared, and where real gourmets and those who want to reach these unique tastes meet on a global level, first in Turkey and then in Europe and America. ',
        desc4:
            "Thanks to the business model we have developed in our own way, we bring traditional or special flavors from mother's kitchen to the doorstep of our customers. To access this unique service, you can access the Vidieats online platform",
        desc9:
            'Vidieats publishes the menus of Chefs who want to share their local and traditional foods, customers choose what they like from these menus and complete their order. Vidieats Courier delivers the prepared orders to the address specified by the customer on time. ',
        desc4Href: ' enter',
        desc4Next: ' just order the products you want.',
        text1: 'The world of unique flavors',
        text2: 'Explore Now',

        desc5:
            'Vidieats is a creative company founded in 2021 that brings a different perspective to the food and beverage industry apart from restaurant culture. It is a partnership that meets all hygiene conditions and aims at the satisfaction of customers by delivering delicious, healthy, home-made meals to their door. While providing this service, it is a social platform that transforms housewives into private cooks in their own kitchens and provides the opportunity to be a courier to any vehicle owner (bike, motorcycle, etc.) who have free time, thus supporting increasing household income. With all these features, Vidieats is a candidate organization to be indispensable in the food and beverage industry.   ',
        desc6:
            'As Vidieats, we aimed to be a platform where everyone who does not have a competitive advantage in material or moral terms can take part in order to be successful by preparing the tastes that they know best, that is, specific to the culture they grew up in.  ',
        desc7:
            'Vidieats is not a place where chefs compete. Vidieats is an address where real tastes, healthy and completely home-made foods are offered to consumers. Vidieats chefs supplies ingredients to be used in meals on order, thus reducing food waste. Our primary goal is to deliver healthy meals to consumers and to help individuals who do not have a regular income to earn through Vidieats. ',
        desc8:
            "Vidieats provides its customers with the opportunity to customize their meals and to send their special requests to the chefs, giving them the chance to create a menu according to their own tastes. Vidieats does not allow the sale of industrial products, but only completely home-made healthy food and beverages prepared in accordance with the customers' demand. It provides the opportunity to create a weekly menu for users who work intensively and for anyone who cannot find the opportunity to cook at home.  ",
    },
    BecomeACourier: {
        defaultQuestion: 'Are you ready to earn income by making use of your available times?',
        title1: 'How do I become a courier?',
        title4: 'Who can be a Courier? And to find out more',
        buttonText: 'About The Courier',
        buttonText2: 'Courier Registration Form',

        button1Ques: "We're Always Here for You",
        button1Answ: "We're with you every time you're on your way. You can contact us instantly via the app for any assistance, advice and support.",
        button2Ques: 'Competitive Fees',
        button2Answ:
            'We pay competitive fees for each order you deliver. In addition, when you deliver orders, you can earn tips from customers so you can reach your goals more easily.',
        button3Ques: 'You Can Increase Your Earnings',
        button3Answ: 'You can take advantage of busy areas and earn more with additional deliveries to close distances.',
        button4Ques: 'Get Paid Anytime You Want',
        button4Answ:
            "We'll transfer your earnings on a weekly basis. If you want to get paid faster, you will be able to withdraw daily through the app 24 hours after delivery.",
    },
    BecomeAChef: {
        defaultQuestion: 'Are you ready to share your skills and tastes with the whole world?',
        title1: 'How do I become a Chef?',
        title2: 'Are you ready to share your skills and tastes with the whole world?',
        title4: 'To Become a Chef at Vidieats',
        buttonText: 'Sign up',

        button1Ques: 'Why Become a Chef at Vidieats',
        button1Answ: 'Discover the opportunities and benefits of Vidieats.',
        button2Ques: 'Generating Income with Vidieats',
        button2Answ: 'Find out what you need to know about pricing and payment.',
        button3Ques: 'Is My Kitchen and Food Menu Suitable for Vidieats?',
        button3Answ: 'There is an perfect customer for every flavor and taste, click to learn the secret.',
        button4Ques: 'Start Your Vidieats Journey',
        button4Answ: 'Learn the basics of working with Vidieats, from creating your account to preparing menus.',

        ques1Desc1:
            " If you believe you are making delicious dishes, desserts or doughy foods and you think your kitchen meets the basic hygiene requirements of Vidieats, you can also come from the foods you will make and connect with very different people and share local or even international cuisine flavors. So, how will you decide the price of your menu and what method to use to get paid? Here's what you need to know:",
        ques1Answer1: 'Vidieats offers you the opportunity to be your own boss and work in your own kitchen whenever you want.',
        ques1Answer2: 'You can contribute to your family budget with the income you earn. ',
        ques1Answer3:
            ' You work in a very low tax bracket without wasting time with bureaucratic procedures (Opportunity to work with 2-4% tax up to 220,000 TL per person per year with the Tradesman Exemption Certificate).',
        ques1Answer4: ' You can access your earnings within five working days at the latest.',
        ques1Answer5: 'You can improve your menu by exchanging information with other chefs who offer different tastes. ',
        ques1Answer6:
            ' You can go to different countries with the gourmet tours provided by Vidieats. In this way, you can enrich your menus globally, reach a wide consumer base and earn more income. ',
        ques2Desc1:
            "If you believe that you make delicious meals, desserts and pastries and your kitchen meets Vidieats' basic hygiene requirements, you should join us. While earning income from the delicious meals you cook as a Chef, you will have the opportunity to connect with different people and share the tastes of local and even world cuisine. Everything is good to go, but how will you decide the price of your menu and which method you will use for the payments you will receive? Here's all you need to know: ",
        ques2Header1: 'It is free to save your kitchen',
        ques2Answer1: "It's completely free to",
        ques2Answer1Href: 'sign up',
        ques2Answer1Next:
            'for Vidieats and publish your menu. All you have to do is to create an interesting food post and wait for orders. The Chef service fee is deducted from every payment to be made to you. The payment to be made to you is the amount obtained when the Chef service fee is subtracted from the fee you request from the customer. With these deductions collected, Vidieats expenses are covered and continuous improvements are made to better serve you. ',

        ques2Header2: 'You determine the price you get',
        ques2Answer2:
            'While determining the portion prices, you can use the pricing that you determine according to your expense table. In addition, there are price controls determined by Vidieats as the lowest and highest according to the food categories. These values determined in the pricing cannot be exceeded. ',
        ques2Answer2Italic:
            ' In the special menu prepared by the Chef for special occasions, the lower limit of price determination belongs entirely to the Chef. ',
        ques2Header3: "You'll get paid after delivery",
        ques2Answer3:
            'After your orders are delivered to your customers, Vidieats completes your payments by sending them within 24 hours at the earliest and within 5 working days at the latest. You can check the status of your payments at any time by reviewing your transaction history. How payments are made to you is up to you. Available payment methods may include options such as direct transfer to your bank, EFT(PayPal, Western Union, prepaid cards) depending on your location.  ',

        ques2Header4: 'Taxes may apply',
        ques2Answer4:
            'Chefs who do not have commercial records must obtain a "Tradesman Exemption Certificate" from the Tax Offices of the Republic of Turkey in order to sell through Vidieats. Chefs who provide this document open a commercial account at a bank of their choice and 2% to 4% tax deduction is applied over this account. In case the Chef has an employee with him, the income tax deduction is applied as 2%, while it is applied as 4% if the Chef carries out the activities alone.  ',
        ques2Answer4Italic:
            ' If the Chef has an employee with him, the income tax deduction is applied as 2%, while if he or she is running the activities alone, it is applied as 4%. ',
        ques2Header5: 'Sign up, set up, win',
        ques2Answer5:
            'As the Vidieats family, we work for your success by giving you the opportunity to earn income from your own kitchen. With a secure payment system and the freedom to choose the pricing you want, you can',
        ques2Answer5Href: ' sign up',
        ques2Answer5Next: 'and create records that fit your business goals.',
        ques3Desc1:
            "There is a perfect customer for every flavor and taste, you just need to learn the secret. You might think that a salty tea or a fish boiled in sugar water wouldn't be popular on Vidieats. In fact, there are many different types of food that appeal to customers; even the unique or modest ones. Whether your meals are extraordinary or simple and affordable, the trick that makes them appealing is to create a detailed and honest record. ",
        ques3Header1: 'Start with basics',
        ques3Answer1:
            'What do you need for a Vidieats menu? You should make your customers feel that the food you prepare is healthy and fresh, and that they also have the option to have their orders delivered whenever they want. You should inform your customers by explaining in detail the minimum time required for the preparation of each dish and the ingredients in the meal. ',
        ques3Header2: 'Define your type of food',
        ques3Answer2:
            "Vidieats has all kinds of food, so it's important to specify exactly the type of food you're serving in your listing. Vegetarian, meat dish or dessert? Is it pastry or a unique flavor? Specifying all these completely will make it easier for customers to find your meals. ",
        ques3Header3: 'Determine what your customers can order',
        ques3Answer3:
            'You can provide your customers with information on what else they can order alongside the food they choose from your menu. For example; Homemade drink, brioche or a seasonal salad. ',
        ques3Header4: 'Be honest about pros and cons',
        ques3Answer4:
            'A chef who makes şırdan in Adana can say that it is very tasty and healthy. However, for someone who is allergic to offal, it would be appropriate to give a warning beforehand. For this reason, limiting consumption characteristics, if any, should be specified with each product. ',
        ques3Header5: 'Make a difference in your presentation',
        ques3Answer5:
            'It is a useful step for you to specify where and how the materials used are obtained in order to expand your customer base. For example; You can stand out among other chefs by using homemade tomato paste or olive oil of your own production in your products. ',
        ques3Header6: 'Focus on the right customer base',
        ques3Answer6:
            '  Each type of food has its own customer base. For example; For someone who enjoys the tastes of the Aegean region, spicy dishes may not be very interesting, but for your customers in the Southeastern Anatolia region, this is an amazing taste. Therefore, you should remember that not every product is suitable for everyone. ',
        ques3Header7: 'Price your menu with a realistic approach',
        ques3Answer7:
            '  Pricing the dishes in your menu in accordance with the socio-economic structure of the region you are in can increase your customer base. Marketing your products by comparing them with the prices determined in different regions can negatively affect your earnings. ',
        ques3Header8: 'A flavor that suits everyone',
        ques3Answer8:
            'If you are confident in your skills and think that you can find suitable customers for your meals, you can sell all kinds of foods on Vidieats (For sample meals, you can visit our blog). You will create your own customer base with beautiful and delicious meals that you will cook. Success is in your hands. In addition, the scoring of your customers will make you more prominent in the ranking in Vidieats. Likewise, as a result of poor service, Vidieats may be forced to terminate your membership.    ',
        ques3Answer8Href: 'blog ',
        ques3Answer8Next:
            "you can visit our page). Every qualified and delicious product you make will lead you to success and increase your appreciation rate. Depending on your customers' liking, your listing status may vary within the app. ",
        ques4Desc:
            'You can think of your listing as an advertisement for your kitchen. Therefore, do not forget to make it interesting by specifying all the details about your kitchen.',

        ques4Answer1: 'After ',
        ques4Answer1Href: 'registering ',
        ques4Answer1Next: 'into the application with your e-mail or phone information, you must first fill in the required information completely',

        ques4Answer2:
            'Put on the apron, cap and gloves that Vidieats will share with you and take a photo of your face and kitchen. Try to choose the best photo for your profile.',
        ques4Answer3:
            "Customers want to see the environment in which their orders are prepared. That's why you should not forget to include pictures of your kitchen. This will positively influence your customers' decision. For the best photo, don't forget to tidy up your kitchen, use natural light, and position your camera horizontally if possible.",

        ques4Answer4: 'Explain your personal characteristics',
        ques4Answer4Prev: 'With the ',
        ques4Answer4Href: 'about ',
        ques4Answer4Next:
            'tab, you can give basic information about yourself and your kitchen. You can talk about many details, from the region of your cuisine to the richness of the ',
        ques4Answer4Href1: 'menu.',
        ques4Answer4Next1: 'you can talk about your wealth and its health benefits.)',

        ques4Answer5:
            'If you do not have a commercial account in any bank, you must obtain a "Tradesman Exemption Certificate" from the Tax Offices of the Republic of Turkey and upload it to the application.',
        ques4Answer6:
            'After receiving your Tradesman Exemption Certificate, you should open a commercial account at the bank you want and upload your account receipt to the application.',
        ques4Answer7:
            'You should create a menu that includes the most delicious products of your kitchen. When preparing the menu, do not forget to emphasize the details that make your meals special (such as vegetarian, seafood, meat or cake). You can also share the main ingredients of your meals and indicate that they are fresh. In order for your customers to decide more easily, you can share how long it will take to prepare their orders and how many people the food is for.',
        ques4Answer8: 'You should create your ',
        ques4Answer8Href: ' calendar ',
        ques4Answer8Next: 'by determining your own working time periods. As a Chef, you can work any time you want. By setting your own ',

        ques4Answer8Next1: 'on Vidieats, you can update the available times to receive orders.',

        ques4Answer9:
            'You should present the prices you have determined for your products to your customers. For portion prices, you can use the pricing you have determined according to your expense table. Apart from this, Vidieats has special price controls for the average base price and for certain periods such as weekends and holidays. If you request, we can help you with our Smart Pricing Tools so that you can determine the price of your products. ',
        ques4Answer10:
            "You have to prepare your food order within the time you specified and ensure that the courier receives it. Since it is Vidieats' commitment that the Customer-Chef-Courier flow will be realized on time, you should pay attention to prepare the order you receive within the specified time.",
    },
    CourrierRegistrationPage: {
        firstName: 'First Name',
        lastName: 'Last Name',
        phone: 'Phone',
        mail: 'E-mail',
        required: 'This field is required',
        sendForApproval: 'Register',
        courrierRegistrationForm: 'Courier Registration Form',
        termsOfUse: ' Terms of Use ',
        termsOfUseBefore: ' Kullanıcı Sözleşmesi ',
        termsOfUseAfter: ' Kullanıcı Sözleşmesi ',
        termsOfUse2: ' Kullanıcı Sözleşmesi ',
        termsOfUseBefore2: ' Kullanıcı Sözleşmesi ',
        termsOfUseAfter2: ' Kullanıcı Sözleşmesi ',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        Error: 'Error',
        registerSuccess: 'Register Success',
        passwordMessage: 'Your password must contain at least 8 characters and at least 1 uppercase letter',
        passwordError: 'Passwords dont match',
        phoneNumberPlaceHolder: 'Phone number (5XXXXXXXXX)',
    },

    CancelletaionPolicyPage: {
        title: ' Vidieats Cancellation Policy  ',
        subtitle1: 'Rules to be applied in cancellations made by the customer:  ',
        subtitle1FirstDesc:
            ' If the order is canceled within the first 15 minutes after the order is placed and confirmed by the Chef, the payment will be refunded without any deduction. ',
        subtitle1SecondDesc: `If 15 minutes have passed since the order, 50% of the refund amount will be deducted for cancellations made up to half of the chef's preparation time. (For example: If the chef prepares the food in 120 minutes, if the customer cancels within the first 15 minutes, no deduction will be made, but if it is canceled between 15-60 minutes, 50% will be charged. If it is canceled after 60 minutes, no refund will be made.) `,
        subtitle2: ' In case of any cancellation made by the Chef;  ',
        subtitle2FirstDesc: 'In cancellations made by the Chef, the full fee is refunded. ',
    },
};
