export const GET_ORDERS_START = 'GET_ORDERS_START';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

export const CREATE_ORDERS = 'CREATE_ORDERS';
export const CREATE_ORDERS_SUCCESS = 'CREATE_ORDERS_SUCCESS';
export const CREATE_ORDERS_FAIL = 'CREATE_ORDERS_FAIL';

export const DELETE_ORDER_STARTED = 'DELETE_ORDER_STARTED';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

export const CREATE_BASKET_ORDERS = 'CREATE_BASKET_ORDERS';
export const CREATE_BASKET_ORDERS_SUCCESS = 'CREATE_BASKET_ORDERS_SUCCESS';
export const CREATE_BASKET_ORDERS_FAIL = 'CREATE_BASKET_ORDERS_FAIL';
