import * as actionTypes from './actionTypes';

const initialState = {
    currency: localStorage.getItem('currency'),
};

export default (state = initialState, { type, payload }) => {
    if (type === actionTypes.SET_CURRENCY_STARTED) {
        return { ...state, currency: payload };
    }
    return state;
};
